import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnInit,
    Output,
    Input,
} from "@angular/core";
import {
    FormArray,
    FormBuilder,
    FormGroup,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from "@angular/forms";
import { AccountService } from "../../../data-access-layer/account/account.service";
import { CreateAccountRequest } from "../../../model/account/account";
import { NotifService } from "../../../core/notification/notif.service";
import { UserStateService } from "../../../auth/user-state-service";
import { AuthService } from "../../../auth/auth.service";
import { OverlaysService } from "src/app/data-access-layer/global-overlays/overlays.service";
import { ActivatedRoute } from "@angular/router";
import { GroupService } from "src/app/data-access-layer/groups/group.service";
import { DatapointsAggregateService } from "src/app/data-access-layer/datapoints/datapoints-aggregate.service";
import { catchError } from 'rxjs/operators';
import { of, partition, range } from 'rxjs';
import { LayerExposedService } from "../services/layer-exposed.service";
import { DatasetFieldScope } from "src/app/model/dataset/dataset-field-scope";

@Component({
    selector: "add-layer-exposed",
    templateUrl: "./add-layer-exposed.component.html",
    styleUrls: ["./add-layer-exposed.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddLayerExposedComponent implements OnInit {
    @Output() updatedSuccessfully = new EventEmitter();
    @Output() onCancelClose = new EventEmitter();
    @Input() existingData: any; 
    @Input() dataToEdit: any;
    @Input() isEdit: boolean;
    createAccountForm: UntypedFormGroup;
    datasetLinkMap = {};
    externalOverlays: any;
    locationDatasetDropdown: any; // Base Value
    policyDatasetDropdown: any; // Limit Value and Attachment Point

    accountId: number;

    locationDataset: any;
    policyDataset: any;

    isFormDisabled = false;
    constructor(
        private readonly accountService: AccountService,
        private readonly notifService: NotifService,
        private readonly changeDetector: ChangeDetectorRef,
        private readonly route: ActivatedRoute,
        private layerExposeService: LayerExposedService,
        private fb: FormBuilder,

    ) {
    }
    ngOnChanges() {
        if (!this.isEdit) {
            this.initForm();
            this.createAccountForm.controls.name.setValue('');
            this.createAccountForm.controls.baseValue.setValue('');
            this.createAccountForm.controls.limit.setValue('');
            this.createAccountForm.controls.attachmentPoint.setValue('');
            this.createAccountForm.controls.participation.setValue('');
        }
        this.accountId = +this.route.snapshot.paramMap.get("accountId");
        this.initForm();
        if (this.existingData) {
            this.patchExistingData();
        }
    }

    ngOnInit() {
        if (!this.isEdit) {
            this.initForm();
            this.createAccountForm.reset();
            this.createAccountForm.controls.name.setValue('');
            this.createAccountForm.controls.baseValue.setValue('');
            this.createAccountForm.controls.limit.setValue('');
            this.createAccountForm.controls.attachmentPoint.setValue('');
            this.createAccountForm.controls.participation.setValue('');
        }
        this.accountId = +this.route.snapshot.paramMap.get("accountId");
        if (this.existingData) {
            this.patchExistingData();
        }
        this.getData();
    }

    patchExistingData(): void {
        this.createAccountForm.patchValue({
            name: this.existingData.name,
            baseValue: this.existingData.baseValue,
            limit: this.existingData.layerValue,
            attachmentPoint: this.existingData.attachmentPoint,
            participation: this.existingData.participation,
        });
    }

    getData() {
        this.accountService.findAccount(this.accountId).subscribe((account) => {
            const locationDataset = account.datasets.find((dataset: any) => dataset.name === 'Locations' );
            if (locationDataset) {
                this.locationDataset = locationDataset;
                this.locationDatasetDropdown = locationDataset.fields.filter(
                    (field) =>
                        field.type === "NUMBER_FIELD" &&
                        field.scope === DatasetFieldScope.INTERNAL
                );
            }
            const policyDataset = account.datasets.find((dataset: any) => dataset.name === 'Policies');
            if (policyDataset) {
                this.policyDatasetDropdown = policyDataset.fields.filter(field => field.type === 'NUMBER_FIELD');
            }
        });
    }
    
    initForm(): void {
        this.isFormDisabled = false;
        this.createAccountForm = this.fb.group({
            name: ['', [Validators.required]],
            baseValue: ['', [Validators.required]],
            limit: ['', [Validators.required]],   
            attachmentPoint: ['', [Validators.required]],
            participation: ['', [Validators.required]],
        });
        this.createAccountForm.updateValueAndValidity();
    }

    clearFormValidators(formGroup: UntypedFormGroup | FormGroup): void {
        Object.keys(formGroup.controls).forEach((key) => {
          const control = formGroup.get(key);
          if (control instanceof UntypedFormGroup || control instanceof FormGroup) {
            this.clearFormValidators(control);
          } else if (control instanceof UntypedFormControl) {
            control.clearValidators();
            control.updateValueAndValidity();
          } else {
            control.clearValidators();
            control.updateValueAndValidity();
          }
        });
      }

      
    onCancel() {
        this.onCancelClose.emit();
        this.changeDetector.detectChanges();
    }
    submitLayerExpose(): void {
        this.createAccountForm.markAllAsTouched();
        if (this.createAccountForm.valid) {

            const formData = this.createAccountForm.value;
            formData.name = formData.name?.trim();
            if (!formData.name) {
                this.createAccountForm.controls['name'].setErrors({ required: true });
                this.createAccountForm.controls['name'].markAsTouched();    
                return;
            }
            if (this.existingData && this.isEdit) {
                const updatePayload = {
                    id: this.existingData.id,
                    accountId: this.accountId,
                    name: formData.name,
                    baseValue: formData.baseValue,
                    layerValue: formData.limit,
                    attachmentPoint: formData.attachmentPoint,
                    participation: formData.participation,
                    isApplied: true
                };

                try {
                    let apiError: any;
                    this.layerExposeService.updateLayerExpose(this.accountId, this.existingData.id, updatePayload)
                        .pipe(
                            catchError((error) => {
                                console.error("API Error: ", error.error.message);
                                apiError = error;
                                return of(null);
                            })
                        )
                        .subscribe((data) => {
                            if (apiError?.error?.errors) {
                                this.notifService.error(apiError.error.errors);
                            } else {
                                this.clearFormValidators(this.createAccountForm);
                                this.createAccountForm.reset();
                                this.notifService.success(
                                    "Successfully updated layer exposed"
                                );
                                this.updatedSuccessfully.emit();
                                this.changeDetector.detectChanges();
                            }

                        });
                } catch (error) {
                    console.error("Unexpected Error: ", error);
                }
            }
            else if (!this.isEdit) {
                const myPayload = {
                    accountId: this.accountId,
                    name: formData.name,
                    baseValue: formData.baseValue,
                    layerValue: formData.limit,
                    attachmentPoint: formData.attachmentPoint,
                    participation: formData.participation,
                    isApplied: true
                };
                try {
                    let apiError: any;
                    this.layerExposeService.createLayerExpose(this.accountId, myPayload)
                        .pipe(
                            catchError((error) => {
                                console.error("API Error: ", error.error.message);
                                apiError = error;
                                return of(null);
                            })
                        )
                        .subscribe((data) => {
                            if (apiError?.error?.errors) {
                                this.notifService.error(apiError.error.errors);
                            } else {
                                this.clearFormValidators(this.createAccountForm);
                                this.createAccountForm.reset();
                                this.notifService.success(
                                    "Successfully created layer exposed"
                                );
                                this.updatedSuccessfully.emit();
                                this.changeDetector.detectChanges();
                            }
                        });
                } catch (error) {
                    console.error("Unexpected Error: ", error);
                }
            }

        } else {
            console.log("Form is invalid. Please check the errors.");
        }
    }

    removeSpaces() {
        const nameControl = this.createAccountForm.get('name');
        if (nameControl) {
          nameControl.setValue(nameControl.value.replace(/^\s+/, ''), { emitEvent: false });
        }
    }
}
