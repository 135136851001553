export class LocationProfile {
    readonly INTERNAL = 'INTERNAL';
    readonly EXTERNAL = 'EXTERNAL';
    readonly CLIMATE = 'CLIMATE';
    readonly APG = 'APG';
    readonly NRI = 'NRI';
    readonly POI = 'POI';
    readonly AIR_QUALITY = 'AIR_QUALITY';
    readonly CONDITIONS = 'CONDITIONS';
    readonly FORECASTS = 'FORECASTS';
    readonly TROPICALCYCLONES = 'TROPICALCYCLONES';
    readonly ALERTS = 'ALERTS';
    readonly CONVECTIVE_AND_OUTLOOK = 'CONVECTIVE_AND_OUTLOOK';
    readonly DROUGHTS_AND_MONITOR = 'DROUGHTS_AND_MONITOR';
    readonly FIRES = 'FIRES';
    readonly FIRES_AND_OUTLOOK = 'FIRES_AND_OUTLOOK';
    readonly PLACES = 'PLACES';
    readonly RIVERS = 'RIVERS';
    readonly STROMCELLS = 'STROMCELLS';
    readonly THREATS = 'THREATS';
//    readonly TENSORFLIGHT = 'TENSORFLIGHT';
    readonly E2VALUE = 'E2VALUE';
    readonly COMBINED = 'COMBINED';
    readonly MUNICHRE = 'MUNICHRE';
    readonly CUSTOM_SCORE = 'CUSTOM_SCORE';
}

export enum dropdownMenuList {
    INTERNAL = 'Internal',
    //  AIR_QUALITY = 'Air Quality',
    //  ALERTS = 'Alerts',
    CLIMATE = 'Climate',
    //  CONVECTIVE_AND_OUTLOOK = 'Convective/Outlook',
    //  DROUGHTS_AND_MONITOR = 'Droughts/Monitor',
    EXTERNAL= 'External',
    //  FIRES = 'Fires',
    //  FIRES_AND_OUTLOOK = 'Fires/Outlook',
    //  FORECASTS = 'Forecasts',
    MUNICHRE = 'Munich Re',
    NRI = 'NRI',
    POI = 'POI',
    APG = 'Precisely Wildfire',
    //  RIVERS = 'Rivers',
    //  STROMCELLS = 'Stormcells',
 //   TENSORFLIGHT = 'TensorFlight',
    E2VALUE = 'E2Value',
    //  TROPICALCYCLONES = 'Tropicalcyclones',
    // THREATS = 'Threats',
    CONDITIONS = 'Xweather conditions',
    PLACES = 'Xweather places',
    CUSTOM_SCORE = 'Custom Score',
}

export enum addressProfileDropdownMenuList {
    EXTERNAL = 'External',
    CLIMATE = 'Climate',
    NRI = 'NRI',
    POI = 'POI',
    APG = 'Precisely Wildfire',
    CONDITIONS = 'XWeather Conditions',
    PLACES = 'XWeather Places',
}

//export const TENSORFLIGHT_ARRAY = ['TENSORFLIGHT', 'Tensorflight']
export const MUNICHRE_ARRAY = ['MUNICHRE', 'Munich Re']

export enum ExternalDataFetchingAPIKeys{
    //TENSORFLIGHT = "tensorflight",
    MUNICHRE = "munichre_hazards"
}

//export const TENSORFLIGHT_POLYGON_DATA_KEY = "polygon_data";
export const EMPTY_PROFILE = "EMPTY_PROFILE"
