import {ChangeDetectorRef, Component, Input, ViewChild} from '@angular/core';
import {FormControl, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {DatapointFilter} from '../../model/datapoint/filter/datapoint-filter';
import {LayerExposedService} from '../../account/layer-exposed/services/layer-exposed.service';
import {ActivatedRoute} from '@angular/router';
import {DatapointsFilterService} from '../../dataset/datapoints/datapoints-filter.service';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import { debounceTime } from 'rxjs/operators';
import { ReportDisplayType } from 'src/app/model/analytics/report-display-type';
import { ReportItem } from 'src/app/model/analytics/report-item';
import { MatDialog } from '@angular/material/dialog';
import { NotifService } from 'src/app/core/notification/notif.service';
import { DownloadService } from 'src/app/data-access-layer/download/download.service';
import { ChartDataSets } from 'chart.js';
import { ColorUtils } from 'src/app/core/utils/color-utils';
import { ChartInfo } from 'src/app/model/datapoint/report/chart-info';
import { StringUtils } from 'src/app/core/utils/string-utils';
import { DownloadReportTableRequest } from 'src/app/model/download/download-report-table-request';
import { DownloadReportItem } from 'src/app/model/download/item/download-report-item';
import { TableColumn, TableColumnType, TableColumnAlignment } from 'src/app/model/upload/table/table-column';
import { TableCell } from 'src/app/model/upload/table/table-cell';
import { TableRow } from 'src/app/model/upload/table/table-row';
import { Constants } from 'src/app/constants';
import { AttachmentUtils } from 'src/app/core/utils/attachment-utils';
import { DownloadDatapointRequest } from 'src/app/model/download/download-datapoint-request';
import { DownloadFileType } from 'src/app/model/download/download-file-type';
import { DownloadReportRequest } from 'src/app/model/download/download-report-request';
import { DownloadReportChartRequest, ValueKey } from 'src/app/model/download/download-report-chart-request';
import { DownloadReportChartValueRequest } from 'src/app/model/download/download-report-chart-value-request';
import { DownloadItemReportType } from 'src/app/model/download/item/download-item-report-type';
import { LayerExposeAccumulation } from './layer-expose-accumulation-response';

@Component({
    selector: 'map-layer-expose-accumulation',
    templateUrl: './layer-expose-accumulation.component.html',
    styleUrls: ['./layer-expose-accumulation.component.scss']
})
export class LayerExposeAccumulationComponent {
    filter: DatapointFilter;
    layerExposeForm: UntypedFormGroup;
    exposeLayerEquations: any;
    showError = false;
    accountId: number;
    layerExposeSummary: any;
    dataSource: any;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    showTable: boolean = false;
    displayedColumns: string[] = ['name', 'exposedValue', 'baseValue', 'layerValue', 'attachmentPoint', 'participation'];
    datasetId: number;
    dataIsReady = false;
    selectedFieldsCount = 0;
    reportSubType: ReportDisplayType = ReportDisplayType.TABLE;
    reportName: string;
    reportItems: ReportItem[] = []; 
    layerExposeComponents: LayerExposeAccumulationComponent[];
    chartDatasets: any[];
    chartLabels: string[];
    chartColors: any[];

    constructor(
        private layerExposeService: LayerExposedService,
        private readonly route: ActivatedRoute,
        private datapointFilterService: DatapointsFilterService,
        private readonly changeDetector: ChangeDetectorRef,
        private datapointsFilterService: DatapointsFilterService,
        private readonly dialog: MatDialog,
        private readonly downloadService: DownloadService,
        private readonly notifService: NotifService,
    ) {
        this.exposeLayerEquations = [];
        this.layerExposeForm = new UntypedFormGroup({});
        this.filter = datapointFilterService.getActiveFilter();
    }

    ngOnInit() {
        this.accountId = +this.route.snapshot.paramMap.get('accountId');
        this.datasetId = +this.route.snapshot.paramMap.get("datasetId");
        this.layerExposeForm = new UntypedFormGroup({
            equationId: new FormControl('', [Validators.required]),
        });

        this.layerExposeService.getAppliedLayerExpose(this.accountId)
            .subscribe(equations => {
                this.exposeLayerEquations = equations;
            });

            this.datapointFilterService.onFilterChange().pipe(debounceTime(200)).subscribe(newFilter => {
                this.filter = newFilter;
                this.fetchLayerExposeData();
            });
    }

    get equationIdControl(): UntypedFormControl {
        return this.layerExposeForm.get('equationId') as UntypedFormControl;
    }

    fetchLayerExposeData(): void {
        this.dataIsReady = true;
        if (!this.equationIdControl.valid) {
            this.showError = true;
            this.dataIsReady = false;
            return;
        }
        this.filter = this.datapointFilterService.getActiveFilter();
        const selectedEquation = this.layerExposeForm.get('equationId')?.value;
        this.layerExposeService.getLayerSummaryResponse(this.filter, this.accountId, selectedEquation)
            .subscribe(data => {
                this.showTable = true;
                this.layerExposeSummary = data;
                this.dataSource = new MatTableDataSource<any>(this.layerExposeSummary.exposureResponses);
                this.dataSource.sort = this.sort;
                this.changeDetector.detectChanges();
            });
    }

    clearData() {
        this.showTable = false;
        this.equationIdControl.setValue(null);
        this.showError = false;
    }

    close() {
        this.dataIsReady = false;
        this.reportSubType = ReportDisplayType.TABLE;
    }

    get ReportDisplayType() {
        return ReportDisplayType;
    }

    chartOptions: any = {
        responsive: true,
        tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {
                    const value =
                        data.datasets[tooltipItem.datasetIndex].data[
                            tooltipItem.indexs
                        ];
                    const name = data.labels[tooltipItem.index];
                    const roundedUpValue = value;
                    let generatedTooltip = `${name}:${StringUtils.numberWithCommas(
                        roundedUpValue
                    )}`;

                    return generatedTooltip;
                },
            },
        },
    };

    private initializeChartData() {
        this.chartDatasets = [];
        this.chartLabels = [];
        this.chartColors = [];
    }

    private populateChartData() {
        if (this.dataSource.length === 0) {
            return;
        }
        this.initializeChartData();

        let chartColors: ChartInfo[] = [];
        let mainDatasetFieldValues = []; 
        let chartDatasets: ChartDataSets[] = [];

        let chartDataset = {
            data: [],
        };
        let chartColor = {
            backgroundColor: [],
        };
        chartDatasets.push(chartDataset);
        chartColors.push(chartColor);
        this.dataSource.filteredData.forEach((entry) => {
            mainDatasetFieldValues.push(entry.policynumber);
            chartDataset.data.push(entry.exposedValue);
            chartColor.backgroundColor.push(
                ColorUtils.generateRandomHexColor()
            );
        });

        this.chartDatasets = chartDatasets;
        this.chartLabels = mainDatasetFieldValues;
        this.chartColors = chartColors;
    }

    setDisplayType(reportSubType: ReportDisplayType) {
        if (this.accountId > 0) {
            this.reportSubType = reportSubType;
        }
        this.chartOptions.scales =
            reportSubType === ReportDisplayType.BAR_CHART
                ? {
                      yAxes: [
                          {
                              ticks: {
                                  callback: (value) => {
                                      return StringUtils.numberWithCommas(
                                          value
                                      );
                                  },
                              },
                          },
                      ],
                  }
                : null;

        this.chartOptions.tooltips = {
            callbacks: {
                label: (tooltipItem, data) => {
                    const value =
                        data.datasets[tooltipItem.datasetIndex].data[
                            tooltipItem.index
                        ];
                    const name = data.labels[tooltipItem.index];
                    const roundedUpValue = value;
                    let generatedTooltip = `${name}:${StringUtils.numberWithCommas(
                        roundedUpValue
                    )}`;

                    return generatedTooltip;
                },
            },
        };

        this.populateChartData();
    }

    getTableReportHeader(): TableColumn[] {
        let columns: TableColumn[] = [];

        const staticHeaders = [
            {
                name: "Policy Number",
                type: TableColumnType.TEXT,
                horizontalAlignment: TableColumnAlignment.LEFT,
            },
            {
                name: "Layer Exposed Value",
                type: TableColumnType.TEXT,
                horizontalAlignment: TableColumnAlignment.LEFT,
            },
            {
                name: "Base Value",
                type: TableColumnType.TEXT,
                horizontalAlignment: TableColumnAlignment.LEFT,
            },
            {
                name: "Limit",
                type: TableColumnType.TEXT,
                horizontalAlignment: TableColumnAlignment.LEFT,
            },
            {
                name: "Attachment Point",
                type: TableColumnType.TEXT,
                horizontalAlignment: TableColumnAlignment.LEFT,
            },
            {
                name: "Participation %",
                type: TableColumnType.TEXT,
                horizontalAlignment: TableColumnAlignment.LEFT,
            },
        ];

        staticHeaders.forEach((header) => {
            columns.push({
                id: header.name,
                name: header.name,
                type: header.type,
                horizontalAlignment: header.horizontalAlignment,
            });
        });

        return columns;
    }

    getTableReportRows(): TableRow[] {
        let rows: TableRow[] = [];
        const filteredData: LayerExposeAccumulation[] =
            this.dataSource.filteredData;
        filteredData.forEach((row: LayerExposeAccumulation) => {
            let cells: TableCell[] = [];
            cells.push({ id: "Policy Number", value: row.policynumber || "-" });
            cells.push({
                id: "Layer Exposed Value",
                value: row.exposedValue || "-",
            });
            cells.push({ id: "Base Value", value: row.baseValue || "-" });
            cells.push({ id: "Limit", value: row.layerLimit || "-" });
            cells.push({
                id: "Attachment Point",
                value: row.attachmentPoint || "-",
            });
            cells.push({
                id: "Participation %",
                value: row.participation || "-",
            });
            rows.push({
                cells: cells,
            });
        });
        return rows;
    }

    getTableReportFooter(): TableRow {
        let cells: TableCell[] = [];

        let totalSumOfLayerExposure = this.dataSource.filteredData.reduce(
            (sum, item) => sum + item.exposedValue,
            0
        );
        let totalSumOfBaseValues = this.dataSource.filteredData.reduce(
            (sum, item) => sum + item.baseValue,
            0
        );

        cells.push({ id: "policyNumber", value: "Total" });
        cells.push({ id: "exposedValue", value: totalSumOfLayerExposure });
        cells.push({ id: "baseValue", value: totalSumOfBaseValues });

        return { cells: cells };
    }

    getDisplayType(): ReportDisplayType {
        return this.reportSubType;
    }

    getReportDownloadRequest(): DownloadReportItem {
        if (this.getDisplayType() === ReportDisplayType.TABLE) {
            return this.getTableReportDownloadRequest();
        } else if (
            this.getDisplayType() === ReportDisplayType.BAR_CHART ||
            this.getDisplayType() === ReportDisplayType.PIE_CHART
        ) {
            return this.getChartReportDownloadRequest();
        }
    }

    getChartReportDownloadRequest(): DownloadReportItem {
        let breakdownFieldsNames: string[] = ["Policy Number"];

        let request: DownloadReportChartRequest = {
            title: this.reportName || "Exposed Value Chart",
            valueKey: ValueKey.EXPOSE,
            type: undefined,
            columns: {
                value: "Policy Number",
                categories: breakdownFieldsNames,
            },
            values: [],
        };

        this.dataSource.filteredData.forEach((item) => {
            let chartValueRequest: DownloadReportChartValueRequest = {
                categories: [item.policynumber],
                colors: ["#36A2EB"],
                count: item.exposedValue,
                value: item.exposedValue,
            };
            request.values.push(chartValueRequest);
        });

        if (this.reportSubType === ReportDisplayType.PIE_CHART) {
            request.type = DownloadItemReportType.AGGREGATE_PIE_CHART;
        } else if (this.reportSubType === ReportDisplayType.BAR_CHART) {
            request.type = DownloadItemReportType.AGGREGATE_BAR_CHART;
        }

        return request;
    }

    getTableReportDownloadRequest(): DownloadReportItem {
        if (this.dataIsReady) {
            let reportHeader = this.getTableReportHeader();
            let reportRows = this.getTableReportRows();
            let reportFooter = this.getTableReportFooter();
            let title = this.reportName || "Layer Exposed";

            return new DownloadReportTableRequest(
                title,
                reportHeader,
                reportRows,
                reportFooter
            );
        } else {
            return null;
        }
    }

    downloadReports() {
        let downloadItems: DownloadReportItem[] = [];

        let reportDownloadRequest: DownloadReportItem =
            this.getReportDownloadRequest();
        if (reportDownloadRequest) {
            downloadItems.push(reportDownloadRequest);
        }

        let reportRequest: DownloadReportRequest = {
            items: downloadItems,
        };

        let downloadRequest: DownloadDatapointRequest = {
            reportRequest: reportRequest,
            filter: this.datapointsFilterService.getActiveFilter(),
            timezone: Constants.DEFAULT_TIMEZONE,
            dateFormat: Constants.DEFAULT_DATE_FORMAT,
            outputFileType: DownloadFileType.XLSX,
        };

        this.downloadReportFile(downloadRequest);
    }

    private downloadReportFile(downloadRequest: DownloadDatapointRequest) {
        this.downloadService
            .downloadDatapoints(
                this.datasetId.toString(),
                downloadRequest,
                "Exposed Layer Accumulation.xlsx"
            )
            .subscribe(
                (response) => {
                    AttachmentUtils.downloadFileWithName(
                        response,
                        "Exposed Layer Accumulation.xlsx"
                    );
                },
                (error) =>
                    this.notifService.error(
                        "Something went wrong during download"
                    )
            );
    }
}
