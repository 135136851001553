import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SeeInfoSearchBarStateService {
  private _isPopupVisible = new BehaviorSubject<boolean>(false);
  isPopupVisible$ = this._isPopupVisible.asObservable();

  togglePopup() {
    this._isPopupVisible.next(!this._isPopupVisible.getValue());
  }

  setPopupState(state: boolean) {
    this._isPopupVisible.next(state);
  }
}
