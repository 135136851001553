<map-header></map-header>
<div class="page-root">
    <div class="breadcrumbs">
        <span>Home</span>
        <span style="flex:1">Dashboard</span>
        <div class="dashbaord-actions">
            <ng-container *ngIf="!isAnalyticsPanelOpen">
                <div class="comparison-toggle-button" matTooltipPosition="above" [matTooltip]="getTooltipForComparisonToggle()">
                    <mat-slide-toggle color="accent" [checked]="isComparisonModeActivated" (change)="changeComparisonMode()"></mat-slide-toggle>
                </div>
            </ng-container>
            <a *ngIf="hasAccess" style="text-decoration: none;margin-right: 16px; color: black; cursor: pointer"
                (click)="openAnalyticsPanel()">Add Widget</a>
            <a style="text-decoration: none;margin-right: 16px; color: black; cursor: pointer"
                (click)="undoResize()">Undo Resize</a>
        </div>
    </div>
    <div class="page">
        <div class="page-container dashboard-container">
            <div class="sidebar-wrap">
                <!-- sidebar start -->
                <section class="dashboard-sidebar p-0">
                    <div class="groups-form-wrap groups-outer-wrap">
                        <h5 class="widget-title">{{ groupAlias }}</h5>
                        <div class="widget-content" *ngIf="dropdownItems?.length">
                            <app-multi-level-dropdown [items]="dropdownItems" [lastItemDisable]="false"
                                (selectedItems)="onSelectedItemsChanged($event)" [ulClass]="ulClassName" [isDisabled]="isComparisonModeActivated"></app-multi-level-dropdown>
                        </div>
                    </div>
                    <div class="groups-form-wrap groups-outer-wrap">
                        <div class="widget-title-container">
                            <h5 class="widget-title boder-bottom-none">Key Indicators</h5>
                            <div *ngIf="hasAccess" class="edit-indicator-button" (click)="openIndicatorsEditDialog()">
                                <i class="fal fa-edit"></i>
                            </div>
                        </div>
                        <div class="widget-content" *ngIf="sidebarCountDataValues.length">
                            <div *ngFor="let element of sidebarCountDataValues" class="indicators-box">
                                <p class="small">{{ element.dataLabel}}</p>
                                <p class="medium">{{ element.dataValue | commaFormat}}</p>
                                <!-- <hr class="menu-line-separator" /> -->
                            </div>
                        </div>
                        <div class="widget-content" *ngIf="sidebarDataValues.length && isSidebarDataLoaded" cdkDropList
                            (cdkDropListDropped)="indicatorsDrop($event)">
                            <div *ngFor="let element of sidebarDataValues" class="indicators-box"
                                [cdkDragDisabled]="element.disabled" cdkDrag>
                                <p class="small">{{ element.dataLabel}}</p>
                                <p class="medium">{{ element.dataValue | commaFormat}}</p>
                                <!-- <hr class="menu-line-separator" /> -->
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <!-- sidebar end -->
            <section cdkDropListGroup class="dashboard-main-content report-box" id="pdfTable" #pdfTable>
                <ng-container *ngIf="isAnalyticsPrepared">
                    <ng-container *ngFor="let columnNumber of columnNumbersArray">
                        <div *ngIf="getAnalyticDataUsingCloumnNumber(columnNumber)" cdkDropList
                            (cdkDropListDropped)="reportDrop($event)"
                            [cdkDropListData]="getAnalyticDataUsingCloumnNumber(columnNumber)">

                            <ng-container
                                *ngFor="let data of getAnalyticDataUsingCloumnNumber(columnNumber); let counter = index">

                                <div *ngIf="data" [ngStyle]="{'width':data?.style?.width}"
                                    class="report-element-container" cdkDrag style="min-width: 320px;"
                                    [cdkDragDisabled]="analyticData.length <= 1">

                                    <!-- AGGREGATE -->
                                    <div class="report-element html2pdf__page-break"
                                        *ngIf="data?.reportType === REPORT_TYPE_MASTER.AGGREGATE">
                                        <header class="panel-name dashboard-panel">
                                            <div class="panel-title report-title-container"><input
                                                    class="report-bottom-title" style="text-align: left !important;"
                                                    [(ngModel)]="data.reportName" [disabled]="!hasAccess"
                                                    placeholder="Aggregate"
                                                    (blur)="updateWorkspaceItemName($event, counter, columnNumber)">
                                            </div>
                                            <div>
                                                <span (click)="removeAnalyticData(counter, columnNumber)"
                                                    class="report-icon active" style="cursor: pointer;">
                                                    <!-- eye-slash-icon -->
                                                    <svg width="24" height="24" viewBox="0 0 24 24"
                                                        xmlns="http://www.w3.org/2000/svg" style="position: relative;top: 4px;">
                                                        <g fill="none" fill-rule="evenodd">
                                                            <path d="M14.558 13.558a3.003 3.003 0 1 1-4.114-4.115"
                                                                stroke="#0044f1" stroke-width="1.5"
                                                                stroke-linecap="round" stroke-linejoin="round" />
                                                            <path
                                                                d="M17.997 16.996A9.873 9.873 0 0 1 12 19.003a9.457 9.457 0 0 1-8.558-5.09 4.323 4.323 0 0 1 0-3.826c.829-1.65 2.149-3 3.779-3.867M20.427 14.134c.04-.076.093-.144.13-.221a4.323 4.323 0 0 0 0-3.826A9.457 9.457 0 0 0 12 4.997c-.225 0-.443.03-.665.045M21.004 20.003 3.997 2.996"
                                                                stroke="#0044f1" stroke-width="1.5"
                                                                stroke-linecap="round" stroke-linejoin="round" />
                                                            <path d="M-.005-.005h24.01v24.01H-.005z" />
                                                        </g>
                                                    </svg>
                                                </span>
                                                <ng-container *ngIf="analyticData.length > 1">
                                                    <span cdkDragHandle class="drag-handler">
                                                        <i class="fal fa-arrows"></i>
                                                    </span>
                                                </ng-container>
                                            </div>
                                        </header>
                                        <div class="report-header">
                                            <div style="display: flex">
                                                <div *ngIf="hasAccess" [matMenuTriggerFor]="valueMenu"
                                                    style="cursor: pointer;">
                                                    <span class="report-icon header-icon" title="Aggregate field">
                                                        <!-- sort-amount-up-icon -->
                                                        <svg width="16" height="16" viewBox="0 0 24 24"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="m16 6.62-.768-1.242A.806.806 0 0 0 14.545 5H6.801a.805.805 0 0 0-.697.395.758.758 0 0 0 .01.783L7.512 8.44c.112.181 1.473 1.348 4.082 3.5-2.61 2.232-3.97 3.439-4.082 3.62l-1.398 2.262a.758.758 0 0 0-.01.783.805.805 0 0 0 .697.395h7.744a.806.806 0 0 0 .687-.378L16 17.38"
                                                                class="chart-icons" stroke="#002d57" stroke-width="1.5"
                                                                fill="none" fill-rule="evenodd" stroke-linecap="round"
                                                                stroke-linejoin="round" />
                                                        </svg>
                                                    </span>
                                                </div>
                                                <mat-menu #valueMenu="matMenu" class="nested-menu"
                                                    (closed)="generateReport(data)">
                                                    <mat-tree [dataSource]="data.aggregateDataSource"
                                                        [treeControl]="data.aggregateTreeControl"
                                                        class="sub-menu-options-child">
                                                        <!-- This is the tree node template for leaf nodes -->
                                                        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle
                                                            class="sub-menu-form-options-tree">

                                                            <li class="mat-tree-node sub-menu-checkbox-options"
                                                                [matTooltip]="getDisplayName(node)" matTooltipPosition="right"
                                                                *ngIf="node.params?.overlay?.geometryType !== DatasetGeometryType.POINT && node.params?.showCheckBox">
                                                                <button mat-icon-button disabled></button>
                                                                <mat-checkbox class="checklist-leaf-node"
                                                                    [checked]="node.selected"
                                                                    [disabled]="getDisabledTreeNode(node, data)"
                                                                    [(ngModel)]="node.selected"
                                                                    (ngModelChange)="onAggregateFieldModelChangeEvent(node, counter, columnNumber)">
                                                                    {{getDisplayName(node)}}</mat-checkbox>
                                                            </li>
                                                        </mat-tree-node>
                                                        <!-- This is the tree node template for expandable nodes -->
                                                        <mat-nested-tree-node
                                                            *matTreeNodeDef="let node; when: data.aggregateTreeStrcuture.hasChild"
                                                            class="sub-menu-options-parent">
                                                            <li>
                                                                <hr *ngIf="data.aggregateDataSource.data.length > 1" class="menu-line-separator" />
                                                                <div class="mat-tree-node" [matTooltip]="node.name" matTooltipPosition="right">
                                                                    <span class="filter-inner-parent">{{node.name}}</span>
                                                                    <button mat-icon-button matTreeNodeToggle
                                                                        [attr.aria-label]="'toggle ' + node.name">
                                                                        <mat-icon class="mat-icon-rtl-mirror">
                                                                            {{data.aggregateTreeControl.isExpanded(node)
                                                                            ? 'expand_more' : 'chevron_right'}}
                                                                        </mat-icon>
                                                                    </button>
                                                                </div>
                                                                <ul
                                                                    [class.tree-invisible]="!data.aggregateTreeControl.isExpanded(node)">
                                                                    <ng-container matTreeNodeOutlet></ng-container>
                                                                </ul>
                                                            </li>
                                                        </mat-nested-tree-node>
                                                    </mat-tree>
                                                </mat-menu>
                                                <div *ngIf="hasAccess" [matMenuTriggerFor]="breakDownFieldMenu"
                                                    style="cursor: pointer">
                                                    <span class="report-icon header-icon" title="Breakdown fields">
                                                        <!-- tasks-icon -->
                                                        <svg width="16" height="16" viewBox="0 0 24 24"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <g fill="none" fill-rule="evenodd">
                                                                <path stroke="#002d57" stroke-width="1.5"
                                                                    stroke-linecap="round" stroke-linejoin="round"
                                                                    d="M7.998 3.997h12.005M2.996 3.997h1.001M2.996 7.998h1.001M2.996 12h1.001M7.998 7.998h12.005M7.998 12h2.001M17.002 21.004A5.002 5.002 0 0 1 12 16.002 5.08 5.08 0 0 1 17.002 11a5.002 5.002 0 0 1 0 10.004" />
                                                                <path stroke="#002d57" stroke-width="1.5"
                                                                    stroke-linecap="round" stroke-linejoin="round"
                                                                    d="m18.665 15.167-2.083 2.085-1.25-1.25" />
                                                                <path d="M-.005-.005h24.01v24.01H-.005z" />
                                                            </g>
                                                        </svg>
                                                    </span>
                                                </div>
                                                <mat-menu #breakDownFieldMenu="matMenu" (closed)="generateReport(data)"
                                                    class="nested-menu">
                                                    <mat-tree [dataSource]="data.breakdownDataSource"
                                                        [treeControl]="data.breakdownTreeControl"
                                                        class="sub-menu-options-child">
                                                        <!-- This is the tree node template for leaf nodes -->
                                                        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle
                                                            class="sub-menu-form-options-tree">

                                                            <li class="mat-tree-node sub-menu-checkbox-options"
                                                                [matTooltip]="getDisplayName(node)" matTooltipPosition="right"
                                                                *ngIf="node.params?.overlay?.geometryType !== DatasetGeometryType.POINT && node.params?.showCheckBox">
                                                                <button mat-icon-button disabled></button>
                                                                <mat-checkbox class="checklist-leaf-node"
                                                                    [checked]="node.selected"
                                                                    [disabled]="!node.selected && data.selectedBreakdownFieldsByDataset?.get(activeDataset.id)?.length == AnalyticsUtils.fieldSelectLimitCount(isComparisonModeActivated)"
                                                                    [(ngModel)]="node.selected"
                                                                    (ngModelChange)="onFieldsMenuClick(node.selected, node.params?.field, counter, columnNumber, node?.params?.overlay)">
                                                                    {{getDisplayName(node)}}</mat-checkbox>
                                                            </li>
                                                        </mat-tree-node>
                                                        <!-- This is the tree node template for expandable nodes -->
                                                        <mat-nested-tree-node
                                                            *matTreeNodeDef="let node; when: data.breakdownTreeStrcuture.hasChild"
                                                            class="sub-menu-options-parent">
                                                            <li>
                                                                <hr *ngIf="data.breakdownDataSource.data.length > 1" class="menu-line-separator" />
                                                                <div class="mat-tree-node" [matTooltip]="node.name" matTooltipPosition="right">
                                                                    <span class="filter-inner-parent">{{node.name}}</span>
                                                                    <button mat-icon-button matTreeNodeToggle
                                                                        [attr.aria-label]="'toggle ' + node.name">
                                                                        <mat-icon class="mat-icon-rtl-mirror">
                                                                            {{data.breakdownTreeControl.isExpanded(node)
                                                                            ? 'expand_more' : 'chevron_right'}}
                                                                        </mat-icon>
                                                                    </button>

                                                                </div>
                                                                <ul
                                                                    [class.tree-invisible]="!data.breakdownTreeControl.isExpanded(node)">
                                                                    <ng-container matTreeNodeOutlet></ng-container>
                                                                </ul>
                                                            </li>
                                                        </mat-nested-tree-node>
                                                    </mat-tree>
                                                </mat-menu>
                                                <div *ngIf="hasAccess && isComparisonModeActivated" [matMenuTriggerFor]="groupMenu" style="cursor: pointer;">
                                                    <svg xmlns="http://www.w3.org/2000/svg" height="15px" viewBox="0 -960 960 960" width="15px"
                                                    fill="#002d57">
                                                    <path
                                                        d="M480-400 40-640l440-240 440 240-440 240Zm0 160L63-467l84-46 333 182 333-182 84 46-417 227Zm0 160L63-307l84-46 333 182 333-182 84 46L480-80Zm0-411 273-149-273-149-273 149 273 149Zm0-149Z" />
                                                    </svg>
                                                </div>
                                                <mat-menu #groupMenu="matMenu" (closed)="generateReport(data)" class="analytics-mat-tree-container">
                                                    <div><hr class="menu-line-separator" />
                                                        <span class="group-compare-label">
                                                            <span>Compare</span>
                                                            <mat-icon (click)="$event.stopPropagation(); data.isCompareDropdownOpen = false" *ngIf="data.isCompareDropdownOpen">keyboard_arrow_down</mat-icon>
                                                            <mat-icon (click)="$event.stopPropagation(); data.isCompareDropdownOpen = true" *ngIf="!data.isCompareDropdownOpen">chevron_right</mat-icon>
                                                        </span>
                                                        <ng-container *ngIf="data.isCompareDropdownOpen">
                                                            <ul [ngClass]="ulClassName">
                                                                <ng-container *ngTemplateOutlet="recursiveList; context: { items: data._compareGroupsStrcuture, level: 0, section: 'compare' }"></ng-container>
                                                            </ul>
                                                        </ng-container>
                                                        <hr class="menu-line-separator" />

                                                        <span class="group-compare-label">
                                                            <span>With</span> 
                                                            <mat-icon (click)="$event.stopPropagation(); data.isCompareWithDropdownOpen = false" *ngIf="data.isCompareWithDropdownOpen">keyboard_arrow_down</mat-icon>
                                                            <mat-icon (click)="$event.stopPropagation(); data.isCompareWithDropdownOpen = true" *ngIf="!data.isCompareWithDropdownOpen">chevron_right</mat-icon>
                                                        </span>
                                                        <ng-container *ngIf="data.isCompareWithDropdownOpen">  
                                                          <ul [ngClass]="ulClassName">
                                                            <ng-container *ngTemplateOutlet="recursiveList; context: { items: data._withGroupsStrcuture, level: 0, section: 'with' }"></ng-container>
                                                          </ul>
                                                        </ng-container>  
                                                          <ng-template #recursiveList let-items="items" let-level="level" let-section="section">
                                                            <li *ngFor="let item of items" [style.margin-left.px]="level * 10">
                                                              <div class="parent-group-container">
                                                                <div class="toggle-icon" [ngClass]="{'invisble': !item?.children?.length}" (click)="$event.stopPropagation();toggleItemExpansion(item)">
                                                                  <mat-icon class="small-icon">{{ item.expanded ? 'keyboard_arrow_down' : 'keyboard_arrow_right' }}</mat-icon>
                                                                </div>
                                                                <mat-checkbox
                                                                  [(ngModel)]="item.selected"
                                                                  (change)="toggleItem($event, item, section, data)"
                                                                  [disabled]="item.disabled"
                                                                  (click)="$event.stopPropagation()"
                                                                  (keyup)="$event.stopPropagation()"
                                                                  matTooltip="{{ item.name }}" matTooltipPosition="right">
                                                                  {{ item.name }}
                                                                </mat-checkbox>
                                                              </div>
                                                              <ng-container *ngIf="item.children && item.expanded">
                                                                <ng-container *ngTemplateOutlet="recursiveList; context: { items: item.children, level: level + 1, section: section }"></ng-container>
                                                              </ng-container>
                                                            </li>
                                                          </ng-template>
                                                      <!-- <div>
                                                        <span class="group-compare-label">
                                                            <span>Compare</span>
                                                            <mat-icon (click)="$event.stopPropagation(); data.isCompareDropdownOpen = false" *ngIf="data.isCompareDropdownOpen">keyboard_arrow_down</mat-icon>
                                                            <mat-icon (click)="$event.stopPropagation(); data.isCompareDropdownOpen = true" *ngIf="!data.isCompareDropdownOpen">chevron_right</mat-icon>
                                                        </span>
                                                        <ng-container *ngIf="data.isCompareDropdownOpen">
                                                            <div class="groups-menu-options-container">
                                                                <ng-container *ngFor="let group of data._compareGroups">
                                                                    <mat-checkbox
                                                                        class="checklist-leaf-node"
                                                                        [value]="group.id"
                                                                        [checked]="group.isChecked"
                                                                        [(ngModel)]="group.isChecked"
                                                                        (change)="onCheckboxChange($event, 'compare', data)"
                                                                        [disabled]="group.disabled"
                                                                        (click)="$event.stopPropagation()"
                                                                        (keyup)="$event.stopPropagation()">
                                                                        <span class="group-checkbox-label">{{group?.name}}</span>
                                                                    </mat-checkbox>
                                                                  </ng-container>
                                                            </div>
                                                        </ng-container>
                                                      </div> -->
                                                      <hr class="menu-line-separator" />
                                                      <!-- <div>
                                                        <span class="group-compare-label">
                                                            <span>With</span> 
                                                            <mat-icon (click)="$event.stopPropagation(); data.isCompareWithDropdownOpen = false" *ngIf="data.isCompareWithDropdownOpen">keyboard_arrow_down</mat-icon>
                                                            <mat-icon (click)="$event.stopPropagation(); data.isCompareWithDropdownOpen = true"  *ngIf="!data.isCompareWithDropdownOpen">chevron_right</mat-icon>
                                                        </span>
                                                        <ng-container *ngIf="data.isCompareWithDropdownOpen">
                                                            <div class="groups-menu-options-container">
                                                                <ng-container *ngFor="let group of data._withGroups">
                                                                    <mat-checkbox
                                                                        class="checklist-leaf-node"
                                                                        [value]="group.id"
                                                                        [checked]="group.isChecked"
                                                                        [(ngModel)]="group.isChecked"
                                                                        (change)="onCheckboxChange($event, 'with', data)"
                                                                        [disabled]="group.disabled"
                                                                        (click)="$event.stopPropagation()"
                                                                        (keyup)="$event.stopPropagation()"
                                                                    >
                                                                    <span class="group-checkbox-label">{{group?.name}}</span>
                                                                    </mat-checkbox>
                                                                </ng-container>
                                                            </div>
                                                        </ng-container>
                                                      </div> -->
                                                    </div>
                                                  </mat-menu>
                                            </div>
                                            <div class="report-type-icons-container">
                                                <span
                                                    (click)="setDisplayType(counter, ReportDisplayType.TABLE, columnNumber)"
                                                    [ngClass]="{'selected': data.reportSubType===ReportDisplayType.TABLE, 'active' : ''}"
                                                    class="report-icon">
                                                    <!-- document-icon -->
                                                    <!-- <svg width="16" height="16" viewBox="0 0 24 24"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <g fill="none" fill-rule="evenodd">
                                                            <path d="M0 0v24h24V0z" />
                                                            <path d="M21 15h-4a2 2 0 0 0-2 2v4" stroke="#002d57"
                                                                class="chart-icons" stroke-width="1.5"
                                                                stroke-linecap="round" stroke-linejoin="round" />
                                                            <path
                                                                d="M7 3h10a4 4 0 0 1 4 4v8.343a4 4 0 0 1-1.172 2.829l-1.656 1.656A4 4 0 0 1 15.343 21H7a4 4 0 0 1-4-4V7a4 4 0 0 1 4-4zM8 12h8M8 8h8M8 16h3"
                                                                stroke="#002d57" class="chart-icons" stroke-width="1.5"
                                                                stroke-linecap="round" stroke-linejoin="round" />
                                                        </g>
                                                    </svg> -->
                                                    <i class="fal fa-table chart-icons" style="font-size: 14px; position: relative;bottom: 1px;"></i>
                                                </span>
                                                <span
                                                    (click)="setDisplayType(counter, ReportDisplayType.BAR_CHART, columnNumber)"
                                                    [ngClass]="{'selected': data.reportSubType===ReportDisplayType.BAR_CHART, 'active' : ''}"
                                                    class="report-icon">
                                                    <!-- chart-bar-icon -->
                                                    <svg width="16" height="16" viewBox="0 0 24 24"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <g fill="none" fill-rule="evenodd">
                                                            <path
                                                                d="M16.5 3.5h3A1.5 1.5 0 0 1 21 5v14a1.5 1.5 0 0 1-1.5 1.5H15V5a1.5 1.5 0 0 1 1.5-1.5zM15 20.5H9v-10A1.5 1.5 0 0 1 10.5 9H15M4.5 15H9v5.5H4.5A1.5 1.5 0 0 1 3 19v-2.5A1.5 1.5 0 0 1 4.5 15z"
                                                                stroke="#002d57" stroke-width="1.5"
                                                                stroke-linecap="round" stroke-linejoin="round"
                                                                class="chart-icons" />
                                                            <path d="M24 24H0V0h24z" />
                                                        </g>
                                                    </svg>
                                                </span>
                                                <ng-container *ngIf="!isComparisonModeActivated">
                                                    <span
                                                        (click)="setDisplayType(counter, ReportDisplayType.PIE_CHART, columnNumber)"
                                                        [ngClass]="{'selected': data.reportSubType===ReportDisplayType.PIE_CHART, 'active' : ''}"
                                                        class="report-icon">
                                                        <!-- chart-pie-icon -->
                                                        <!-- <svg width="16" height="16" viewBox="0 0 24 24"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <g fill="none" fill-rule="evenodd">
                                                                <path d="M12 17.5a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11"
                                                                    class="chart-icons" stroke="#002d57" stroke-width="1.5"
                                                                    stroke-linecap="round" stroke-linejoin="round" />
                                                                <rect class="chart-icons" stroke="#002d57"
                                                                    stroke-width="1.5" stroke-linecap="round"
                                                                    stroke-linejoin="round" x="2.5" y="2.5" width="19"
                                                                    height="19" rx="9.5" />
                                                                <path class="chart-icons" stroke="#002d57"
                                                                    stroke-width="1.5" stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                    d="M15.89 15.889 12 11.999V6.51" />
                                                                <path d="M0 0h24v24H0z" />
                                                            </g>
                                                        </svg> -->
                                                        <i class="fal fa-chart-pie chart-icons" style="font-size: 14px; position: relative;bottom: 1px;"></i>
                                                    </span>
                                                </ng-container>
                                                <span [matMenuTriggerFor]="actionsMenu"
                                                    class="report-icon ellipsis-icon active">
                                                    <!-- dot-menu-icon -->
                                                    <svg width="13" height="13" viewBox="0 0 9 9"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <g fill="#002d57" fill-rule="evenodd">
                                                            <circle cx="4.5" cy="1" r="1" />
                                                            <circle cx="4.5" cy="4" r="1" />
                                                            <circle cx="4.5" cy="7" r="1" />
                                                        </g>
                                                    </svg>
                                                </span>
                                                <mat-menu #actionsMenu="matMenu" class="nested-menu header-menu"
                                                    backdropClass="mat-menu-header">
                                                    <hr *ngIf="hasAccess" class="menu-line-separator" />
                                                    <div mat-menu-item *ngIf="hasAccess"
                                                        (click)="saveState(counter, columnNumber)">
                                                        <span>
                                                            <!-- save-icon -->
                                                            <svg width="16" height="16" viewBox="0 0 24 24"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <g transform="translate(-.005 -.005)" fill="none"
                                                                    fill-rule="evenodd">
                                                                    <path
                                                                        d="M20.008 11.005V7.564c0-.502-.199-.983-.553-1.337l-2.672-2.672a1.89 1.89 0 0 0-1.337-.554H5.002a2 2 0 0 0-2 2.001v13.006a2 2 0 0 0 2 2h6.003"
                                                                        stroke="#0044f1" stroke-width="1.5"
                                                                        stroke-linecap="round"
                                                                        stroke-linejoin="round" />
                                                                    <path
                                                                        d="M15 3.001v4.127a.876.876 0 0 1-.876.875H8.872a.876.876 0 0 1-.875-.875V3.001M7.003 20.008v-5.676a1.315 1.315 0 0 1 1.215-1.327h3.787"
                                                                        stroke="#0044f1" stroke-width="1.5"
                                                                        stroke-linecap="round"
                                                                        stroke-linejoin="round" />
                                                                    <circle stroke="#0044f1" stroke-width="1.5"
                                                                        stroke-linecap="round" stroke-linejoin="round"
                                                                        cx="17.507" cy="17.507" r="4.502" />
                                                                    <path stroke="#0044f1" stroke-width="1.5"
                                                                        stroke-linecap="round" stroke-linejoin="round"
                                                                        d="m18.907 16.808-1.75 1.752-1.05-1.052" />
                                                                    <path d="M0 0h24.01v24.01H0z" />
                                                                </g>
                                                            </svg>
                                                        </span>
                                                        Save
                                                    </div>
                                                    <hr *ngIf="hasAccess" class="menu-line-separator" />
                                                    <div *ngIf="hasAccess" mat-menu-item
                                                        (click)="saveAsWorkspaceItemToDashboard(counter, columnNumber)">
                                                        <span>
                                                            <!-- save-icon -->
                                                            <svg width="16" height="16" viewBox="0 0 24 24"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <g transform="translate(-.005 -.005)" fill="none"
                                                                    fill-rule="evenodd">
                                                                    <path
                                                                        d="M20.008 11.005V7.564c0-.502-.199-.983-.553-1.337l-2.672-2.672a1.89 1.89 0 0 0-1.337-.554H5.002a2 2 0 0 0-2 2.001v13.006a2 2 0 0 0 2 2h6.003"
                                                                        stroke="#0044f1" stroke-width="1.5"
                                                                        stroke-linecap="round"
                                                                        stroke-linejoin="round" />
                                                                    <path
                                                                        d="M15 3.001v4.127a.876.876 0 0 1-.876.875H8.872a.876.876 0 0 1-.875-.875V3.001M7.003 20.008v-5.676a1.315 1.315 0 0 1 1.215-1.327h3.787"
                                                                        stroke="#0044f1" stroke-width="1.5"
                                                                        stroke-linecap="round"
                                                                        stroke-linejoin="round" />
                                                                    <circle stroke="#0044f1" stroke-width="1.5"
                                                                        stroke-linecap="round" stroke-linejoin="round"
                                                                        cx="17.507" cy="17.507" r="4.502" />
                                                                    <path stroke="#0044f1" stroke-width="1.5"
                                                                        stroke-linecap="round" stroke-linejoin="round"
                                                                        d="m18.907 16.808-1.75 1.752-1.05-1.052" />
                                                                    <path d="M0 0h24.01v24.01H0z" />
                                                                </g>
                                                            </svg>
                                                        </span>
                                                        Save as
                                                    </div>
                                                    <hr class="menu-line-separator" />
                                                    <div mat-menu-item (click)="downloadReport(counter, columnNumber)">
                                                        <span>
                                                            <!-- download-arrow-icon -->
                                                            <svg width="16" height="16" viewBox="0 0 24 24"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <g fill="none" fill-rule="evenodd">
                                                                    <path stroke="#0044f1" stroke-width="1.5"
                                                                        stroke-linecap="round" stroke-linejoin="round"
                                                                        d="M15.557 11.11 12 14.669l-3.557-3.557M12 3.997v10.671M20.003 16.446h0a3.557 3.557 0 0 1-3.557 3.557H7.554a3.557 3.557 0 0 1-3.557-3.557h0" />
                                                                    <path d="M-.005-.005h24.01v24.01H-.005z" />
                                                                </g>
                                                            </svg>
                                                        </span>
                                                        Download
                                                    </div>
                                                    <hr class="menu-line-separator" />
                                                    <div mat-menu-item *ngIf="hasAccess"
                                                        (click)="deleteWorkspaceItem(counter, columnNumber)">
                                                        <span>
                                                            <!-- trash-icon -->
                                                            <svg width="16" height="16" viewBox="0 0 24 24"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <g fill="none" fill-rule="evenodd">
                                                                    <path
                                                                        d="M18.5 6.5V19a2 2 0 0 1-2 2h-9a2 2 0 0 1-2-2V6.5M9 17h6M9 13h6M8.5 6.5v-2A1.5 1.5 0 0 1 10 3h4a1.5 1.5 0 0 1 1.5 1.5v2M20 6.5H4"
                                                                        stroke="#0044f1" stroke-width="1.5"
                                                                        stroke-linecap="round"
                                                                        stroke-linejoin="round" />
                                                                    <path d="M0 0h24v24H0z" />
                                                                </g>
                                                            </svg>
                                                        </span>
                                                        Delete
                                                    </div>
                                                </mat-menu>
                                            </div>
                                        </div>

                                        <div class="report-content" [ngStyle]="data.style" mwlResizable
                                            [validateResize]="validate" [enableGhostResize]="true"
                                            [resizeSnapGrid]="{ left: 1, right: 1 }"
                                            (resizeEnd)="onResizeEnd($event, counter, columnNumber)">
                                            <div class="resize-handle-top" mwlResizeHandle
                                                [resizeEdges]="{ top: true }"></div>
                                            <div class="resize-handle-left" mwlResizeHandle
                                                [resizeEdges]="{ left: true }"></div>
                                            <div class="resize-handle-right" mwlResizeHandle
                                                [resizeEdges]="{ right: true }"></div>
                                            <div class="resize-handle-bottom" mwlResizeHandle
                                                [resizeEdges]="{ bottom: true }">
                                            </div>

                                            <div *ngIf="data.isResizing; else reportContentContainer"
                                                class="dashboard-loader-container">
                                                <mat-spinner diameter="50" color="accent"></mat-spinner>
                                            </div>

                                            <ng-template #reportContentContainer>
                                                <div *ngIf="data.reportSubType===ReportDisplayType.TABLE && !isComparisonModeActivated"
                                                    class="report-table">
                                                    <table [dataSource]="data.table.reportData" class="report-table"
                                                        mat-table matSort matSortDisableClear
                                                        (matSortChange)="sortData($event, counter, data.reportType, columnNumber)"
                                                        [matSortActive]="data?.sort?.active"
                                                        [matSortDirection]="data?.sort?.direction">

                                                        <ng-container *ngFor="let dynamicColumn of data.table.dynamicColumns | keyvalue; let i = index"
                                                            matColumnDef="{{dynamicColumn.key}}">
                                                            <th *matHeaderCellDef mat-header-cell mat-sort-header> {{dynamicColumn.value}} </th>
                                                            <td *matCellDef="let element " mat-cell>{{element.dynamicFieldValuesByIds.get(dynamicColumn.key)}}</td>
                                                            <td *matFooterCellDef mat-footer-cell>{{i === 0 ? 'Total' : '' | commaFormat }}</td>
                                                        </ng-container>

                                                        <ng-container [matColumnDef]="AGGREGATE_COLUMN_ID">
                                                            <th *matHeaderCellDef mat-header-cell mat-sort-header> {{data.selectedAggregateName}}</th>
                                                            <td *matCellDef="let element" mat-cell> {{element.aggregate | commaFormat}}</td>
                                                            <td *matFooterCellDef mat-footer-cell>{{data.table.totalAggregate | commaFormat}}</td>
                                                        </ng-container>

                                                        <ng-container [matColumnDef]="PERCENTAGE_COLUMN_ID">
                                                            <th *matHeaderCellDef mat-header-cell mat-sort-header>Total%</th>
                                                            <td *matCellDef="let element" mat-cell> {{element.percentage | commaFormat}}%</td>
                                                            <td *matFooterCellDef mat-footer-cell>100%</td>
                                                        </ng-container>

                                                        <ng-container matColumnDef="count">
                                                            <th *matHeaderCellDef mat-header-cell mat-sort-header>Count </th>
                                                            <td *matCellDef="let element" mat-cell> {{element.count | commaFormat}} </td>
                                                            <td *matFooterCellDef mat-footer-cell> {{data.table.totalCount | commaFormat}} </td>
                                                        </ng-container>

                                                        <ng-container [matColumnDef]="AVERAGE_COLUMN_ID">
                                                            <th *matHeaderCellDef mat-header-cell mat-sort-header>Average</th>
                                                            <td *matCellDef="let element" mat-cell> {{element.average | roundUpNumber | commaFormat }}</td>
                                                            <td *matFooterCellDef mat-footer-cell> {{data.table.totalAggregate / data.table.totalCount | roundUpNumber | commaFormat }} </td>
                                                        </ng-container>

                                                        <tr *matHeaderRowDef="data.table.columnsToDisplay; sticky: true"
                                                            mat-header-row>
                                                        </tr>
                                                        <tr *matRowDef="let row; columns: data.table.columnsToDisplay" mat-row></tr>
                                                        <tr *matFooterRowDef="data.table.columnsToDisplay" mat-footer-row></tr>
                                                    </table>
                                                </div>
                                                <div *ngIf="data.reportSubType===ReportDisplayType.TABLE && isComparisonModeActivated"
                                                    class="report-table">
                                                    <table [dataSource]="data.table.reportData" class="report-table"
                                                        mat-table matSort matSortDisableClear
                                                        (matSortChange)="sortData($event, counter, data.reportType, columnNumber)"
                                                        [matSortActive]="data?.sort?.active"
                                                        [matSortDirection]="data?.sort?.direction">

                                                        <ng-container *ngFor="let dynamicColumn of data.table.dynamicColumns | keyvalue; let i = index"
                                                            matColumnDef="{{dynamicColumn.key}}">
                                                            <th *matHeaderCellDef mat-header-cell mat-sort-header>{{dynamicColumn.value}}</th>
                                                            <td *matCellDef="let element " mat-cell>
                                                                {{element.dynamicFieldValuesByIds.get(dynamicColumn.key)}}</td>
                                                            <td *matFooterCellDef mat-footer-cell>{{i === 0 ? 'Total' : '' | commaFormat }}</td>
                                                        </ng-container>

                                                        <ng-container [matColumnDef]="PRIMARY_AGGREGATE_COLUMN_ID">
                                                            <th *matHeaderCellDef mat-header-cell mat-sort-header [matTooltip]="AnalyticsUtils.getGroupName(AnayticsConstants.PRIMARY_GROUP_INDEX, data.activeGroups, false)" matTooltipPosition="above">{{ AnalyticsUtils.getGroupName(AnayticsConstants.PRIMARY_GROUP_INDEX, data.activeGroups, true) }} {{data.selectedAggregateName}}</th>
                                                            <td *matCellDef="let element" mat-cell> {{element.primaryGroupAggregate | commaFormat}}</td>
                                                            <td *matFooterCellDef mat-footer-cell>{{data.table.totalAggregatePrimaryGroup | commaFormat}}</td>
                                                        </ng-container>

                                                        <ng-container [matColumnDef]="SECONDARY_AGGREGATE_COLUMN_ID">
                                                            <th *matHeaderCellDef mat-header-cell mat-sort-header [matTooltip]="AnalyticsUtils.getGroupName(AnayticsConstants.SECONDARY_GROUP_INDEX, data.activeGroups, false)" matTooltipPosition="above">{{ AnalyticsUtils.getGroupName(AnayticsConstants.SECONDARY_GROUP_INDEX, data.activeGroups, true) }} {{data.selectedAggregateName}}</th>
                                                            <td *matCellDef="let element" mat-cell> {{element.secondaryGroupAggregate | commaFormat}}</td>
                                                            <td *matFooterCellDef mat-footer-cell>{{data.table.totalAggregateSecondaryGroup | commaFormat}}</td>
                                                        </ng-container>
                                                        
                                                        <ng-container [matColumnDef]="COMPARE_AMOUNT_COLUMN_ID">
                                                            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ AnalyticsUtils.getGroupName(AnayticsConstants.PRIMARY_GROUP_INDEX, data.activeGroups, true) }} vs {{
                                                                AnalyticsUtils.getGroupName(AnayticsConstants.SECONDARY_GROUP_INDEX, data.activeGroups, true) }}</th>
                                                            <td mat-cell *matCellDef="let element"> {{element.comparedGroupAmount | commaFormat}}</td>
                                                            <td mat-footer-cell *matFooterCellDef>{{(data.table.totalAggregatePrimaryGroup - data.table.totalAggregateSecondaryGroup) | commaFormat}}</td>
                                                        </ng-container>

                                                        <ng-container [matColumnDef]="COMPARE_COLUMN_ID">
                                                            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ AnalyticsUtils.getGroupName(AnayticsConstants.PRIMARY_GROUP_INDEX, data.activeGroups, true) }} vs {{
                                                                AnalyticsUtils.getGroupName(AnayticsConstants.SECONDARY_GROUP_INDEX, data.activeGroups, true) }} (%)</th>
                                                            <td mat-cell *matCellDef="let element"> {{element.comparedGroupPercentage | commaFormat}}%</td>
                                                            <td mat-footer-cell *matFooterCellDef>{{ RandomUtils.roundUp(AnalyticsUtils.calculateVariancePercentage(data.table.totalAggregatePrimaryGroup, data.table.totalAggregateSecondaryGroup)) }} %</td>
                                                        </ng-container>

                                                        <tr *matHeaderRowDef="data.table.columnsToDisplay; sticky: true"
                                                            mat-header-row>
                                                        </tr>
                                                        <tr *matRowDef="let row; columns: data.table.columnsToDisplay"
                                                            mat-row></tr>
                                                        <tr *matFooterRowDef="data.table.columnsToDisplay"
                                                            mat-footer-row></tr>
                                                    </table>
                                                </div>
                                                <div *ngIf="data.reportSubType===ReportDisplayType.BAR_CHART">
                                                    <div *ngIf="data.selectedBreakdownFieldsCount > 2"
                                                        class="report-instructions">
                                                        Unable to create a graph with more than two fields selected for
                                                        grouping by
                                                    </div>
                                                    <div *ngIf="data.chart.chartDatasets.length && data.selectedBreakdownFieldsCount <= 2"
                                                        class="chart-background">
                                                        <mat-radio-group
                                                            (change)="onChartDisplayTypeChanged(data, counter, columnNumber)"
                                                            [(ngModel)]="data.chartDisplayType"
                                                            class="radio-buttons-group"
                                                            id="aggregateChartDisplayType_{{counter}}">
                                                            <mat-radio-button
                                                                [value]="ChartDisplayType.AGGREGATE">Aggregate</mat-radio-button>
                                                            <mat-radio-button
                                                                [value]="ChartDisplayType.COUNT">Count</mat-radio-button>
                                                            <mat-radio-button
                                                                [value]="ChartDisplayType.AVERAGE">Average</mat-radio-button>
                                                        </mat-radio-group>
                                                        <canvas [colors]="data.chart.chartColors"
                                                            [datasets]="data.chart.chartDatasets"
                                                            [labels]="data.chart.chartLabels" [legend]="false"
                                                            [options]="data.chart.chartOptions" chartType="bar"
                                                            mdbChart>
                                                        </canvas>
                                                    </div>
                                                </div>
                                                <div *ngIf="data.reportSubType===ReportDisplayType.PIE_CHART">
                                                    <div *ngIf="data.selectedBreakdownFieldsCount > 2"
                                                        class="report-instructions">
                                                        Unable to create a graph with more than two fields selected for
                                                        grouping by
                                                    </div>
                                                    <div *ngIf="data.chart.chartDatasets.length && data.selectedBreakdownFieldsCount <= 2"
                                                        class="chart-background">
                                                        <mat-radio-group
                                                            (change)="onChartDisplayTypeChanged(data, counter, columnNumber)"
                                                            [(ngModel)]="data.chartDisplayType"
                                                            class="radio-buttons-group"
                                                            id="aggregatePieChartDisplayType">
                                                            <mat-radio-button
                                                                [value]="ChartDisplayType.AGGREGATE">Aggregate</mat-radio-button>
                                                            <mat-radio-button
                                                                [value]="ChartDisplayType.COUNT">Count</mat-radio-button>
                                                            <mat-radio-button
                                                                [value]="ChartDisplayType.AVERAGE">Average</mat-radio-button>
                                                        </mat-radio-group>
                                                        <canvas [colors]="data.chart.chartColors"
                                                            [datasets]="data.chart.chartDatasets"
                                                            [labels]="data.chart.chartLabels"
                                                            [legend]="data.selectedBreakdownFieldsCount >= 1"
                                                            [options]="data.chart.chartOptions" chartType="pie"
                                                            mdbChart>
                                                        </canvas>
                                                    </div>
                                                </div>
                                            </ng-template>

                                        </div>
                                    </div>
                                    <!-- RANKING -->
                                    <div *ngIf="data?.reportType === REPORT_TYPE_MASTER.RANKING"
                                        class="report-element html2pdf__page-break">
                                        <header class="panel-name dashboard-panel">
                                            <div class="panel-title report-title-container"><input
                                                    class="report-bottom-title" style="text-align: left !important;"
                                                    [(ngModel)]="data.reportName" [disabled]="!hasAccess"
                                                    (blur)="updateWorkspaceItemName($event, counter, columnNumber)"
                                                    placeholder="Ranking">
                                            </div>
                                            <div>
                                                <span (click)="removeAnalyticData(counter, columnNumber)"
                                                    class="report-icon active" style="cursor: pointer;">
                                                    <!-- eye-slash-icon -->
                                                    <svg width="24" height="24" viewBox="0 0 24 24"
                                                        xmlns="http://www.w3.org/2000/svg" style="position: relative;top: 4px;">
                                                        <g fill="none" fill-rule="evenodd">
                                                            <path d="M14.558 13.558a3.003 3.003 0 1 1-4.114-4.115"
                                                                stroke="#0044f1" stroke-width="1.5"
                                                                stroke-linecap="round" stroke-linejoin="round" />
                                                            <path
                                                                d="M17.997 16.996A9.873 9.873 0 0 1 12 19.003a9.457 9.457 0 0 1-8.558-5.09 4.323 4.323 0 0 1 0-3.826c.829-1.65 2.149-3 3.779-3.867M20.427 14.134c.04-.076.093-.144.13-.221a4.323 4.323 0 0 0 0-3.826A9.457 9.457 0 0 0 12 4.997c-.225 0-.443.03-.665.045M21.004 20.003 3.997 2.996"
                                                                stroke="#0044f1" stroke-width="1.5"
                                                                stroke-linecap="round" stroke-linejoin="round" />
                                                            <path d="M-.005-.005h24.01v24.01H-.005z" />
                                                        </g>
                                                    </svg>
                                                </span>
                                                <ng-container *ngIf="analyticData.length > 1">
                                                    <span cdkDragHandle class="drag-handler">
                                                        <i class="fal fa-arrows"></i>
                                                    </span>
                                                </ng-container>
                                            </div>
                                        </header>
                                        <div class="report-header">
                                            <div class="box-header-wrap">
                                                <div *ngIf="hasAccess" class="left-box-header">
                                                    <div [matMenuTriggerFor]="valueMenu"
                                                        style="cursor: pointer; ">
                                                        <span class="report-icon header-icon" title="Ranking field">
                                                            <!-- amount-up-icon-->
                                                            <svg width="16" height="16" viewBox="0 0 24 24"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <g fill="none" fill-rule="evenodd">
                                                                    <path stroke="#002d57" class="chart-icons"
                                                                        stroke-width="1.5" stroke-linecap="round"
                                                                        stroke-linejoin="round"
                                                                        d="M18.003 2.996v17.007M2.996 20.003H12M4.997 16.002H12M6.998 12H12M8.398 7.998H12M15.501 5.497l2.502-2.5 2.5 2.5" />
                                                                    <path d="M-.005-.005h24.01v24.01H-.005z" />
                                                                </g>
                                                            </svg>
                                                        </span>
                                                    </div>
                                                    <mat-menu #valueMenu="matMenu" class="nested-menu">
                                                        <mat-tree [dataSource]="data.rankingDataSource"
                                                            [treeControl]="data.rankingTreeControl"
                                                            class="sub-menu-options-child">
                                                            <!-- This is the tree node template for leaf nodes -->
                                                            <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle
                                                                class="sub-menu-form-options-tree">

                                                                <li class="mat-tree-node sub-menu-checkbox-options"
                                                                    [matTooltip]="getDisplayName(node)" matTooltipPosition="right"
                                                                    *ngIf="node.params?.overlay?.geometryType !== DatasetGeometryType.POINT && node.params?.showCheckBox">
                                                                    <button mat-icon-button disabled></button>
                                                                    <mat-checkbox class="checklist-leaf-node"
                                                                        [checked]="node.selected"
                                                                        [disabled]="data.selectedRankingField !== undefined && data.selectedRankingField?.id !== node.params?.field.id"
                                                                        [(ngModel)]="node.selected"
                                                                        (ngModelChange)="onRankingFieldSelection(node.selected, node.params?.field, counter, columnNumber)">
                                                                        {{getDisplayName(node)}}</mat-checkbox>
                                                                </li>
                                                            </mat-tree-node>
                                                            <!-- This is the tree node template for expandable nodes -->
                                                            <mat-nested-tree-node
                                                                *matTreeNodeDef="let node; when: data.rankingTreeStrcuture.hasChild"
                                                                class="sub-menu-options-parent">
                                                                <li>
                                                                    <hr *ngIf="data.rankingDataSource.data.length > 1"  class="menu-line-separator" />
                                                                    <div class="mat-tree-node" [matTooltip]="node.name" matTooltipPosition="right">
                                                                        <span class="filter-inner-parent">{{node.name}}</span>
                                                                        <button mat-icon-button matTreeNodeToggle
                                                                            [attr.aria-label]="'toggle ' + node.name">
                                                                            <mat-icon class="mat-icon-rtl-mirror">
                                                                                {{data.rankingTreeControl.isExpanded(node)
                                                                                ? 'expand_more' : 'chevron_right'}}
                                                                            </mat-icon>
                                                                        </button>
                                                                    </div>
                                                                    <ul
                                                                        [class.tree-invisible]="!data.rankingTreeControl.isExpanded(node)">
                                                                        <ng-container matTreeNodeOutlet></ng-container>
                                                                    </ul>
                                                                </li>
                                                            </mat-nested-tree-node>
                                                        </mat-tree>
                                                    </mat-menu>
                                                    <div [matMenuTriggerFor]="columnsMenu"
                                                        style="cursor: pointer;">
                                                        <span class="report-icon header-icon"
                                                            title="Columns to display">
                                                            <!-- tasks-icon -->
                                                            <svg width="16" height="16" viewBox="0 0 24 24"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <g fill="none" fill-rule="evenodd">
                                                                    <path stroke="#002d57" stroke-width="1.5"
                                                                        stroke-linecap="round" stroke-linejoin="round"
                                                                        d="M7.998 3.997h12.005M2.996 3.997h1.001M2.996 7.998h1.001M2.996 12h1.001M7.998 7.998h12.005M7.998 12h2.001M17.002 21.004A5.002 5.002 0 0 1 12 16.002 5.08 5.08 0 0 1 17.002 11a5.002 5.002 0 0 1 0 10.004" />
                                                                    <path stroke="#002d57" stroke-width="1.5"
                                                                        stroke-linecap="round" stroke-linejoin="round"
                                                                        d="m18.665 15.167-2.083 2.085-1.25-1.25" />
                                                                    <path d="M-.005-.005h24.01v24.01H-.005z" />
                                                                </g>
                                                            </svg>
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <span class="report-icon header-icon stacks-icon" style="cursor: pointer"
                                                            [matMenuTriggerFor]="groupMenu" *ngIf="isComparisonModeActivated">
                                                            <svg xmlns="http://www.w3.org/2000/svg" height="15px" viewBox="0 -960 960 960" width="15px"
                                                                fill="#002d57">
                                                                <path
                                                                    d="M480-400 40-640l440-240 440 240-440 240Zm0 160L63-467l84-46 333 182 333-182 84 46-417 227Zm0 160L63-307l84-46 333 182 333-182 84 46L480-80Zm0-411 273-149-273-149-273 149 273 149Zm0-149Z" />
                                                            </svg>
                                                        </span>
                                                    </div>
                                                    <mat-menu #columnsMenu="matMenu" class="nested-menu">
                                                        <mat-tree [dataSource]="data.columnsToDisplayDataSource"
                                                            [treeControl]="data.columnsToDisplayTreeControl"
                                                            class="sub-menu-options-child">
                                                            <!-- This is the tree node template for leaf nodes -->
                                                            <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle
                                                                class="sub-menu-form-options-tree">

                                                                <li class="mat-tree-node sub-menu-checkbox-options"
                                                                    [matTooltip]="getDisplayName(node)" matTooltipPosition="right"
                                                                    *ngIf="node.params?.overlay?.geometryType !== DatasetGeometryType.POINT && node.params?.showCheckBox">
                                                                    <button mat-icon-button disabled></button>
                                                                    <mat-checkbox class="checklist-leaf-node"
                                                                        [checked]="node.selected"
                                                                        [disabled]="node.params?.field.id === data.selectedRankingField?.id"
                                                                        [(ngModel)]="node.selected"
                                                                        (ngModelChange)="onRankingFieldsMenuClick(node.selected, node.params?.field, data)">
                                                                        {{getDisplayName(node)}}</mat-checkbox>
                                                                </li>
                                                            </mat-tree-node>
                                                            <!-- This is the tree node template for expandable nodes -->
                                                            <mat-nested-tree-node
                                                                *matTreeNodeDef="let node; when: data.columnsToDisplayTreeStrcuture.hasChild"
                                                                class="sub-menu-options-parent">
                                                                <li>
                                                                    <hr *ngIf="data.columnsToDisplayDataSource.data.length > 1"  class="menu-line-separator" />
                                                                    <div class="mat-tree-node" [matTooltip]="node.name" matTooltipPosition="right">
                                                                        <span class="filter-inner-parent">{{node.name}}</span>
                                                                        <button mat-icon-button matTreeNodeToggle
                                                                            [attr.aria-label]="'toggle ' + node.name">
                                                                            <mat-icon class="mat-icon-rtl-mirror">
                                                                                {{data.columnsToDisplayTreeControl.isExpanded(node)
                                                                                ? 'expand_more' : 'chevron_right'}}
                                                                            </mat-icon>
                                                                        </button>

                                                                    </div>
                                                                    <ul
                                                                        [class.tree-invisible]="!data.columnsToDisplayTreeControl.isExpanded(node)">
                                                                        <ng-container matTreeNodeOutlet></ng-container>
                                                                    </ul>
                                                                </li>
                                                            </mat-nested-tree-node>
                                                <mat-menu #groupMenu="matMenu" (closed)="generateRankingReport(data)" class="analytics-mat-tree-container">
                                                    <div><hr class="menu-line-separator" />
                                                        <span class="group-compare-label">
                                                            <span>Compare</span>
                                                            <mat-icon (click)="$event.stopPropagation(); data.isCompareDropdownOpen = false" *ngIf="data.isCompareDropdownOpen">keyboard_arrow_down</mat-icon>
                                                            <mat-icon (click)="$event.stopPropagation(); data.isCompareDropdownOpen = true" *ngIf="!data.isCompareDropdownOpen">chevron_right</mat-icon>
                                                        </span>
                                                        <ng-container *ngIf="data.isCompareDropdownOpen">
                                                        <ul [ngClass]="ulClassName">
                                                            <ng-container *ngTemplateOutlet="recursiveList; context: { items: data._compareGroupsStrcuture, level: 0, section: 'compare' }"></ng-container>
                                                          </ul>
                                                        </ng-container>
                                                        <hr class="menu-line-separator" />
                                                        <span class="group-compare-label">
                                                            <span>With</span> 
                                                            <mat-icon (click)="$event.stopPropagation(); data.isCompareWithDropdownOpen = false" *ngIf="data.isCompareWithDropdownOpen">keyboard_arrow_down</mat-icon>
                                                            <mat-icon (click)="$event.stopPropagation(); data.isCompareWithDropdownOpen = true" *ngIf="!data.isCompareWithDropdownOpen">chevron_right</mat-icon>
                                                        </span>
                                                        <ng-container *ngIf="data.isCompareWithDropdownOpen">  
                                                            <ul [ngClass]="ulClassName">
                                                                <ng-container *ngTemplateOutlet="recursiveList; context: { items: data._withGroupsStrcuture, level: 0, section: 'with' }"></ng-container>
                                                            </ul>
                                                        </ng-container>  
                                                          <ng-template #recursiveList let-items="items" let-level="level" let-section="section">
                                                            <li *ngFor="let item of items" [style.margin-left.px]="level * 10">
                                                              <div class="parent-group-container">
                                                                <div class="toggle-icon" [ngClass]="{'invisble': !item?.children?.length}" (click)="$event.stopPropagation();toggleItemExpansion(item)">
                                                                  <mat-icon class="small-icon">{{ item.expanded ? 'keyboard_arrow_down' : 'keyboard_arrow_right' }}</mat-icon>
                                                                </div>
                                                                <mat-checkbox
                                                                  [(ngModel)]="item.selected"
                                                                  (change)="toggleItem($event, item, section, data)"
                                                                  [disabled]="item.disabled"
                                                                  (click)="$event.stopPropagation()"
                                                                  (keyup)="$event.stopPropagation()"
                                                                  matTooltip="{{ item.name }}" matTooltipPosition="right">
                                                                  {{ item.name }}
                                                                </mat-checkbox>
                                                              </div>
                                                              <ng-container *ngIf="item.children && item.expanded">
                                                                <ng-container *ngTemplateOutlet="recursiveList; context: { items: item.children, level: level + 1, section: section }"></ng-container>
                                                              </ng-container>
                                                            </li>
                                                          </ng-template>
                                                      <!-- <div>
                                                        <span class="group-compare-label">
                                                            <span>Compare</span>
                                                            <mat-icon (click)="$event.stopPropagation(); data.isCompareDropdownOpen = false" *ngIf="data.isCompareDropdownOpen">keyboard_arrow_down</mat-icon>
                                                            <mat-icon (click)="$event.stopPropagation(); data.isCompareDropdownOpen = true" *ngIf="!data.isCompareDropdownOpen">chevron_right</mat-icon>
                                                        </span>
                                                        <ng-container *ngIf="data.isCompareDropdownOpen">
                                                            <div class="groups-menu-options-container">
                                                                <ng-container *ngFor="let group of data._compareGroups">
                                                                    <mat-checkbox
                                                                        class="checklist-leaf-node"
                                                                        [value]="group.id"
                                                                        [checked]="group.isChecked"
                                                                        [(ngModel)]="group.isChecked"
                                                                        (change)="onCheckboxChange($event, 'compare', data)"
                                                                        [disabled]="group.disabled"
                                                                        (click)="$event.stopPropagation()"
                                                                        (keyup)="$event.stopPropagation()">
                                                                        <span class="group-checkbox-label">{{group?.name}}</span>
                                                                    </mat-checkbox>
                                                                </ng-container>
                                                            </div>
                                                        </ng-container>
                                                      </div> -->
                                                      <hr class="menu-line-separator" />
                                                      <!-- <div>
                                                        <span class="group-compare-label">
                                                            <span>With</span> 
                                                            <mat-icon (click)="$event.stopPropagation(); data.isCompareWithDropdownOpen = false" *ngIf="data.isCompareWithDropdownOpen">keyboard_arrow_down</mat-icon>
                                                            <mat-icon (click)="$event.stopPropagation(); data.isCompareWithDropdownOpen = true" *ngIf="!data.isCompareWithDropdownOpen">chevron_right</mat-icon>
                                                        </span>
                                                        <ng-container *ngIf="data.isCompareWithDropdownOpen">
                                                            <div class="groups-menu-options-container">
                                                                <ng-container *ngFor="let group of data._withGroups">
                                                                    <mat-checkbox
                                                                        class="checklist-leaf-node"
                                                                        [value]="group.id"
                                                                        [checked]="group.isChecked"
                                                                        [(ngModel)]="group.isChecked"
                                                                        (change)="onCheckboxChange($event, 'with', data)"
                                                                        [disabled]="group.disabled"
                                                                        (click)="$event.stopPropagation()"
                                                                        (keyup)="$event.stopPropagation()"
                                                                    >
                                                                    <span class="group-checkbox-label">{{group?.name}}</span>
                                                                    </mat-checkbox>
                                                                </ng-container>
                                                            </div>
                                                        </ng-container>
                                                      </div> -->
                                                    </div>
                                                  </mat-menu>
                                                        </mat-tree>
                                                    </mat-menu>
                                                </div>
                                                <div class="right-box-header">
                                                    <input *ngIf="hasAccess" class="ranking-input" type="number"
                                                        placeholder="# of items" matInput [(ngModel)]="data.noOfItems"
                                                        autocomplete="off" style="margin-right: 8px;"/>

                                                    <div *ngIf="hasAccess && (data.selectedRankingField || data.selectedFormula) && data.selectedFields.length > 0 && data.noOfItems > 0"
                                                        class="report-submit-btn" (click)="generateRankingReport(data)">
                                                        <!-- check-outline-icon -->
                                                        <svg width="16" height="16" viewBox="0 0 24 24"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <g transform="translate(-.005 -.005)" fill="none"
                                                                fill-rule="evenodd">
                                                                <circle stroke="#0044f1" stroke-width="1.5"
                                                                    stroke-linecap="round" stroke-linejoin="round"
                                                                    cx="12.005" cy="12.005" r="9.004" />
                                                                <path stroke="#0044f1" stroke-width="1.5"
                                                                    stroke-linecap="round" stroke-linejoin="round"
                                                                    d="m8.448 12.344 2.167 2.168-.014-.014 4.891-4.891" />
                                                                <path d="M0 0h24.01v24.01H0z" />
                                                            </g>
                                                        </svg>
                                                    </div>
                                                    <div>
                                                        <span [matMenuTriggerFor]="actionsMenu"
                                                            class="report-icon ellipsis-icon active">
                                                            <!-- dot-menu-icon -->
                                                            <svg width="13" height="13" viewBox="0 0 9 9"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <g fill="#002d57" fill-rule="evenodd">
                                                                    <circle cx="4.5" cy="1" r="1" />
                                                                    <circle cx="4.5" cy="4" r="1" />
                                                                    <circle cx="4.5" cy="7" r="1" />
                                                                </g>
                                                            </svg>
                                                        </span>
                                                        <mat-menu #actionsMenu="matMenu" class="nested-menu header-menu"
                                                            backdropClass="mat-menu-header">
                                                            <hr *ngIf="hasAccess" class="menu-line-separator" />
                                                            <div mat-menu-item *ngIf="hasAccess"
                                                                (click)="saveState(counter, columnNumber)">
                                                                <span>
                                                                    <!-- save-icon -->
                                                                    <svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                        <g transform="translate(-.005 -.005)" fill="none" fill-rule="evenodd">
                                                                            <path
                                                                                d="M20.008 11.005V7.564c0-.502-.199-.983-.553-1.337l-2.672-2.672a1.89 1.89 0 0 0-1.337-.554H5.002a2 2 0 0 0-2 2.001v13.006a2 2 0 0 0 2 2h6.003"
                                                                                stroke="#0044f1" stroke-width="1.5" stroke-linecap="round"
                                                                                stroke-linejoin="round" />
                                                                            <path
                                                                                d="M15 3.001v4.127a.876.876 0 0 1-.876.875H8.872a.876.876 0 0 1-.875-.875V3.001M7.003 20.008v-5.676a1.315 1.315 0 0 1 1.215-1.327h3.787"
                                                                                stroke="#0044f1" stroke-width="1.5" stroke-linecap="round"
                                                                                stroke-linejoin="round" />
                                                                            <circle stroke="#0044f1" stroke-width="1.5" stroke-linecap="round"
                                                                                stroke-linejoin="round" cx="17.507" cy="17.507" r="4.502" />
                                                                            <path stroke="#0044f1" stroke-width="1.5" stroke-linecap="round"
                                                                                stroke-linejoin="round" d="m18.907 16.808-1.75 1.752-1.05-1.052" />
                                                                            <path d="M0 0h24.01v24.01H0z" />
                                                                        </g>
                                                                    </svg>
                                                                </span>
                                                                Save
                                                            </div>
                                                            <hr *ngIf="hasAccess" class="menu-line-separator" />
                                                            <div *ngIf="hasAccess" mat-menu-item
                                                                (click)="saveAsWorkspaceItemToDashboard(counter, columnNumber)">
                                                                <span>
                                                                    <!-- save-icon -->
                                                                    <svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                        <g transform="translate(-.005 -.005)" fill="none" fill-rule="evenodd">
                                                                            <path
                                                                                d="M20.008 11.005V7.564c0-.502-.199-.983-.553-1.337l-2.672-2.672a1.89 1.89 0 0 0-1.337-.554H5.002a2 2 0 0 0-2 2.001v13.006a2 2 0 0 0 2 2h6.003"
                                                                                stroke="#0044f1" stroke-width="1.5" stroke-linecap="round"
                                                                                stroke-linejoin="round" />
                                                                            <path
                                                                                d="M15 3.001v4.127a.876.876 0 0 1-.876.875H8.872a.876.876 0 0 1-.875-.875V3.001M7.003 20.008v-5.676a1.315 1.315 0 0 1 1.215-1.327h3.787"
                                                                                stroke="#0044f1" stroke-width="1.5" stroke-linecap="round"
                                                                                stroke-linejoin="round" />
                                                                            <circle stroke="#0044f1" stroke-width="1.5" stroke-linecap="round"
                                                                                stroke-linejoin="round" cx="17.507" cy="17.507" r="4.502" />
                                                                            <path stroke="#0044f1" stroke-width="1.5" stroke-linecap="round"
                                                                                stroke-linejoin="round" d="m18.907 16.808-1.75 1.752-1.05-1.052" />
                                                                            <path d="M0 0h24.01v24.01H0z" />
                                                                        </g>
                                                                    </svg>
                                                                </span>
                                                                Save as
                                                            </div>
                                                            <hr class="menu-line-separator" />
                                                            <div mat-menu-item
                                                                (click)="downloadReport(counter, columnNumber)">
                                                                <span>
                                                                    <!-- download-arrow-icon -->
                                                                    <svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                        <g fill="none" fill-rule="evenodd">
                                                                            <path stroke="#0044f1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                                                d="M15.557 11.11 12 14.669l-3.557-3.557M12 3.997v10.671M20.003 16.446h0a3.557 3.557 0 0 1-3.557 3.557H7.554a3.557 3.557 0 0 1-3.557-3.557h0" />
                                                                            <path d="M-.005-.005h24.01v24.01H-.005z" />
                                                                        </g>
                                                                    </svg>
                                                                </span>
                                                                Download
                                                            </div>
                                                            <hr class="menu-line-separator" />
                                                            <div mat-menu-item *ngIf="hasAccess"
                                                                (click)="deleteWorkspaceItem(counter, columnNumber)">
                                                                <span>
                                                                    <!-- Trash-Icon -->
                                                                    <svg style="margin-right: 4px;" width="16" height="16"
                                                                        viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                        <g fill="none" fill-rule="evenodd">
                                                                            <path
                                                                                d="M18.5 6.5V19a2 2 0 0 1-2 2h-9a2 2 0 0 1-2-2V6.5M9 17h6M9 13h6M8.5 6.5v-2A1.5 1.5 0 0 1 10 3h4a1.5 1.5 0 0 1 1.5 1.5v2M20 6.5H4"
                                                                                stroke="#0044f1" stroke-width="1.5" stroke-linecap="round"
                                                                                stroke-linejoin="round" class="icon-actions" />
                                                                            <path d="M0 0h24v24H0z" />
                                                                        </g>
                                                                    </svg>
                                                                </span>
                                                                Delete
                                                            </div>
                                                        </mat-menu>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="report-content" [ngStyle]="data.style" mwlResizable
                                            [validateResize]="validate" [enableGhostResize]="true"
                                            [resizeSnapGrid]="{ left: 1, right: 1 }"
                                            (resizeEnd)="onResizeEnd($event, counter, columnNumber)">
                                            <div class="resize-handle-top" mwlResizeHandle
                                                [resizeEdges]="{ top: true }"></div>
                                            <div class="resize-handle-left" mwlResizeHandle
                                                [resizeEdges]="{ left: true }"></div>
                                            <div class="resize-handle-right" mwlResizeHandle
                                                [resizeEdges]="{ right: true }"></div>
                                            <div class="resize-handle-bottom" mwlResizeHandle
                                                [resizeEdges]="{ bottom: true }">
                                            </div>

                                            <div *ngIf="data.isResizing; else reportContentContainer"
                                                class="dashboard-loader-container">
                                                <mat-spinner diameter="50" color="accent"></mat-spinner>
                                            </div>

                                            <ng-template #reportContentContainer>
                                                <div class="report-table"
                                                    *ngIf="data.reportSubType===ReportDisplayType.TABLE">
                                                    <table [dataSource]="data.table.reportData" class="report-table"
                                                        mat-table matSort matSortDisableClear
                                                        (matSortChange)="sortData($event, counter, data.reportType, columnNumber)"
                                                        [matSortActive]="data?.sort?.active"
                                                        [matSortDirection]="data?.sort?.direction">

                                                        <ng-container [matColumnDef]="RANKING_COLUMN_ID">
                                                            <th mat-header-cell mat-sort-header *matHeaderCellDef>
                                                                Ranking</th>
                                                            <td mat-cell *matCellDef="let element"> {{element.ranking |
                                                                commaFormat}}
                                                            </td>
                                                            <td mat-footer-cell *matFooterCellDef>
                                                                {{data.table.sumByFieldId.size >
                                                                0 ?
                                                                'Total' : '' | commaFormat}}</td>
                                                        </ng-container>

                                                        <ng-container
                                                            *ngFor="let dynamicColumn of data.table.dynamicColumns | keyvalue; let i = index"
                                                            matColumnDef="{{dynamicColumn.key}}">
                                                            <th mat-header-cell mat-sort-header *matHeaderCellDef [matTooltip]="getFormattedName(dynamicColumn.value, false)" matTooltipPosition="above">
                                                               {{getFormattedName(dynamicColumn.value, false)
                                                                | commaFormat}}</th>
                                                            <td mat-cell *matCellDef="let element "
                                                                [matTooltip]="element.dynamicFieldValuesByIds.get(dynamicColumn.key) | commaFormat">
                                                                {{element.dynamicFieldValuesByIds.get(dynamicColumn.key)
                                                                |
                                                                commaFormat}}
                                                            </td>
                                                            <td mat-footer-cell *matFooterCellDef>
                                                                {{data.table.sumByFieldId.get(dynamicColumn.key) |
                                                                commaFormat}}
                                                            </td>
                                                        </ng-container>

                                                        <tr mat-header-row
                                                            *matHeaderRowDef="data.table.columnsToDisplay; sticky: true">
                                                        </tr>
                                                        <tr mat-row
                                                            *matRowDef="let row; columns: data.table.columnsToDisplay">
                                                        </tr>
                                                        <tr mat-footer-row
                                                            *matFooterRowDef="data.table.columnsToDisplay"></tr>
                                                    </table>
                                                </div>
                                            </ng-template>

                                        </div>
                                    </div>
                                    <!-- COUNT -->
                                    <div class="report-element" *ngIf="data?.reportType === REPORT_TYPE_MASTER.COUNT">
                                        <header class="panel-name dashboard-panel">
                                            <div class="panel-title report-title-container"><input
                                                    class="report-bottom-title" style="text-align: left !important;"
                                                    [(ngModel)]="data.reportName" [disabled]="!hasAccess"
                                                    (blur)="updateWorkspaceItemName($event, counter, columnNumber)"
                                                    placeholder="Count">
                                            </div>
                                            <div>
                                                <span (click)="removeAnalyticData(counter, columnNumber)"
                                                    class="report-icon active" style="cursor: pointer;">
                                                    <!-- eye-slash-icon -->
                                                    <svg width="24" height="24" viewBox="0 0 24 24"
                                                        xmlns="http://www.w3.org/2000/svg" style="position: relative;top: 4px;">
                                                        <g fill="none" fill-rule="evenodd">
                                                            <path d="M14.558 13.558a3.003 3.003 0 1 1-4.114-4.115"
                                                                stroke="#0044f1" stroke-width="1.5"
                                                                stroke-linecap="round" stroke-linejoin="round" />
                                                            <path
                                                                d="M17.997 16.996A9.873 9.873 0 0 1 12 19.003a9.457 9.457 0 0 1-8.558-5.09 4.323 4.323 0 0 1 0-3.826c.829-1.65 2.149-3 3.779-3.867M20.427 14.134c.04-.076.093-.144.13-.221a4.323 4.323 0 0 0 0-3.826A9.457 9.457 0 0 0 12 4.997c-.225 0-.443.03-.665.045M21.004 20.003 3.997 2.996"
                                                                stroke="#0044f1" stroke-width="1.5"
                                                                stroke-linecap="round" stroke-linejoin="round" />
                                                            <path d="M-.005-.005h24.01v24.01H-.005z" />
                                                        </g>
                                                    </svg>
                                                </span>
                                                <ng-container *ngIf="analyticData.length > 1">
                                                    <span cdkDragHandle class="drag-handler">
                                                        <i class="fal fa-arrows"></i>
                                                    </span>
                                                </ng-container>
                                            </div>
                                        </header>
                                        <div class="report-header">
                                            <div>
                                                <div class="report-header-left-actions">
                                                    <div *ngIf="hasAccess" [matMenuTriggerFor]="groupByMenu"
                                                        style="cursor: pointer;">
                                                        <span class="report-icon header-icon" title="Group by fields">
                                                            <!-- tasks-icon -->
                                                            <svg width="16" height="16" viewBox="0 0 24 24"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <g fill="none" fill-rule="evenodd">
                                                                    <path stroke="#002d57" stroke-width="1.5"
                                                                        stroke-linecap="round" stroke-linejoin="round"
                                                                        d="M7.998 3.997h12.005M2.996 3.997h1.001M2.996 7.998h1.001M2.996 12h1.001M7.998 7.998h12.005M7.998 12h2.001M17.002 21.004A5.002 5.002 0 0 1 12 16.002 5.08 5.08 0 0 1 17.002 11a5.002 5.002 0 0 1 0 10.004" />
                                                                    <path stroke="#002d57" stroke-width="1.5"
                                                                        stroke-linecap="round" stroke-linejoin="round"
                                                                        d="m18.665 15.167-2.083 2.085-1.25-1.25" />
                                                                    <path d="M-.005-.005h24.01v24.01H-.005z" />
                                                                </g>
                                                            </svg>
                                                        </span>
                                                    </div>
                                                    <span class="report-icon header-icon stacks-icon" style="cursor: pointer"
                                                        [matMenuTriggerFor]="groupMenu" *ngIf="isComparisonModeActivated  && hasAccess">
                                                        <svg xmlns="http://www.w3.org/2000/svg" height="15px" viewBox="0 -960 960 960" width="15px"
                                                            fill="#002d57">
                                                            <path
                                                                d="M480-400 40-640l440-240 440 240-440 240Zm0 160L63-467l84-46 333 182 333-182 84 46-417 227Zm0 160L63-307l84-46 333 182 333-182 84 46L480-80Zm0-411 273-149-273-149-273 149 273 149Zm0-149Z" />
                                                        </svg>
                                                    </span>
                                                </div>
                                                <mat-menu #groupByMenu="matMenu" class="nested-menu"
                                                    (closed)="generateCountReport(data)">
                                                    <!-- Start Added Location option in list for group by field at Add count section - Date:11 April 2022 -->
                                                    <mat-tree [dataSource]="data.dataSource"
                                                        [treeControl]="data.treeControl" class="sub-menu-options-child">
                                                        <!-- This is the tree node template for leaf nodes -->
                                                        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle
                                                            class="sub-menu-form-options-tree">

                                                            <li class="mat-tree-node sub-menu-checkbox-options"
                                                                [matTooltip]="getDisplayName(node)" matTooltipPosition="right"
                                                                *ngIf="node.params?.overlay?.geometryType !== DatasetGeometryType.POINT && node.params?.showCheckBox">
                                                                <button mat-icon-button disabled></button>
                                                                <mat-checkbox class="checklist-leaf-node"
                                                                    [checked]="node.selected"
                                                                    [disabled]="!node.selected && data.selectedFieldsByDatasetCount == AnalyticsUtils.fieldSelectLimitCount(isComparisonModeActivated)"
                                                                    [(ngModel)]="node.selected"
                                                                    (ngModelChange)="onCountFieldsMenuClick(node.selected, node.params?.field, counter, columnNumber, node?.params?.overlay)">
                                                                    {{getDisplayName(node)}}</mat-checkbox>
                                                            </li>
                                                        </mat-tree-node>
                                                        <!-- This is the tree node template for expandable nodes -->
                                                        <mat-nested-tree-node
                                                            *matTreeNodeDef="let node; when: data.filterTreeStrcuture.hasChild"
                                                            class="sub-menu-options-parent">
                                                            <li>
                                                                <hr *ngIf="data.dataSource.data.length > 1"  class="menu-line-separator" />
                                                                <div class="mat-tree-node" [matTooltip]="node.name" matTooltipPosition="right">
                                                                    <span class="filter-inner-parent">{{node.name}}</span>
                                                                    <button mat-icon-button matTreeNodeToggle
                                                                        [attr.aria-label]="'toggle ' + node.name">
                                                                        <mat-icon class="mat-icon-rtl-mirror">
                                                                            {{data.treeControl.isExpanded(node) ?
                                                                            'expand_more' : 'chevron_right'}}
                                                                        </mat-icon>
                                                                    </button>

                                                                </div>
                                                                <ul
                                                                    [class.tree-invisible]="!data.treeControl.isExpanded(node)">
                                                                    <ng-container matTreeNodeOutlet></ng-container>
                                                                </ul>
                                                            </li>
                                                        </mat-nested-tree-node>
                                                    </mat-tree>
                                                </mat-menu>
                                                <mat-menu #groupMenu="matMenu" (closed)="generateCountReport(data)" class="analytics-mat-tree-container">
                                                    <div>
                                                        <hr class="menu-line-separator" />
                                                        
                                                        <span class="group-compare-label">
                                                            <span>Compare</span>
                                                            <mat-icon (click)="$event.stopPropagation(); data.isCompareDropdownOpen = false" *ngIf="data.isCompareDropdownOpen">keyboard_arrow_down</mat-icon>
                                                            <mat-icon (click)="$event.stopPropagation(); data.isCompareDropdownOpen = true" *ngIf="!data.isCompareDropdownOpen">chevron_right</mat-icon>
                                                        </span>
                                                        <ng-container *ngIf="data.isCompareDropdownOpen">
                                                            <ul [ngClass]="ulClassName">
                                                                <ng-container *ngTemplateOutlet="recursiveList; context: { items: data._compareGroupsStrcuture, level: 0, section: 'compare' }"></ng-container>
                                                            </ul>
                                                        </ng-container>

                                                        <hr class="menu-line-separator" />

                                                        <span class="group-compare-label">
                                                            <span>With</span> 
                                                            <mat-icon (click)="$event.stopPropagation(); data.isCompareWithDropdownOpen = false" *ngIf="data.isCompareWithDropdownOpen">keyboard_arrow_down</mat-icon>
                                                            <mat-icon (click)="$event.stopPropagation(); data.isCompareWithDropdownOpen = true" *ngIf="!data.isCompareWithDropdownOpen">chevron_right</mat-icon>
                                                        </span>
                                                        <ng-container *ngIf="data.isCompareWithDropdownOpen">
                                                            <ul [ngClass]="ulClassName">
                                                              <ng-container *ngTemplateOutlet="recursiveList; context: { items: data._withGroupsStrcuture, level: 0, section: 'with' }"></ng-container>
                                                            </ul>
                                                        </ng-container>
                                                          
                                                          
                                                        <ng-template #recursiveList let-items="items" let-level="level" let-section="section">
                                                            <li *ngFor="let item of items" [style.margin-left.px]="level * 10" class="analytics-mat-tree">
                                                              <div class="parent-group-container">
                                                                <div class="toggle-icon" [ngClass]="{'invisble': !item?.children?.length}" (click)="$event.stopPropagation();toggleItemExpansion(item)">
                                                                  <mat-icon class="small-icon">{{ item.expanded ? 'keyboard_arrow_down' : 'keyboard_arrow_right' }}</mat-icon>
                                                                </div>
                                                                <mat-checkbox
                                                                  [(ngModel)]="item.selected"
                                                                  (change)="toggleItem($event, item, section, data)"
                                                                  [disabled]="item.disabled"
                                                                  (click)="$event.stopPropagation()"
                                                                  (keyup)="$event.stopPropagation()"
                                                                  matTooltip="{{ item.name }}" matTooltipPosition="right">
                                                                  {{ item.name }}
                                                                </mat-checkbox>
                                                              </div>
                                                              <ng-container *ngIf="item.children && item.expanded">
                                                                <ng-container *ngTemplateOutlet="recursiveList; context: { items: item.children, level: level + 1, section: section }"></ng-container>
                                                              </ng-container>
                                                            </li>
                                                        </ng-template>  
                                                      </div>
                                                  </mat-menu>
                                            </div>
                                            <div class="report-type-icons-container">
                                                <span
                                                    (click)="setDisplayType(counter, ReportDisplayType.TABLE, columnNumber)"
                                                    class="report-icon"
                                                    [ngClass]="{'selected': data.reportSubType===ReportDisplayType.TABLE, 'active' : ''}">
                                                    <!-- document-icon -->
                                                    <!-- <svg width="16" height="16" viewBox="0 0 24 24"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <g fill="none" fill-rule="evenodd">
                                                            <path d="M0 0v24h24V0z" />
                                                            <path d="M21 15h-4a2 2 0 0 0-2 2v4" stroke="#002d57"
                                                                class="chart-icons" stroke-width="1.5"
                                                                stroke-linecap="round" stroke-linejoin="round" />
                                                            <path
                                                                d="M7 3h10a4 4 0 0 1 4 4v8.343a4 4 0 0 1-1.172 2.829l-1.656 1.656A4 4 0 0 1 15.343 21H7a4 4 0 0 1-4-4V7a4 4 0 0 1 4-4zM8 12h8M8 8h8M8 16h3"
                                                                stroke="#002d57" class="chart-icons" stroke-width="1.5"
                                                                stroke-linecap="round" stroke-linejoin="round" />
                                                        </g>
                                                    </svg> -->
                                                    <i class="fal fa-table chart-icons" style="font-size: 14px; position: relative;bottom: 1px;"></i>
                                                </span>
                                                <span
                                                    (click)="setDisplayType(counter, ReportDisplayType.BAR_CHART, columnNumber)"
                                                    class="report-icon"
                                                    [ngClass]="{'selected': data.reportSubType===ReportDisplayType.BAR_CHART, 'active' : ''}">
                                                    <!-- chart-bar-icon -->
                                                    <svg width="16" height="16" viewBox="0 0 24 24"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <g fill="none" fill-rule="evenodd">
                                                            <path
                                                                d="M16.5 3.5h3A1.5 1.5 0 0 1 21 5v14a1.5 1.5 0 0 1-1.5 1.5H15V5a1.5 1.5 0 0 1 1.5-1.5zM15 20.5H9v-10A1.5 1.5 0 0 1 10.5 9H15M4.5 15H9v5.5H4.5A1.5 1.5 0 0 1 3 19v-2.5A1.5 1.5 0 0 1 4.5 15z"
                                                                stroke="#002d57" stroke-width="1.5"
                                                                stroke-linecap="round" stroke-linejoin="round"
                                                                class="chart-icons" />
                                                            <path d="M24 24H0V0h24z" />
                                                        </g>
                                                    </svg>
                                                </span>
                                                <ng-container *ngIf="!isComparisonModeActivated">
                                                    <span
                                                        (click)="setDisplayType(counter, ReportDisplayType.PIE_CHART, columnNumber)"
                                                        class="report-icon"
                                                        [ngClass]="{'selected': data.reportSubType===ReportDisplayType.PIE_CHART, 'active' : ''}">
                                                        <!-- chart-pie-icon -->
                                                        <!-- <svg width="16" height="16" viewBox="0 0 24 24"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <g fill="none" fill-rule="evenodd">
                                                                <path d="M12 17.5a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11"
                                                                    class="chart-icons" stroke="#002d57" stroke-width="1.5"
                                                                    stroke-linecap="round" stroke-linejoin="round" />
                                                                <rect class="chart-icons" stroke="#002d57"
                                                                    stroke-width="1.5" stroke-linecap="round"
                                                                    stroke-linejoin="round" x="2.5" y="2.5" width="19"
                                                                    height="19" rx="9.5" />
                                                                <path class="chart-icons" stroke="#002d57"
                                                                    stroke-width="1.5" stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                    d="M15.89 15.889 12 11.999V6.51" />
                                                                <path d="M0 0h24v24H0z" />
                                                            </g>
                                                        </svg> -->
                                                        <i class="fal fa-chart-pie chart-icons" style="font-size: 14px; position: relative;bottom: 1px;"></i>
                                                    </span>
                                                </ng-container>
                                                <span [matMenuTriggerFor]="actionsMenu"
                                                    class="report-icon active ellipsis-icon">
                                                    <!-- dot-menu-icon -->
                                                    <svg width="13" height="13" viewBox="0 0 9 9"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <g fill="#002d57" fill-rule="evenodd">
                                                            <circle cx="4.5" cy="1" r="1" />
                                                            <circle cx="4.5" cy="4" r="1" />
                                                            <circle cx="4.5" cy="7" r="1" />
                                                        </g>
                                                    </svg>
                                                </span>
                                                <mat-menu #actionsMenu="matMenu" class="nested-menu header-menu"
                                                    backdropClass="mat-menu-header">
                                                    <hr *ngIf="hasAccess" class="menu-line-separator" />
                                                    <div mat-menu-item *ngIf="hasAccess"
                                                        (click)="saveState(counter, columnNumber)">
                                                        <span>
                                                            <!-- save-icon -->
                                                            <svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                <g transform="translate(-.005 -.005)" fill="none" fill-rule="evenodd">
                                                                    <path
                                                                        d="M20.008 11.005V7.564c0-.502-.199-.983-.553-1.337l-2.672-2.672a1.89 1.89 0 0 0-1.337-.554H5.002a2 2 0 0 0-2 2.001v13.006a2 2 0 0 0 2 2h6.003"
                                                                        stroke="#0044f1" stroke-width="1.5" stroke-linecap="round"
                                                                        stroke-linejoin="round" />
                                                                    <path
                                                                        d="M15 3.001v4.127a.876.876 0 0 1-.876.875H8.872a.876.876 0 0 1-.875-.875V3.001M7.003 20.008v-5.676a1.315 1.315 0 0 1 1.215-1.327h3.787"
                                                                        stroke="#0044f1" stroke-width="1.5" stroke-linecap="round"
                                                                        stroke-linejoin="round" />
                                                                    <circle stroke="#0044f1" stroke-width="1.5" stroke-linecap="round"
                                                                        stroke-linejoin="round" cx="17.507" cy="17.507" r="4.502" />
                                                                    <path stroke="#0044f1" stroke-width="1.5" stroke-linecap="round"
                                                                        stroke-linejoin="round" d="m18.907 16.808-1.75 1.752-1.05-1.052" />
                                                                    <path d="M0 0h24.01v24.01H0z" />
                                                                </g>
                                                            </svg>
                                                        </span>
                                                        Save
                                                    </div>
                                                    <hr *ngIf="hasAccess" class="menu-line-separator" />
                                                    <div *ngIf="hasAccess" mat-menu-item
                                                        (click)="saveAsWorkspaceItemToDashboard(counter, columnNumber)">
                                                        <span>
                                                            <!-- save-icon -->
                                                            <svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                <g transform="translate(-.005 -.005)" fill="none" fill-rule="evenodd">
                                                                    <path
                                                                        d="M20.008 11.005V7.564c0-.502-.199-.983-.553-1.337l-2.672-2.672a1.89 1.89 0 0 0-1.337-.554H5.002a2 2 0 0 0-2 2.001v13.006a2 2 0 0 0 2 2h6.003"
                                                                        stroke="#0044f1" stroke-width="1.5" stroke-linecap="round"
                                                                        stroke-linejoin="round" />
                                                                    <path
                                                                        d="M15 3.001v4.127a.876.876 0 0 1-.876.875H8.872a.876.876 0 0 1-.875-.875V3.001M7.003 20.008v-5.676a1.315 1.315 0 0 1 1.215-1.327h3.787"
                                                                        stroke="#0044f1" stroke-width="1.5" stroke-linecap="round"
                                                                        stroke-linejoin="round" />
                                                                    <circle stroke="#0044f1" stroke-width="1.5" stroke-linecap="round"
                                                                        stroke-linejoin="round" cx="17.507" cy="17.507" r="4.502" />
                                                                    <path stroke="#0044f1" stroke-width="1.5" stroke-linecap="round"
                                                                        stroke-linejoin="round" d="m18.907 16.808-1.75 1.752-1.05-1.052" />
                                                                    <path d="M0 0h24.01v24.01H0z" />
                                                                </g>
                                                            </svg>
                                                        </span>
                                                        Save as
                                                    </div>
                                                    <hr class="menu-line-separator" />
                                                    <div mat-menu-item (click)="downloadReport(counter, columnNumber)">
                                                        <span>
                                                            <!-- download-arrow-icon -->
                                                            <svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                <g fill="none" fill-rule="evenodd">
                                                                    <path stroke="#0044f1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                                        d="M15.557 11.11 12 14.669l-3.557-3.557M12 3.997v10.671M20.003 16.446h0a3.557 3.557 0 0 1-3.557 3.557H7.554a3.557 3.557 0 0 1-3.557-3.557h0" />
                                                                    <path d="M-.005-.005h24.01v24.01H-.005z" />
                                                                </g>
                                                            </svg>
                                                        </span>
                                                        Download
                                                    </div>
                                                    <hr class="menu-line-separator" />
                                                    <div mat-menu-item *ngIf="hasAccess"
                                                        (click)="deleteWorkspaceItem(counter, columnNumber)">
                                                        <span>
                                                            <!-- Trash-Icon -->
                                                            <svg style="margin-right: 4px;" width="16" height="16"
                                                                viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                <g fill="none" fill-rule="evenodd">
                                                                    <path
                                                                        d="M18.5 6.5V19a2 2 0 0 1-2 2h-9a2 2 0 0 1-2-2V6.5M9 17h6M9 13h6M8.5 6.5v-2A1.5 1.5 0 0 1 10 3h4a1.5 1.5 0 0 1 1.5 1.5v2M20 6.5H4"
                                                                        stroke="#0044f1" stroke-width="1.5" stroke-linecap="round"
                                                                        stroke-linejoin="round" class="icon-actions" />
                                                                    <path d="M0 0h24v24H0z" />
                                                                </g>
                                                            </svg>
                                                        </span>
                                                        Delete
                                                    </div>
                                                </mat-menu>
                                            </div>
                                        </div>

                                        <div class="report-content" [ngStyle]="data.style" mwlResizable
                                            [validateResize]="validate" [enableGhostResize]="true"
                                            [resizeSnapGrid]="{ left: 1, right: 1 }"
                                            (resizeEnd)="onResizeEnd($event, counter, columnNumber)">
                                            <div class="resize-handle-top" mwlResizeHandle
                                                [resizeEdges]="{ top: true }"></div>
                                            <div class="resize-handle-left" mwlResizeHandle
                                                [resizeEdges]="{ left: true }"></div>
                                            <div class="resize-handle-right" mwlResizeHandle
                                                [resizeEdges]="{ right: true }"></div>
                                            <div class="resize-handle-bottom" mwlResizeHandle
                                                [resizeEdges]="{ bottom: true }">
                                            </div>

                                            <div *ngIf="data.isResizing; else reportContentContainer"
                                                class="dashboard-loader-container">
                                                <mat-spinner diameter="50" color="accent"></mat-spinner>
                                            </div>

                                            <ng-template #reportContentContainer>
                                                <div class="report-table"
                                                    *ngIf="data.reportSubType===ReportDisplayType.TABLE && !isComparisonModeActivated">
                                                    <table [dataSource]="data.table.reportData" class="report-table"
                                                        mat-table matSort matSortDisableClear
                                                        (matSortChange)="sortData($event, counter, data.reportType, columnNumber)"
                                                        [matSortActive]="data?.sort?.active"
                                                        [matSortDirection]="data?.sort?.direction">

                                                        <ng-container
                                                            *ngFor="let dynamicColumn of data.table.dynamicColumns | keyvalue; let i = index"
                                                            matColumnDef="{{dynamicColumn.key}}">
                                                            <th mat-header-cell mat-sort-header *matHeaderCellDef>
                                                                {{dynamicColumn.value}}</th>
                                                            <td mat-cell *matCellDef="let element ">
                                                                {{element.dynamicFieldValuesByIds.get(dynamicColumn.key)}}
                                                            </td>
                                                            <td mat-footer-cell *matFooterCellDef>{{i === 0 ? 'Total' :
                                                                '' |
                                                                commaFormat}}</td>
                                                        </ng-container>

                                                        <ng-container [matColumnDef]="PERCENTAGE_COLUMN_ID">
                                                            <th mat-header-cell mat-sort-header *matHeaderCellDef>Total
                                                                %</th>
                                                            <td mat-cell *matCellDef="let element">
                                                                {{element.percentage}}%</td>
                                                            <td mat-footer-cell *matFooterCellDef>100%</td>
                                                        </ng-container>

                                                        <ng-container [matColumnDef]="COUNT_COLUMN_ID">
                                                            <th mat-header-cell mat-sort-header *matHeaderCellDef>Count
                                                            </th>
                                                            <td mat-cell *matCellDef="let element"> {{element.count |
                                                                commaFormat}}</td>
                                                            <td mat-footer-cell *matFooterCellDef>
                                                                {{data.table.totalCount |
                                                                commaFormat}}
                                                            </td>
                                                        </ng-container>

                                                        <tr mat-header-row
                                                            *matHeaderRowDef="data.table.columnsToDisplay; sticky: true">
                                                        </tr>
                                                        <tr mat-row
                                                            *matRowDef="let row; columns: data.table.columnsToDisplay">
                                                        </tr>
                                                        <tr mat-footer-row
                                                            *matFooterRowDef="data.table.columnsToDisplay"></tr>
                                                    </table>
                                                </div>
                                                <div class="report-table" *ngIf="data.reportSubType===ReportDisplayType.TABLE && isComparisonModeActivated">
                                                    <table mat-table matSort matSortDisableClear (matSortChange)="sortData($event, counter, data.reportType, columnNumber)"
                                                        [matSortActive]="data?.sort?.active" [matSortDirection]="data?.sort?.direction" [dataSource]="data.table.reportData"
                                                        class="report-table">

                                                        <ng-container *ngFor="let dynamicColumn of data.table.dynamicColumns | keyvalue; let i = index"
                                                            matColumnDef="{{dynamicColumn.key}}">
                                                            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{dynamicColumn.value}}</th>
                                                            <td mat-cell *matCellDef="let element">
                                                                {{element.dynamicFieldValuesByIds.get(dynamicColumn.key)}}
                                                            </td>
                                                            <td mat-footer-cell *matFooterCellDef>{{i === 0 ? 'Total' : '' | commaFormat}}</td>
                                                        </ng-container>
                                                        
                                                        <ng-container [matColumnDef]="PRIMARY_GROUP_COUNT_COLUMN_ID">
                                                            <th mat-header-cell mat-sort-header *matHeaderCellDef [matTooltip]="AnalyticsUtils.getGroupName(AnayticsConstants.PRIMARY_GROUP_INDEX, data.activeGroups, false)" matTooltipPosition="above">{{ AnalyticsUtils.getGroupName(AnayticsConstants.PRIMARY_GROUP_INDEX, data.activeGroups, true) }} Count</th>
                                                            <td mat-cell *matCellDef="let element"> {{element.primaryGroupCount | commaFormat}}</td>
                                                            <td mat-footer-cell *matFooterCellDef> {{data.table.totalCountPrimaryGroup | commaFormat}} </td>
                                                        </ng-container>

                                                        <ng-container [matColumnDef]="SECONDARY_GROUP_COUNT_COLUMN_ID">
                                                            <th mat-header-cell mat-sort-header *matHeaderCellDef [matTooltip]="AnalyticsUtils.getGroupName(AnayticsConstants.SECONDARY_GROUP_INDEX, data.activeGroups, false)" matTooltipPosition="above">{{ AnalyticsUtils.getGroupName(AnayticsConstants.SECONDARY_GROUP_INDEX, data.activeGroups, true) }} Count</th>
                                                            <td mat-cell *matCellDef="let element"> {{element.secondaryGroupCount | commaFormat}}</td>
                                                            <td mat-footer-cell *matFooterCellDef> {{data.table.totalCountSecondaryGroup | commaFormat}} </td>
                                                        </ng-container>

                                                        <ng-container [matColumnDef]="COMPARE_AMOUNT_COLUMN_ID">
                                                            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ AnalyticsUtils.getGroupName(AnayticsConstants.PRIMARY_GROUP_INDEX, data.activeGroups, true) }} vs {{
                                                                AnalyticsUtils.getGroupName(AnayticsConstants.SECONDARY_GROUP_INDEX, data.activeGroups, true) }}</th>
                                                            <td mat-cell *matCellDef="let element"> {{element.comparedGroupAmount | commaFormat}}</td>
                                                            <td mat-footer-cell *matFooterCellDef>{{ (data.table.totalCountPrimaryGroup - data.table.totalCountSecondaryGroup) | commaFormat}}</td>
                                                        </ng-container>

                                                        <ng-container [matColumnDef]="COMPARE_COLUMN_ID">
                                                            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ AnalyticsUtils.getGroupName(AnayticsConstants.PRIMARY_GROUP_INDEX, data.activeGroups, true) }} vs {{
                                                                AnalyticsUtils.getGroupName(AnayticsConstants.SECONDARY_GROUP_INDEX, data.activeGroups, true) }} (%)</th>
                                                            <td mat-cell *matCellDef="let element"> {{element.comparedGroupPercentage | commaFormat}}%</td>
                                                            <td mat-footer-cell *matFooterCellDef>{{ RandomUtils.roundUp(AnalyticsUtils.calculateVariancePercentage(data.table.totalCountPrimaryGroup, data.table.totalCountSecondaryGroup)) }}%</td>
                                                        </ng-container>

                                                        <tr mat-header-row *matHeaderRowDef="data.table.columnsToDisplay; sticky: true"></tr>
                                                        <tr mat-row *matRowDef="let row; columns: data.table.columnsToDisplay"></tr>
                                                        <tr mat-footer-row *matFooterRowDef="data.table.columnsToDisplay"></tr>
                                                    </table>
                                                </div>
                                                <div *ngIf="data.reportSubType===ReportDisplayType.BAR_CHART"
                                                    class="chart-background">
                                                    <div *ngIf="data.selectedFieldsByDatasetCount > 2"
                                                        class="report-instructions">
                                                        Unable to create a graph with more than two fields selected for
                                                        grouping
                                                        by
                                                    </div>
                                                    <canvas
                                                        *ngIf="data.chart.chartDatasets.length && data.selectedFieldsByDatasetCount <= 2"
                                                        mdbChart chartType="bar" [datasets]="data.chart.chartDatasets"
                                                        [labels]="data.chart.chartLabels"
                                                        [options]="data.chart.chartOptions"
                                                        [colors]="data.chart.chartColors" [legend]="false">
                                                    </canvas>
                                                </div>

                                                <div *ngIf="data.reportSubType===ReportDisplayType.PIE_CHART"
                                                    class="chart-background">
                                                    <div *ngIf="data.selectedFieldsByDatasetCount > 2"
                                                        class="report-instructions">
                                                        Unable to create a graph with more than two fields selected for
                                                        grouping by
                                                    </div>
                                                    <div
                                                        *ngIf="data.chart.chartDatasets.length && data.selectedFieldsByDatasetCount <= 2">
                                                        <canvas [colors]="data.chart.chartColors"
                                                            [datasets]="data.chart.chartDatasets"
                                                            [labels]="data.chart.chartLabels"
                                                            [legend]="data.selectedFieldsByDatasetCount >= 1"
                                                            [options]="data.chart.chartOptions" chartType="pie"
                                                            mdbChart>
                                                        </canvas>
                                                    </div>
                                                </div>
                                            </ng-template>

                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                </ng-container>
            </section>
        </div>

        <div class="footer-actions">
            <div class="footer-group footer-group-custom">
                <span (click)="switchView('dashboard')" [ngClass]="{'footer-button-active': true}" style="padding: 6px;"
                    class="footer-button">
                    <!-- Dashboard-icon -->
                    <div class="icon-container-wrap">
                        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
                            class="dashboard-icon">
                            <g fill="none" fill-rule="evenodd">
                                <path d="M24 24H0V0h24z" />
                                <path d="M0 0h24v24H0z" />
                                <path class="icon-primary" stroke="#002d57" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M7.5 17H8M16 17h.5M12 17l2.5-2.5M12 13v-.5M9.172 14.172l-.354-.354M18 21H6a3 3 0 0 1-3-3V6a3 3 0 0 1 3-3h12a3 3 0 0 1 3 3v12a3 3 0 0 1-3 3zM3 9h18" />
                                <path class="icon-primary" stroke="#002d57" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M11 5.995 10.995 6l.005.005.005-.005L11 5.995M8.5 5.995 8.495 6l.005.005L8.505 6 8.5 5.995M6 5.995 5.995 6 6 6.005 6.005 6 6 5.995" />
                                <path class="icon-primary" stroke="#002d57" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M11 5.995 10.995 6l.005.005.005-.005L11 5.995M8.5 5.995 8.495 6l.005.005L8.505 6 8.5 5.995M6 5.995 5.995 6 6 6.005 6.005 6 6 5.995" />
                            </g>
                        </svg>
                    </div>
                </span>
                <span (click)="switchView('table')" style="padding: 6px;" class="footer-button">
                    <!-- burger-icon -->
                    <div class="icon-container-wrap">
                        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
                            class="dashboard-icon">
                            <g fill="none" fill-rule="evenodd">
                                <path class="icon-primary" stroke="#002d57" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M5.498 12h13.005M5.498 16.002h13.005M5.497 7.998h13.005" />
                                <path d="M-.005-.005h24.01v24.01H-.005z" />
                            </g>
                        </svg>
                    </div>
                </span>
                <span (click)="switchView('map')" style="padding: 6px;" class="footer-button">
                    <!-- travel-map-icon -->
                    <div class="icon-container-wrap">
                        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
                            class="dashboard-icon">
                            <g fill="none" fill-rule="evenodd">
                                <path class="icon-primary" stroke="#002d57" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M16 18.457V13.25M8 18.463V13.25M5.083 8l-1.597.959A1 1 0 0 0 3 9.816v9.668a1 1 0 0 0 1.514.857l2.91-1.745a1 1 0 0 1 1.114.058L11.4 20.8a1 1 0 0 0 1.2 0l2.862-2.147a1 1 0 0 1 1.115-.057l2.909 1.745A1 1 0 0 0 21 19.484V9.816a1 1 0 0 0-.486-.857L18.917 8M12 15.25v5.748M8 6h0a4 4 0 0 1 4-4h0a4 4 0 0 1 4 4h0" />
                                <path class="icon-primary"
                                    d="M8 6c0 1.807 1.993 3.735 3.166 4.713.49.382 1.177.383 1.667 0C14.006 9.736 16 7.808 16 6"
                                    stroke="#002d57" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round" />
                                <path class="icon-primary" d="M12.001 5.639a.25.25 0 1 1-.002.5.25.25 0 0 1 .002-.5"
                                    stroke="#002d57" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round" />
                                <path d="M0 0h24v24H0z" />
                            </g>
                        </svg>
                    </div>
                </span>
            </div>
        </div>
    </div>
</div>
<app-workspace-item-dialog #workspaceItemDialog [accountId]="dataset?.accountID" [datasetId]="dataset?.id"
    (itemSubmitted)="rightPanelCall()"></app-workspace-item-dialog>
