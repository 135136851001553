// tslint:disable:max-file-line-count
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
} from "@angular/core";
import {
    defaultMapSettings,
    drawingOptions,
    shapeMeasurementColor,
    shapeSelectColor,
} from "../../core/map/map.constants";
import { MapStateService } from "../map-state.service";
import {
    BehaviorSubject,
    forkJoin,
    ReplaySubject,
    Subject,
    Subscription,
} from "rxjs";
import { MapState } from "../../model/map/map-state";
import { MapStateActionEnum } from "../../model/map/map-state-action.enum";
import { CircleDrawing } from "../../model/map/drawing/circle-drawing";
import { RectangleDrawing } from "../../model/map/drawing/rectangle-drawing";
import {
    AgmInfoWindow,
    Circle,
    DataMouseEvent,
    LatLngBoundsLiteral,
    LatLngLiteral,
    MapTypeStyle,
    MouseEvent,
} from "@agm/core";
import {
    MapDetails,
    MapInfoBoxComponent,
} from "./info-box/map-info-box.component";
import { DatapointsPageStateService } from "../../dataset/datapoints/datapoints-page-state.service";
import { MapDrawType } from "../../model/map/map-draw-type";
import { DatapointsFilterService } from "../../dataset/datapoints/datapoints-filter.service";
import { MapViewType } from "../../model/map/map-view-type";
import { MapThematicOverlayService } from "../map-thematic-overlay.service";
import { debounceTime, skip, throttleTime } from "rxjs/operators";
import { PolygonPathEvent } from "@agm/core/directives/polygon";
import { MapStateForImageOverlays } from "../../model/map/map-state-for-image-overlays";
import { Point } from "../../model/geometry/point";
import { MapLocation } from "../../model/map/map-location";
import { Location as GMALocation } from "@angular-material-extensions/google-maps-autocomplete/lib/interfaces/location.interface";
import { MapInteractionMode } from "../../dataset/datapoints/map-interaction-mode";
import { TessadataService } from "../../data-access-layer/tessadata.service";
import { MapInteractionStatus } from "../../dataset/datapoints/map-interaction-status";
import { ClusteringService } from "../../dataset/clustering/clustering.service";
import { Cluster } from "../../dataset/clustering/cluster";
import { ClusteringRequest } from "../../dataset/clustering/clustering-request";
import { DatasetField } from "../../model/dataset/field/dataset-field";
import { Observable } from "rxjs/internal/Observable";
import { GeocodingService } from "../../shared/services/geo-coder.service";
import { GeographicalRegion } from "../../model/geographical-region/geographical-region";
import AerisWeather from "@aerisweather/javascript-sdk";
import { AerisType } from "../../core/aeris/AerisType";
import { ImageOverlay } from "../../model/overlay/external/image-overlay";
import { environment } from "../../../environments/environment";
import { AerisService } from "../../data-access-layer/aeris.service";
import { MapShape } from "../../model/map/map-shape";
import { AerisDescriptions } from "../../core/aeris/AerisDescriptions";
import {
    FetchLimitParam,
    numberOfAerisRequestsWithinShapes,
} from "../../core/aeris/AerisConsts";
import { ActivatedRoute } from "@angular/router";
import { ObjAerisType } from "../../core/aeris/AerisApiData";
import { ShapeDebouncer } from "./shape-debouncer";
import { PolygonDrawing } from "../../model/map/drawing/polygon-drawing";
import { NotifService } from "../../core/notification/notif.service";
import { PolylineDrawing } from "../../model/map/drawing/polyline-drawing";
import {
    Crisis24Alert,
    Crisis24Circle,
} from "src/app/model/datapoint/crisis24-alert";
import { isUndefined } from "src/app/core/utils/util-master";
import { LocationProfile } from "src/app/model/datapoint/location-profile/location-profile";
//import { TensorflightStatus } from "src/app/dataset/datapoints/tensorflight-keys";
import { OpenWeather } from "src/app/model/datapoint/open-weather";
import { DatapointsService } from "src/app/data-access-layer/datapoints/datapoints.service";
import { MapIcons } from "./map-icons";
import { customMapStyles } from "./map-style";
import { Aeris } from "src/app/core/utils/aeris";
import { DataPointsServiceState } from "src/app/shared/services/datapoints-service-state";
import { HeatmapResponse } from "src/app/dataset/clustering/heatmap-response";
import { AccumulationsService } from "src/app/shared/services/accumulations.service";
import { DecimalPipe } from "@angular/common";
import { LayerExposedService } from "src/app/account/layer-exposed/services/layer-exposed.service";
import { FormBuilder, FormControl, UntypedFormGroup } from "@angular/forms";
import { AccountService } from "src/app/data-access-layer/account/account.service";
import { Account } from "src/app/model/account/account";
import { GroupService } from "src/app/data-access-layer/groups/group.service";
import { DatapointsAggregateService } from "src/app/data-access-layer/datapoints/datapoints-aggregate.service";
import { UserStateService } from "src/app/auth/user-state-service";
import { DatasetFieldScope } from "src/app/model/dataset/dataset-field-scope";
import { SeeInfoSearchBarStateService } from "src/app/dataset/datapoints/see-info-search-bar-state.service";

declare var google: any;

@Component({
    selector: "map-map",
    templateUrl: "./map.component.html",
    styleUrls: ["./map.component.scss"],
})
export class MapComponent implements OnInit, OnDestroy {
    @Input() isThematicMapEnabled: boolean;
    @Output() mapReady = new ReplaySubject(1);
    @Output() DetailsOpen = new EventEmitter<MapDetails>();
    @Output() onCustomLocationDetailsSelected = new EventEmitter<Point>();
    @Input() geoRegion: GeographicalRegion;
 //   @Input() externalTensorFlightData: any;

    activeMarker: Point;
    markerAnimation: string;
    activeMarkerEditMode = false;
    markerIsInitialized: boolean;

    mapType: { type: string; iconSource: string };

    mapSettings = defaultMapSettings;
    drawingOptions = drawingOptions;
    shapeMeasurementColor = shapeMeasurementColor;
    shapeSelectColor = shapeSelectColor;

    clusters: Cluster[];
    clusterSettings: ClusteringRequest;
    clusteringField: DatasetField;

    polygons: { measure: PolygonDrawing[]; select: PolygonDrawing[] };
    polylines: { measure: PolylineDrawing[] };
    circles: { measure: CircleDrawing[]; select: CircleDrawing[] };
    rectangles: { measure: RectangleDrawing[]; select: RectangleDrawing[] };

    isMeasurementDrawing: boolean;
    showPlayButton: boolean;
    isAnimating: boolean;
    interactiveLayers: string[] = [];
    shapeUpdateDebouncer: ReplaySubject<ShapeDebouncer>;
    mapStatus: MapInteractionStatus;
    interactiveOverlayShapes: GeojsonMapItem[];
    // externalDatasetShapeStyle = {fillColor: '#ff0000', strokeWeight: 2, strokeColor: '#444', fillOpacity: 0.5};
    searchResultMarker$: BehaviorSubject<MapLocation> =
        this.mapStateService.activeSearchResultMarker$;
    currentPinAddress: Observable<string>;
    regionsShapes: GeojsonMapItem[];
    crises24CircleObjects: Circle[] = [];
    private aerisMap;
    private mapElement: google.maps.Map;
    private drawingManager: google.maps.drawing.DrawingManager;
    private overlayCompleteListener: google.maps.MapsEventListener;
    private readonly subscription: Subscription = new Subscription();

    destroy$: Subject<void> = new Subject();
  //  tensorFlightPolygonData: { paths: LatLngLiteral[] };
    clickedCoords: LatLngLiteral;
    currentMapBounds: LatLngBoundsLiteral | null = null;
    previousMapBounds: LatLngBoundsLiteral | null = null;
    currentZoom: number;
    preservedZoom: number;
  //  isTensorflightFirstTimeFetched: any = true;
    areBoundsChanged: any = false;
    openWeatherInfoWindow: google.maps.InfoWindow;
    openWeatherAlertsObjects: Map<string, any> = new Map<string, any>();
    openWeatherpolygons: {
        polygon: google.maps.Polygon;
        params: {
            geometryId: string;
            event_name: String;
            start_date: String;
            end_date: String;
        };
    }[] = [];
    mapOption: { type: string; iconSource: string }[];

    heatMap: google.maps.visualization.HeatmapLayer;

    @Input("activeMarker")
    set setActiveMarker(point: Point) {
        this.activeMarker = point;
    }

    @Input("activeMarkerEditMode")
    set setActiveMarkerEditMode(active: boolean) {
        this.activeMarkerEditMode = active;
        if (active) {
            this.markerAnimation = "BOUNCE";
        } else {
            this.markerAnimation = undefined;
        }
    }

    address: any;
    locationProfile = new LocationProfile();
    aerisCredentials: {
        aerisClient: string;
        aerisSecret: string;
    };

    get customMapStyles() {
        return customMapStyles;
    }
    showDynamicIndicatorPopup = false;
    dynamicIndicatorLayerExposureData: any;
    showDynamicIndicatorSettings = false;
    createDropdownForm: UntypedFormGroup;
    dynamicDropDownList: any;
    defaultBottom = 65;
    defaultRight = 10;
    iconPosition = { x: null, y: null };
    isDragging = false;
    dragOffset = { x: 0, y: 0 };
    dragMoved = false;
    popupPosition = { x: null, y: null };
    firstPopupPosition = { x: null, y: null };
    secondPopupPosition = { x: null, y: null };
    popupWidth = 300;
    dynamicIndicatorValues: any[] = [];
    accountDetails: Account;
    pendingRequests: Set<number> = new Set();
    filterChanged = false;
    accountId: any;
    showSearchInfo = false;
    constructor(
        private readonly mapStateService: MapStateService,
        private readonly datapointsPageStateService: DatapointsPageStateService,
        private readonly datapointsFilterService: DatapointsFilterService,
        private readonly mapThematicOverlayService: MapThematicOverlayService,
        private readonly clusteringService: ClusteringService,
        private readonly tessadataService: TessadataService,
        private geocodingService: GeocodingService,
        private readonly cd: ChangeDetectorRef,
        private readonly aerisService: AerisService,
        private readonly route: ActivatedRoute,
        public readonly notifService: NotifService,
        public readonly datapointService: DatapointsService,
        public dataPointsServiceState: DataPointsServiceState,
        public readonly accumulationsSerivce: AccumulationsService,
        private decimalPipe: DecimalPipe,
        private layerExposeService: LayerExposedService,
        private readonly accountService: AccountService,
        private readonly groupService: GroupService,
        private readonly datapointsAggregateService: DatapointsAggregateService,
        private fb: FormBuilder,
        readonly userStateService: UserStateService,
        private renderer: Renderer2,
        private popupService: SeeInfoSearchBarStateService
    ) {
        this.mapElement = null;
        this.polygons = { measure: [], select: [] };
        this.polylines = { measure: [] };
        this.circles = { measure: [], select: [] };
        this.rectangles = { measure: [], select: [] };
        this.drawingManager = new google.maps.drawing.DrawingManager();
        this.isMeasurementDrawing = true;
        this.mapType = {
            type: defaultMapSettings.mapType,
            iconSource: MapIcons[defaultMapSettings.mapType],
        };
        this.isThematicMapEnabled = false;
        this.shapeUpdateDebouncer = new ReplaySubject<ShapeDebouncer>(1);

        this.currentPinAddress = this.mapStateService.getCurrentPinAddress();
        this.regionsShapes = [];
        this.isAnimating = false;
        this.mapOption = this.getMapViewType();
        this.mapType = this.mapOption.find(
            (option) => option.type === defaultMapSettings.mapType
        );
        this.accountId = +this.route.snapshot.paramMap.get("accountId")
    }

    ngOnInit() {
        this.renderer.addClass(document.body, 'custom-recaptcha-page');
        this.aerisService.getAerisCredentials().subscribe((value: any) => {
            this.aerisCredentials = Aeris.decodeAerisCredentials(
                value.aerisClient,
                value.aerisSecret
            );
        });

        this.subscription.add(
            this.datapointsPageStateService
                .onMapInteractionChange()
                .subscribe((newStatus) => {
                    this.mapStatus = newStatus;
                    this.interactiveOverlayShapes = [];
                })
        );
        this.subscription.add(
            this.accumulationsSerivce
                .onHeatmapActivated()
                .subscribe((response) => {
                    if (!response) {
                        this.heatMap?.setMap(null);
                    } else {
                        this.prepareHeatMap(response);
                    }
                })
        );
        this.subscription.add(
            this.clusteringService
                .onClusteringActivated()
                .subscribe((clusteringResponse) => {
                    this.clusterSettings = this.clusteringService.getSettings();
                    this.clusteringField = this.datapointsPageStateService
                        .getActiveDatasetFields()
                        .get(this.clusterSettings.fieldId);
                    this.clusters = clusteringResponse.clusters;
                })
        );
        this.subscription.add(
            this.clusteringService
                .onClusterAnimationNeeded()
                .subscribe((clusterIndex) => {
                    // this.clusters.forEach(cluster => cluster.showInfo = false);
                    this.onClusterClick(this.clusters[clusterIndex]);
                })
        );
        this.subscription.add(
            this.clusteringService.onClusteringDisabled().subscribe((v) => {
                this.clusterSettings = null;
                this.clusters = [];
            })
        );
        this.subscription.add(
            this.mapStateService
                .getMarkerIsInitializedSubject()
                .subscribe((value) => {
                    this.markerIsInitialized = value;
                })
        );

        // this.subscription.add(
        //     this.dataPointsServiceState
        //         .getTensorflightAddressProfilePolygon()
        //         .subscribe((value: string) => {
        //             const parsedValue = value ? JSON.parse(value) : null;
        //             this.createAddressProfileTensorflightPolygon(parsedValue);
        //         })
        // );
        
        
        this.subscription.add(
            this.popupService.isPopupVisible$.subscribe(
                visible => this.showSearchInfo = visible
            )
        );
    }

    onClusterClick(cluster: Cluster) {
        cluster.showInfo = true;
        this.clusteringService.clusterSelected(cluster);
    }

    get DatapointsMapMode() {
        return MapInteractionMode;
    }

    get MapDrawType() {
        return MapDrawType;
    }

    get MapShape() {
        return MapShape;
    }

    private initMarker() {
        const center = this.mapElement.getCenter();
        this.mapStateService.activeSearchResultMarker$.next({
            latitude: center.lat(),
            longitude: center.lng(),
        });
        this.mapStateService.emitMarkerIsInitialized(true);
    }

    onInteractiveMapAdded(overlay: ImageOverlay, opacity: number): void {
        let coordinates;
        if (
            !this.geocodingService.currentPinAddress &&
            !this.markerIsInitialized
        ) {
            coordinates = this.mapElement.getCenter();
        } else {
            coordinates = {
                lng: () => this.searchResultMarker$.value.longitude,
                lat: () => this.searchResultMarker$.value.latitude,
            };
        }

        if (!this.aerisMap) {
            const aeris = new AerisWeather(
                this.aerisCredentials.aerisClient,
                this.aerisCredentials.aerisSecret
            );
            aeris.views().then((views) => {
                this.aerisMap = new views.InteractiveMap(this.mapElement, {
                    layers: [
                        {
                            layer: overlay.id,
                            options: {
                                style: {
                                    opacity,
                                },
                            },
                        },
                    ],
                    timeline: {
                        from: -3 * 3600,
                        to: 0,
                    },
                });

                // must wait for map to be ready before trying to update its view
                this.aerisMap.on("ready", () => {
                    this.showPlayButton = true;
                    this.aerisMap.setCenter({
                        lat: coordinates.lat(),
                        lon: coordinates.lng(),
                    });
                    this.aerisMap.setZoom(this.mapElement.getZoom());
                    this.interactiveLayers.push(overlay.id);
                });

                this.aerisMap.on("timeline:play", () => {
                    this.isAnimating = true;
                    this.cd.detectChanges();
                });

                this.aerisMap.on("timeline:stop", () => {
                    this.isAnimating = false;
                    this.cd.detectChanges();
                });

                // account info is needed to request alert-related data from the API for the alerts legend
                const account = aeris.account();

                const legend = new views.Legend("#aerisLegend", {
                    size: {
                        width: 340,
                    },
                    styles: {
                        label: {
                            color: "#ffffff",
                        },
                    },
                });

                // `map` is an instance of InteractiveMap
                // add and remove legends when layers are added and/or removed from the map
                this.aerisMap
                    .on("layer:add", (e) => {
                        const { layer, id } = e.data || {
                            layer: null,
                            id: null,
                        };
                        const keys = layer || id;
                        if (keys) {
                            // some keys contain multiple layers, so add a legend for each as needed
                            const layers = keys
                                .replace(/\:[^,]+/g, "")
                                .split(",");
                            layers.forEach((code) => {
                                legend.add(code, {
                                    account: account,
                                });

                                // the "alerts" legend requires the current map bounds
                                if (
                                    code === "alerts" ||
                                    /^alerts-/.test(code)
                                ) {
                                    setTimeout(() => {
                                        legend.update({
                                            account: account,
                                            within: {
                                                bounds: this.aerisMap.getBounds(),
                                            },
                                        });
                                    }, 500);
                                }
                            });
                        }
                    })
                    .on("layer:remove source:remove", (e) => {
                        const { layer, id } = e.data || {
                            layer: null,
                            id: null,
                        };
                        const keys = layer || id;
                        if (keys) {
                            // some keys contain multiple layers, so remove the legend for each as needed
                            const layers = keys
                                .replace(/\:[^,]+/g, "")
                                .split(",");
                            layers.forEach((code) => {
                                legend.remove(code);
                            });
                        }
                    })
                    .on("change:bounds", (e) => {
                        if (this.aerisMap) {
                            const opts = { account: account, within: {} };

                            // if active layers contains `alerts`, we need to pass the maps current bounds, size
                            // and zoom to be used to request a filtered version of the advisories legend just
                            // for the map region
                            if (this.aerisMap.getBounds()) {
                                opts.within = {
                                    bounds: this.aerisMap.getBounds(),
                                };
                            }
                            legend.update(opts);
                        }
                    });
            });
        } else {
            this.aerisMap.addLayer(overlay.id);
            this.showPlayButton = true;
            this.interactiveLayers.push(overlay.id);
        }
    }

    toggleInteractiveMapAnimation() {
        if (this.aerisMap.timeline.isAnimating()) {
            this.aerisMap.timeline.stop();
        } else {
            this.aerisMap.timeline.play();
        }
    }

    onInteractiveMapRemoved(overlay: ImageOverlay): void {
        this.aerisMap.removeLayer(overlay.id);
        const elementToRemove = this.interactiveLayers.findIndex(
            (id) => id === overlay.id
        );
        this.interactiveLayers.splice(elementToRemove, 1);

        if (this.interactiveLayers.length === 0) {
            this.aerisMap = null;
            this.showPlayButton = false;
        }
    }

    onMapReady(event: any): void {
        this.mapElement = event;
        this.resetOpenWeatherVariables();
        this.openWeatherInfoWindow = new google.maps.InfoWindow();
        this.clearCrises24CircleObjectsArray();
        this.subscription.add(
            // TODO Investigate if anything else in the component requires unsubscribing.
            this.mapStateService
                .getActiveOverlaysSubject()
                .subscribe((state) => {
                    this.mapStateReducer(state);
                })
        );
        this.subscription.add(
            this.mapStateService
                .getActiveShapesSubject()
                .subscribe((newShape) => {
                    this.isMeasurementDrawing = false;
                    this.onShapeOverlayComplete(
                        newShape.overlayType,
                        newShape.overlay,
                        false
                    );
                })
        );
        this.mapStateService
            .getActiveCrisis24CirclesSubject()
            .subscribe((response) => {
                this.clearCrises24CircleObjectsArray();
                response.forEach((element) => {
                    this.drawCircle(element);
                });
            });
        this.mapStateService
            .getCrisis24CirclesRemoveFlag()
            .subscribe((response) => {
                if (response) {
                    this.activeMarker = null;
                    this.clearCrises24CircleObjectsArray();
                }
            });
        this.mapStateService
            .getOpenWeatherRemovePolygonFlag()
            .subscribe((response) => {
                if (response) {
                    this.resetOpenWeatherVariables();
                }
            });
        this.subscription.add(
            this.mapStateService
                .getActiveImageOverlaysSubject()
                .subscribe((mapState) => {
                    this.handleImageOverlaysMapChanges(mapState);
                })
        );
        this.subscription.add(
            this.shapeUpdateDebouncer
                .pipe(
                    throttleTime(300, undefined, {
                        leading: true,
                        trailing: true,
                    })
                )
                .subscribe((value) => {
                    this.onGeometriesBoundsChange(
                        value.event,
                        value.index,
                        value.type,
                        value.action
                    );
                })
        );
        this.subscription.add(
            this.mapStateService
                .getActiveGeoRegionSubject()
                .subscribe((geoRegionFiltered) => {
                    this.onApplyGeoRegionsGeometryOnMap(geoRegionFiltered);
                })
        );
        this.subscription.add(
            this.mapStateService
                .getClearFilteredRegionsSubject()
                .subscribe((value) => {
                    this.clearRegionsGeometries(value);
                })
        );
        this.mapReady.next(event);
    }

    onApplyGeoRegionsGeometryOnMap(geoRegionsFiltered) {
        this.regionsShapes = [];
        const color = this.getColorForRegionShape();
        const style = {
            fillColor: color,
            strokeColor: color,
            strokeWeight: 1,
            fillOpacity: 0.3,
            zIndex: -1,
            stylers: null,
        };
        if (geoRegionsFiltered) {
            geoRegionsFiltered.forEach((geoRegionFiltered) => {
                this.regionsShapes.push({
                    geojson: {
                        type: "Feature",
                        geometry: geoRegionFiltered.geometry,
                    },
                    showInfo: false,
                    style: style,
                    regionId: geoRegionFiltered.id,
                });
            });
        }
        this.datapointsFilterService.applyFilterForGeoRegions(
            this.regionsShapes
        );
    }

    getColorForRegionShape() {
        const color = "#104466";
        return color;
    }

    clearRegionsGeometries(value) {
        if (value) {
            this.regionsShapes = [];
            this.datapointsFilterService.applyFilterForGeoRegions(
                this.regionsShapes
            );
        }
    }

    private mapStateReducer(state: MapState) {
        switch (state.action) {
            case MapStateActionEnum.LOAD:
                break;
            case MapStateActionEnum.REDRAW_ALL:
                this.onStateRedrawAllAction(state);
                break;
            case MapStateActionEnum.UPDATE_OVERLAY:
                this.onStateUpdateOverlayAction(state);
                break;
            case MapStateActionEnum.INSERT_OVERLAY:
                this.onStateInsertOverlayAction(state);
                break;
            case MapStateActionEnum.REMOVE_OVERLAY:
                this.onStateRemoveOverlay(state.datasetID);
                break;
            case MapStateActionEnum.SHAPES:
                this.addDrawingManager(state);
                break;
            case MapStateActionEnum.CLEAR_SHAPES:
                if (state.options.drawingType === MapDrawType.MEASURE) {
                    this.clearMeasureShapes();
                    this.clearDrawingManager();
                }
                if (state.options.drawingType === MapDrawType.FILTER) {
                    this.clearDrawingManager();
                    let haveActiveGeometries =
                        this.circles.select.length ||
                        this.rectangles.select.length ||
                        this.polygons.select.length;
                    if (haveActiveGeometries) {
                        this.clearSelectShapes();
                    }
                }
        }
    }

    private onStateInsertOverlayAction(state: MapState): void {
        let datasetID = state.datasetID;
        let dataset = this.datapointsPageStateService.getDataset(datasetID);
        let existingIndex =
            this.datapointsPageStateService.activeDatasetsOnMap.findIndex(
                (ds) => ds.id === datasetID
            );
        if (existingIndex >= 0) {
            return;
        }

        let locationDatasetId = this.route.snapshot.paramMap.get("datasetId") || "";
        if(datasetID !== locationDatasetId) {
            this.mapElement.overlayMapTypes.insertAt(0, state.map);
            this.datapointsPageStateService.activeDatasetsOnMap.unshift(dataset);
        } else {
            this.mapElement.overlayMapTypes.push(state.map);
            this.datapointsPageStateService.activeDatasetsOnMap.push(dataset);
        }
    }

    private onStateRemoveOverlay(datasetID: string): void {
        let indexOfOverlay = this.mapElement.overlayMapTypes
            .getArray()
            .findIndex((map) => map.name === datasetID);
        this.mapElement.overlayMapTypes.removeAt(indexOfOverlay);
        this.datapointsPageStateService.activeDatasetsOnMap.splice(
            indexOfOverlay,
            1
        );
    }

    private onStateRedrawAllAction(state: MapState): void {
        this.mapElement.overlayMapTypes.clear();
    }

    /**
     * If the overlay is not active already, it will be added instead of update
     */
    private onStateUpdateOverlayAction(state: MapState): void {
        let datasetID = state.datasetID;
        let existingLayerIndex = this.mapElement.overlayMapTypes
            .getArray()
            .findIndex((map) => map.name === datasetID);
        if (existingLayerIndex >= 0) {
            this.mapElement.overlayMapTypes.setAt(
                existingLayerIndex,
                state.map
            );
        } else {
            this.onStateInsertOverlayAction(state);
        }
    }

    onGeojsonClick(item: GeojsonMapItem, event: DataMouseEvent) {
        item.showInfo = true;
        item.clickedLocation = {
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
        };
    }

    onMapClick(event: MouseEvent, infoWindow: MapInfoBoxComponent) {
        console.log("ON CLICK");
        let currentMode =
            this.datapointsPageStateService.getMapInteractionStatus();
        if (
            currentMode.mode === MapInteractionMode.INTERACTION_DATASET_ACTIVE
        ) {
            let accountId = +this.route.snapshot.paramMap.get("accountId");
            this.tessadataService
                .getZoneGeometry(
                    event.coords.lng,
                    event.coords.lat,
                    currentMode.externalDatasetId,
                    accountId
                )
                .subscribe((geojson) => {
                    this.interactiveOverlayShapes.forEach(
                        (item) => (item.showInfo = false)
                    );
                    if (geojson) {
                        const color = this.getPolygonColorBasedOnZone(
                            geojson.properties.fld_zone
                        );
                        const style = {
                            fillColor: color,
                            strokeColor: color,
                            strokeWeight: 2,
                            fillOpacity: 0.5,
                            zIndex: -1,
                            stylers: null,
                        };
                        this.interactiveOverlayShapes.push({
                            geojson: geojson,
                            showInfo: true,
                            clickedLocation: event.coords,
                            style: style,
                        });
                        // this.externalDatasetShapeStyle.fillColor = color;
                        // this.externalDatasetShapeStyle.strokeColor = color;
                    }
                });
            return;
        }
        if (!this.isThematicMapEnabled) {
            infoWindow.open(this.mapElement.getZoom(), event.coords);
            this.clickedCoords = event.coords;
        } else {
            this.mapThematicOverlayService.onMapClick(
                this.mapElement.getZoom(),
                event.coords
            );
            this.mapThematicOverlayService.setMapCoords(
                this.mapElement.getZoom(),
                event.coords
            );
        }

        if (this.aerisMap) {
            this.aerisMap.setCenter({
                lat: event.coords.lat,
                lon: event.coords.lng,
            });
            this.aerisMap.setZoom(this.mapElement.getZoom());
        }
    }

    getPolygonColorBasedOnZone(floodZone: string) {
        let lightBlue = "#83b8e9";
        let blue = "#096099";
        let red = "#ca0202";
        let grey = "#aaaaaa";
        // if (floodZone.startsWith('X') || floodZone.startsWith('B') || floodZone.startsWith('C')) { return lightBlue; }
        // if (floodZone.startsWith('A')) { return blue; }
        // if (floodZone.startsWith('V')) { return red; }
        return lightBlue;
    }

    onMapRightClick(event: MouseEvent): void {
        if (this.isThematicMapEnabled) {
            this.mapThematicOverlayService.onMapRightClick();
        }
        switch (this.mapStatus.mode) {
            case MapInteractionMode.INTERACTION_DATASET_ACTIVE:
                this.interactiveOverlayShapes = [];
                break;
        }
    }

    clearMeasureShapes() {
        this.polygons.measure = [];
        this.polylines.measure = [];
        this.circles.measure = [];
        this.rectangles.measure = [];
    }

    clearSelectShapes() {
        this.polygons.select = [];
        this.circles.select = [];
        this.rectangles.select = [];
        this.onGeometriesChange();
    }

    // DRAWING
    private addDrawingManager(state: MapState): void {
        if (!state.options.drawingMode) {
            return;
        }
        let color;
        if (state.options.drawingType === MapDrawType.FILTER) {
            color = shapeSelectColor;
            this.isMeasurementDrawing = false;
        } else {
            color = shapeMeasurementColor;
            this.isMeasurementDrawing = true;
        }
        let drawingMode = this.drawingManager.getDrawingMode();
        if (drawingMode === null) {
            this.drawingManager.setDrawingMode(state.options.drawingMode);
        } else {
            this.drawingManager.setDrawingMode(null);
        }

        let config = {
            drawingControl: false,
            polygonOptions: {
                ...drawingOptions.polygon,
                fillColor: color,
                strokeColor: color,
            },
            polylineOptions: drawingOptions.polyline,
            circleOptions: {
                ...drawingOptions.circle,
                fillColor: color,
                strokeColor: color,
            },
            rectangleOptions: {
                ...drawingOptions.rectangle,
                fillColor: color,
                strokeColor: color,
            },
            drawingMode: state.options.drawingMode,
        };
        this.drawingManager = new google.maps.drawing.DrawingManager(config);
        this.drawingManager.setMap(this.mapElement);
        this.overlayCompleteListener = google.maps.event.addListener(
            this.drawingManager,
            "overlaycomplete",
            (overlay) => this.onShapeDrawComplete(overlay)
        );
    }

    private clearDrawingManager(): void {
        google.maps.event.removeListener(this.overlayCompleteListener);
        this.drawingManager.setMap(null);
        this.drawingManager.setDrawingMode(null);
    }

    private onShapeDrawComplete(
        event: google.maps.drawing.OverlayCompleteEvent
    ): void {
        let overlay = event.overlay;
        this.onShapeOverlayComplete(event.type, overlay, true);
        overlay.setMap(null);
        this.clearDrawingManager();
        this.mapStateService.setDrawingMode(null, null);
    }

    onShapeOverlayComplete(
        overlayType: google.maps.drawing.OverlayType,
        overlay,
        updateFilter: boolean
    ) {
        switch (overlayType) {
            case google.maps.drawing.OverlayType.CIRCLE:
                this.onCircleComplete(
                    overlay as google.maps.Circle,
                    updateFilter
                );
                break;
            case google.maps.drawing.OverlayType.POLYGON:
                this.onPolygonComplete(
                    overlay as google.maps.Polygon,
                    updateFilter
                );
                break;
            case google.maps.drawing.OverlayType.POLYLINE:
                this.onPolylineComplete(overlay as google.maps.Polyline);
                break;
            case google.maps.drawing.OverlayType.RECTANGLE:
                this.onRectangleComplete(
                    overlay as google.maps.Rectangle,
                    updateFilter
                );
                break;
        }
    }

    onGeometriesBoundsChange(
        event: any,
        index: number,
        type: string,
        action: string
    ): void {
        this.cd.detectChanges();
        if (type === MapShape.CIRCLE) {
            if (action === this.MapDrawType.FILTER) {
                let circle = this.circles.select[index];
                // reset data earlier for markers to not remain to much on map
                circle.circleData = {};
                circle.isReceivingAllData = false;
                //
                circle.latitude = event.latitude;
                circle.longitude = event.longitude;
                circle.radius = event.radius;
                this.fetchAerisApiDataWithinCircle(circle);
            } else {
                let circle = this.circles.measure[index];
                circle.circleData = {};
                circle.isReceivingAllData = false;
                circle.latitude = event.latitude;
                circle.longitude = event.longitude;
                circle.radius = event.radius;
                this.fetchAerisApiDataWithinCircle(circle);
            }
        } else if (type === MapShape.POLYGON) {
            if (action === this.MapDrawType.FILTER) {
                let polygon = this.polygons.select[index];
                this.fetchAerisApiDataWithinPolygon(polygon);
            } else {
                let polygon = this.polygons.measure[index];
                this.fetchAerisApiDataWithinPolygon(polygon);
            }
        } else {
            if (action === this.MapDrawType.FILTER) {
                let rectangle = this.rectangles.select[index];
                rectangle.rectangleData = {};
                rectangle.isReceivingAllData = false;
                rectangle.north = event.north;
                rectangle.east = event.east;
                rectangle.south = event.south;
                rectangle.west = event.west;
                this.fetchAerisApiDataWithinRectangle(rectangle);
            } else {
                let rectangle = this.rectangles.measure[index];
                rectangle.rectangleData = {};
                rectangle.isReceivingAllData = false;
                rectangle.north = event.north;
                rectangle.south = event.south;
                rectangle.east = event.east;
                rectangle.west = event.west;
                this.fetchAerisApiDataWithinRectangle(rectangle);
            }
        }
        if (action === MapDrawType.FILTER) {
            this.onGeometriesChange();
        }
    }

    private onGeometriesChange(): void {
        this.datapointsFilterService.updateShapes({
            rectangles: this.rectangles.select,
            circles: this.circles.select,
            polygons: this.polygons.select,
        });
    }

    onPolygonComplete(overlay: google.maps.Polygon, updateFilter: boolean) {
        const polygonObj: PolygonDrawing = {
            polygon: overlay,
        };
        const list = this.isMeasurementDrawing
            ? this.polygons.measure
            : this.polygons.select;
        list.push(polygonObj);
        if (!this.isMeasurementDrawing && updateFilter) {
            this.onGeometriesChange();
            this.fetchAerisApiDataWithinPolygon(polygonObj);
        } else {
            this.fetchAerisApiDataWithinPolygon(polygonObj);
        }
    }

    fetchAerisApiDataWithinPolygon(polygon: PolygonDrawing) {
        polygon.isReceivingAllData = false;
        let coordinates = polygon.polygon
            .getPath()
            .getArray()
            .map((p) => [p.lat(), p.lng()]);
        let mergedCoords = [].concat.apply([], coordinates);
        let coordsApiParams = mergedCoords.toString();
        forkJoin([
            this.aerisService.fetchAerisDataWithinPolygon(
                AerisType.AIR_QUALITY,
                this.aerisCredentials.aerisClient,
                this.aerisCredentials.aerisSecret,
                coordsApiParams,
                FetchLimitParam.limit
            ),
            this.aerisService.fetchAerisDataWithinPolygon(
                AerisType.EARTHQUAKES,
                this.aerisCredentials.aerisClient,
                this.aerisCredentials.aerisSecret,
                coordsApiParams,
                FetchLimitParam.limit
            ),
            this.aerisService.fetchAerisDataWithinPolygon(
                AerisType.FIRES,
                this.aerisCredentials.aerisClient,
                this.aerisCredentials.aerisSecret,
                coordsApiParams,
                FetchLimitParam.limit
            ),
            this.aerisService.fetchAerisDataWithinPolygon(
                AerisType.CLIMATE_NORMALS,
                this.aerisCredentials.aerisClient,
                this.aerisCredentials.aerisSecret,
                coordsApiParams,
                FetchLimitParam.limit
            ),
            this.aerisService.fetchAerisDataWithinPolygon(
                AerisType.OBSERVATIONS,
                this.aerisCredentials.aerisClient,
                this.aerisCredentials.aerisSecret,
                coordsApiParams,
                FetchLimitParam.limit
            ),
            this.aerisService.fetchAerisDataWithinPolygon(
                AerisType.RIVERS,
                this.aerisCredentials.aerisClient,
                this.aerisCredentials.aerisSecret,
                coordsApiParams,
                FetchLimitParam.limit
            ),
            this.aerisService.fetchAerisDataWithinPolygon(
                AerisType.RIVERS_GAUGES,
                this.aerisCredentials.aerisClient,
                this.aerisCredentials.aerisSecret,
                coordsApiParams,
                FetchLimitParam.limit
            ),
            this.aerisService.fetchAerisDataWithinPolygon(
                AerisType.STORMCELLS,
                this.aerisCredentials.aerisClient,
                this.aerisCredentials.aerisSecret,
                coordsApiParams,
                FetchLimitParam.limit
            ),
            this.aerisService.fetchAerisDataWithinPolygon(
                AerisType.STORM_REPORTS,
                this.aerisCredentials.aerisClient,
                this.aerisCredentials.aerisSecret,
                coordsApiParams,
                FetchLimitParam.limit
            ),
            this.aerisService.fetchAerisDataWithinPolygon(
                AerisType.TIDES,
                this.aerisCredentials.aerisClient,
                this.aerisCredentials.aerisSecret,
                coordsApiParams,
                FetchLimitParam.limit
            ),
            this.aerisService.fetchAerisDataWithinPolygon(
                AerisType.TROPICALCYCLONES,
                this.aerisCredentials.aerisClient,
                this.aerisCredentials.aerisSecret,
                coordsApiParams,
                FetchLimitParam.tropicalcyclonesLimit
            ),
            this.aerisService.fetchAerisDataWithinPolygon(
                AerisType.ALERTS_SUMMARY,
                this.aerisCredentials.aerisClient,
                this.aerisCredentials.aerisSecret,
                coordsApiParams,
                FetchLimitParam.limit
            ),
            //,this.aerisService.fetchAerisDataWithinPolygon(AerisType.LIGHTNING_SUMMARY, coordsApiParams, FetchLimitParam.limit)
        ]).subscribe(
            ([
                airqualityObs,
                earthquakesObs,
                firesObs,
                normalsObs,
                observationsObs,
                riversObs,
                riversGaugesObs,
                stormcellsObs,
                stormReportsObs,
                tidesObs,
                tropicalcyclonesObs,
                alertsObs,
                lightningObs,
            ]) => {
                polygon.polygonData = {};
                polygon.polygonData = Object.assign(
                    this.prepareAirQualityResponse(airqualityObs),
                    this.prepareEarthquakesResponse(earthquakesObs),
                    this.prepareFiresResponse(firesObs),
                    this.prepareNormalsResponse(normalsObs),
                    this.prepareObservationsResponse(observationsObs),
                    this.prepareRiversResponse(riversObs),
                    this.prepareRiversGaugesResponse(riversGaugesObs),
                    this.prepareStormcellsResponse(stormcellsObs),
                    this.prepareStormreportsResponse(stormReportsObs),
                    this.prepareTidesResponse(tidesObs),
                    this.prepareTropicalcyclonesResponse(tropicalcyclonesObs),
                    this.prepareAlertsResponse(alertsObs),
                    this.prepareLightningResponse(lightningObs)
                );
            },
            (error) => {
                polygon.isReceivingAllData = true;
                polygon.emptyResponsesCounter =
                    numberOfAerisRequestsWithinShapes;
                this.notifService.error(
                    "We can not provide Risk Info for this type of polygon"
                );
                console.log(error);
            },
            () => {
                polygon.emptyResponsesCounter = 0;
                polygon.isReceivingAllData = true;
                polygon.emptyResponsesCounter = this.aerisResponsesCounter(
                    polygon.polygonData
                );
            }
        );
    }

    onCircleComplete(overlay: google.maps.Circle, updateFilter: boolean): void {
        const center = overlay.getCenter();
        const radius = overlay.getRadius();
        const list = this.isMeasurementDrawing
            ? this.circles.measure
            : this.circles.select;
        const circle: CircleDrawing = {
            radius: radius,
            latitude: center.lat(),
            longitude: center.lng(),
        };
        list.push(circle);
        if (!this.isMeasurementDrawing && updateFilter) {
            this.onGeometriesChange();
            this.fetchAerisApiDataWithinCircle(circle);
        } else {
            this.fetchAerisApiDataWithinCircle(circle);
        }
    }

    circleDragEnd(info: AgmInfoWindow) {
        setTimeout(() => {
            info.open();
        }, 500);
    }

    fetchAerisApiDataWithinCircle(circle: CircleDrawing) {
        let radiusInMi = this.mToMi(circle.radius);
        circle.isReceivingAllData = false;
        forkJoin([
            this.aerisService.fetchAerisDataWithinCircle(
                AerisType.AIR_QUALITY,
                this.aerisCredentials.aerisClient,
                this.aerisCredentials.aerisSecret,
                circle.latitude,
                circle.longitude,
                radiusInMi,
                FetchLimitParam.limit
            ),
            this.aerisService.fetchAerisDataWithinCircle(
                AerisType.EARTHQUAKES,
                this.aerisCredentials.aerisClient,
                this.aerisCredentials.aerisSecret,
                circle.latitude,
                circle.longitude,
                radiusInMi,
                FetchLimitParam.limit
            ),
            this.aerisService.fetchAerisDataWithinCircle(
                AerisType.FIRES,
                this.aerisCredentials.aerisClient,
                this.aerisCredentials.aerisSecret,
                circle.latitude,
                circle.longitude,
                radiusInMi,
                FetchLimitParam.limit
            ),
            this.aerisService.fetchAerisDataWithinCircle(
                AerisType.CLIMATE_NORMALS,
                this.aerisCredentials.aerisClient,
                this.aerisCredentials.aerisSecret,
                circle.latitude,
                circle.longitude,
                radiusInMi,
                FetchLimitParam.limit
            ),
            this.aerisService.fetchAerisDataWithinCircle(
                AerisType.OBSERVATIONS,
                this.aerisCredentials.aerisClient,
                this.aerisCredentials.aerisSecret,
                circle.latitude,
                circle.longitude,
                radiusInMi,
                FetchLimitParam.limit
            ),
            this.aerisService.fetchAerisDataWithinCircle(
                AerisType.RIVERS,
                this.aerisCredentials.aerisClient,
                this.aerisCredentials.aerisSecret,
                circle.latitude,
                circle.longitude,
                radiusInMi,
                FetchLimitParam.limit
            ),
            this.aerisService.fetchAerisDataWithinCircle(
                AerisType.RIVERS_GAUGES,
                this.aerisCredentials.aerisClient,
                this.aerisCredentials.aerisSecret,
                circle.latitude,
                circle.longitude,
                radiusInMi,
                FetchLimitParam.limit
            ),
            this.aerisService.fetchAerisDataWithinCircle(
                AerisType.STORMCELLS,
                this.aerisCredentials.aerisClient,
                this.aerisCredentials.aerisSecret,
                circle.latitude,
                circle.longitude,
                radiusInMi,
                FetchLimitParam.limit
            ),
            this.aerisService.fetchAerisDataWithinCircle(
                AerisType.STORM_REPORTS,
                this.aerisCredentials.aerisClient,
                this.aerisCredentials.aerisSecret,
                circle.latitude,
                circle.longitude,
                radiusInMi,
                FetchLimitParam.limit
            ),
            this.aerisService.fetchAerisDataWithinCircle(
                AerisType.TIDES,
                this.aerisCredentials.aerisClient,
                this.aerisCredentials.aerisSecret,
                circle.latitude,
                circle.longitude,
                radiusInMi,
                FetchLimitParam.limit
            ),
            this.aerisService.fetchAerisDataWithinCircle(
                AerisType.TROPICALCYCLONES,
                this.aerisCredentials.aerisClient,
                this.aerisCredentials.aerisSecret,
                circle.latitude,
                circle.longitude,
                radiusInMi,
                FetchLimitParam.tropicalcyclonesLimit
            ),
            this.aerisService.fetchAerisDataWithinCircle(
                AerisType.ALERTS_SUMMARY,
                this.aerisCredentials.aerisClient,
                this.aerisCredentials.aerisSecret,
                circle.latitude,
                circle.longitude,
                radiusInMi,
                FetchLimitParam.limit
            ),
            //,this.aerisService.fetchAerisDataWithinCircle(AerisType.LIGHTNING_SUMMARY, circle.latitude, circle.longitude, radiusInMi, FetchLimitParam.limit)
        ]).subscribe(
            ([
                airqualityObs,
                earthquakesObs,
                firesObs,
                normalsObs,
                observationsObs,
                riversObs,
                riversGaugesObs,
                stormcellsObs,
                stormReportsObs,
                tidesObs,
                tropicalcyclonesObs,
                alertsObs,
                lightningObs,
            ]) => {
                circle.circleData = {};
                circle.circleData = Object.assign(
                    this.prepareAirQualityResponse(airqualityObs),
                    this.prepareEarthquakesResponse(earthquakesObs),
                    this.prepareFiresResponse(firesObs),
                    this.prepareNormalsResponse(normalsObs),
                    this.prepareObservationsResponse(observationsObs),
                    this.prepareRiversResponse(riversObs),
                    this.prepareRiversGaugesResponse(riversGaugesObs),
                    this.prepareStormcellsResponse(stormcellsObs),
                    this.prepareStormreportsResponse(stormReportsObs),
                    this.prepareTidesResponse(tidesObs),
                    this.prepareTropicalcyclonesResponse(tropicalcyclonesObs),
                    this.prepareAlertsResponse(alertsObs),
                    this.prepareLightningResponse(lightningObs)
                );
            },
            (error) => {
                circle.isReceivingAllData = true;
                circle.emptyResponsesCounter =
                    numberOfAerisRequestsWithinShapes;
                this.notifService.error(
                    "Something went wrong... We can not provide Risk Info"
                );
                console.log(error);
            },
            () => {
                circle.emptyResponsesCounter = 0;
                circle.isReceivingAllData = true;
                circle.emptyResponsesCounter = this.aerisResponsesCounter(
                    circle.circleData
                );
            }
        );
    }

    aerisResponsesCounter(data: object) {
        let lists = Object.values(data);
        let counter = 0;
        lists.forEach((list) => {
            if (list.length === 0) {
                counter++;
            }
        });
        return counter;
    }

    showCircleInfoByLabel(data: any) {
        data.showData = !data.showData;
    }

    prepareAirQualityResponse(list: any): ObjAerisType {
        // prepare array with unique loc and lng
        for (let i = 0; i < list.response.length; i++) {
            if (list.response[i] !== undefined && list[i + 1] !== undefined) {
                if (list.response[i].loc.lat === list.response[i + 1].loc.lat) {
                    list.splice(i, 1);
                    i--;
                }
            }
        }
        let airqualityList = [];
        list.response.forEach((item) => {
            let propertiesPicked = {};
            let pollutantsList = [];
            if (item.periods) {
                propertiesPicked = (({
                    category,
                    dateTimeISO,
                    dominant,
                    method,
                }) => ({ category, dateTimeISO, dominant, method }))(
                    item.periods[0]
                );
                item.periods[0].pollutants.forEach((pollutant) => {
                    let pollutantPropertiesPicked = (({
                        name,
                        category,
                        valuePPB,
                        valueUGM3,
                    }) => ({ name, category, valuePPB, valueUGM3 }))(pollutant);
                    let prepareDisplayedPollutantsList = Object.entries(
                        pollutantPropertiesPicked
                    );
                    pollutantsList.push(prepareDisplayedPollutantsList);
                });
            }
            let preparedPickedProperties = Object.entries(propertiesPicked);
            let airQualityObj = {
                type: this.AerisType.AIR_QUALITY,
                loc: item.loc,
                properties: preparedPickedProperties,
                traitLabel: "Pollutant",
                traitList: pollutantsList,
                description: this.AerisDescriptions.AIR_QUALITY,
                showData: false,
            };
            airqualityList.push(airQualityObj);
        });
        let airquality: ObjAerisType = {};
        airquality[AerisType.AIR_QUALITY] = airqualityList;
        return airquality;
    }

    prepareEarthquakesResponse(list: any): ObjAerisType {
        let earthquakesList = [];
        list.response.forEach((item: any) => {
            let propertiesPicked = {};
            if (item.report) {
                propertiesPicked = (({
                    region,
                    dateTimeISO,
                    updatedDateTimeISO,
                    type,
                    depthKM,
                    mag,
                }) => ({
                    region,
                    dateTimeISO,
                    updatedDateTimeISO,
                    type,
                    depthKM,
                    mag,
                }))(item.report);
            }
            let preparedPickedProperties = Object.entries(propertiesPicked);
            let earthquakesObj = {
                type: this.AerisType.EARTHQUAKES,
                loc: item.loc,
                properties: preparedPickedProperties,
                description: AerisDescriptions.EARTHQUAKES,
                showData: false,
            };
            earthquakesList.push(earthquakesObj);
        });
        let earthquakes: ObjAerisType = {};
        earthquakes[AerisType.EARTHQUAKES] = earthquakesList;
        return earthquakes;
    }

    prepareFiresResponse(list: any): ObjAerisType {
        let firesList = [];
        list.response.forEach((item: any) => {
            let propertiesPicked = {};
            if (item.report) {
                propertiesPicked = (({
                    dateTimeISO,
                    starDateISO,
                    cause,
                    areaKM,
                    fuels,
                    location,
                    terrain,
                }) => ({
                    dateTimeISO,
                    starDateISO,
                    cause,
                    areaKM,
                    fuels,
                    location,
                    terrain,
                }))(item.report);
            }
            let preparedPickedProperties = Object.entries(propertiesPicked);
            let firesObj = {
                type: this.AerisType.FIRES,
                loc: item.loc,
                properties: preparedPickedProperties,
                description: AerisDescriptions.FIRES,
                shoWData: false,
            };
            firesList.push(firesObj);
        });
        let fires: ObjAerisType = {};
        fires[AerisType.FIRES] = firesList;
        return fires;
    }

    prepareNormalsResponse(list: any): ObjAerisType {
        let normalsList = [];
        list.response.forEach((item: any) => {
            // daily - default - periods[0]
            let propertiesPicked = {};
            if (item.periods) {
                let generalProperties = (({ dateTimeISO, type }) => ({
                    dateTimeISO,
                    type,
                }))(item.periods[0]);
                let tempProperties = item.periods[0].temp;
                propertiesPicked = { ...generalProperties, ...tempProperties };
            }
            let preparedPickedProperties = Object.entries(propertiesPicked);
            let normalsObj = {
                type: this.AerisType.CLIMATE_NORMALS,
                loc: item.loc,
                properties: preparedPickedProperties,
                description: AerisDescriptions.CLIMATE_NORMALS,
                showData: false,
            };
            normalsList.push(normalsObj);
        });
        let normals: ObjAerisType = {};
        normals[AerisType.CLIMATE_NORMALS] = normalsList;
        return normals;
    }

    prepareObservationsResponse(list: any): ObjAerisType {
        let observationsList = [];
        list.response.forEach((item: any) => {
            let obsPropertiesPicked = {};
            let generalProperties = {};
            if (item.dataSource) {
                generalProperties = (({ dataSource }) => ({ dataSource }))(
                    item
                );
            }
            if (item.ob) {
                obsPropertiesPicked = (({
                    dateTimeISO,
                    feelslikeC,
                    humidity,
                    heatindexC,
                    recDateTimeISO,
                    solradMethod,
                    tempC,
                    weather,
                    windDir,
                    windSpeedKPH,
                    windGustKPH,
                }) => ({
                    dateTimeISO,
                    feelslikeC,
                    humidity,
                    heatindexC,
                    recDateTimeISO,
                    solradMethod,
                    tempC,
                    weather,
                    windDir,
                    windSpeedKPH,
                    windGustKPH,
                }))(item.ob);
            }
            let propertiesPicked = {
                ...generalProperties,
                ...obsPropertiesPicked,
            };
            let preparedPickedProperties = Object.entries(propertiesPicked);
            let observationsObj = {
                type: this.AerisType.OBSERVATIONS,
                loc: item.loc,
                properties: preparedPickedProperties,
                description: AerisDescriptions.OBSERVATIONS,
                showData: false,
            };
            observationsList.push(observationsObj);
        });
        let observations: ObjAerisType = {};
        observations[AerisType.OBSERVATIONS] = observationsList;
        return observations;
    }

    prepareRiversResponse(list: any): ObjAerisType {
        let riversList = [];
        list.response.forEach((item: any) => {
            let propertiesPicked = {};
            if (item.ob) {
                propertiesPicked = (({
                    dateTimeISO,
                    heightM,
                    flowCMS,
                    status,
                    impact,
                }) => ({ dateTimeISO, heightM, flowCMS, status, impact }))(
                    item.ob
                );
            }
            let preparedPickedProperties = Object.entries(propertiesPicked);
            let riversObj = {
                type: this.AerisType.RIVERS,
                loc: item.loc,
                properties: preparedPickedProperties,
                description: AerisDescriptions.RIVERS,
                showData: false,
            };
            riversList.push(riversObj);
        });
        let rivers: ObjAerisType = {};
        rivers[AerisType.RIVERS] = riversList;
        return rivers;
    }

    prepareRiversGaugesResponse(list: any): ObjAerisType {
        let riversGaugesList = [];
        list.response.forEach((item: any) => {
            let propertiesPicked = {};
            let preparedPickedProperties;
            let impacts = [];
            if (item.profile) {
                if (item.profile.cats) {
                    propertiesPicked = (({
                        actionM,
                        floodM,
                        majorM,
                        moderateM,
                    }) => ({ actionM, floodM, majorM, moderateM }))(
                        item.profile.cats
                    );
                }
                if (item.profile.waterbody) {
                    propertiesPicked["waterbody"] = item.profile.waterbody;
                }
                preparedPickedProperties = Object.entries(propertiesPicked);
                let impactsProp;
                let impatcsExists = item.profile.hasOwnProperty("impacts");
                if (impatcsExists) {
                    if (item.profile.impacts) {
                        item.profile.impacts.forEach((impact) => {
                            impactsProp = Object.entries(impact);
                            impacts.push(impactsProp);
                        });
                    }
                }
            }
            let riversGaugesObj = {
                type: this.AerisType.RIVERS_GAUGES,
                loc: item.loc,
                properties: preparedPickedProperties,
                traitLabel: "Impact",
                traitList: impacts || null,
                description: AerisDescriptions.RIVERS_GAUGES,
                showData: false,
            };
            riversGaugesList.push(riversGaugesObj);
        });
        let riversGauges: ObjAerisType = {};
        riversGauges[AerisType.RIVERS_GAUGES] = riversGaugesList;
        return riversGauges;
    }

    prepareStormcellsResponse(list: any): ObjAerisType {
        let stormcellsList = [];
        list.response.forEach((item: any) => {
            let propertiesPicked = {};
            if (item.ob) {
                propertiesPicked = (({ dateTimeISO, location }) => ({
                    dateTimeISO,
                    location,
                }))(item.ob);
            }
            let preparedPickedProperties = Object.entries(propertiesPicked);
            let traits;
            if (item.traits) {
                traits = Object.entries(item.traits);
            }
            let stormcellsObj = {
                type: this.AerisType.STORMCELLS,
                loc: item.loc,
                properties: preparedPickedProperties,
                traitLabel: "Traits",
                traitList: traits || null,
                description: AerisDescriptions.STORMCELLS,
                showData: false,
            };
            stormcellsList.push(stormcellsObj);
        });
        let stormcells: ObjAerisType = {};
        stormcells[AerisType.STORMCELLS] = stormcellsList;
        return stormcells;
    }

    prepareStormreportsResponse(list: any): ObjAerisType {
        let stormreportsList = [];
        list.response.forEach((item: any) => {
            let propertiesPicked = {};
            if (item.report) {
                propertiesPicked = (({
                    dateTimeISO,
                    cat,
                    comments,
                    type,
                    reporter,
                    name,
                }) => ({ dateTimeISO, cat, comments, type, reporter, name }))(
                    item.report
                );
            }
            const preparedPickedProperties = Object.entries(propertiesPicked);
            let stormreportsObj = {
                type: this.AerisType.STORM_REPORTS,
                loc: item.loc,
                properties: preparedPickedProperties,
                description: AerisDescriptions.STORM_REPORTS,
                showData: false,
            };
            stormreportsList.push(stormreportsObj);
        });
        let stormreports: ObjAerisType = {};
        stormreports[AerisType.STORM_REPORTS] = stormreportsList;
        return stormreports;
    }

    prepareTidesResponse(list: any): ObjAerisType {
        let tidesList = [];
        list.response.forEach((item: any) => {
            let propertiesPicked = {};
            if (item.periods) {
                propertiesPicked = (({
                    dateTimeISO,
                    heightM,
                    heightFT,
                    type,
                }) => ({ dateTimeISO, heightM, heightFT, type }))(
                    item.periods[0]
                );
            }
            let preparedPickedProperties = Object.entries(propertiesPicked);
            let tidesObj = {
                type: this.AerisType.TIDES,
                loc: item.loc,
                properties: preparedPickedProperties || null,
                description: AerisDescriptions.TIDES,
                showData: false,
            };
            tidesList.push(tidesObj);
        });
        let tides: ObjAerisType = {};
        tides[AerisType.TIDES] = tidesList;
        return tides;
    }

    prepareTropicalcyclonesResponse(list: any): ObjAerisType {
        let tropicalcyclonesList = [];
        list.response.forEach((item: any) => {
            let propertiesPicked = {};
            let movementPrepared;
            if (item.position) {
                let generalInfoPicked = (({ dateTimeISO }) => ({
                    dateTimeISO,
                }))(item.position);
                if (item.position.details) {
                    let detailsPicked = (({
                        stormName,
                        stormType,
                        advisoryNumber,
                        windSpeedKTS,
                        gustSpeedKTS,
                        pressureMB,
                        basin,
                    }) => ({
                        stormName,
                        stormType,
                        advisoryNumber,
                        windSpeedKTS,
                        gustSpeedKTS,
                        pressureMB,
                        basin,
                    }))(item.position.details);
                    let movement = item.position.details.movement;
                    movementPrepared = Object.entries(movement);
                    propertiesPicked = {
                        ...generalInfoPicked,
                        ...detailsPicked,
                    };
                }
            }
            let preparedPickedProperties = Object.entries(propertiesPicked);
            let tropicalcyclonesObj = {
                type: this.AerisType.TROPICALCYCLONES,
                properties: preparedPickedProperties,
                loc: item.position.loc,
                traitLabel: "Movement",
                traitList: movementPrepared,
                description: AerisDescriptions.TROPICALCYCLONES,
                showData: false,
            };
            tropicalcyclonesList.push(tropicalcyclonesObj);
        });
        let tropicalcyclones: ObjAerisType = {};
        tropicalcyclones[AerisType.TROPICALCYCLONES] = tropicalcyclonesList;
        return tropicalcyclones;
    }

    prepareAlertsResponse(response: any): ObjAerisType {
        let alertsTypesPicked;
        let alertsList = [];
        if (response !== undefined && response.response.length > 0) {
            alertsTypesPicked = (({ types }) => ({ types }))(
                response.response[0].summary
            );
            let propertiesPicked = [];
            alertsTypesPicked.types.forEach((alert) => {
                alert.countries = alert.countries.toString();
                let alertPropertiesPicked = (({
                    type,
                    countries,
                    priority,
                    count,
                }) => ({ type, countries, priority, count }))(alert);
                let preparedPickedProperties = Object.entries(
                    alertPropertiesPicked
                );
                propertiesPicked.push(preparedPickedProperties);
            });
            let alertsObj = {
                type: this.AerisType.ALERTS_SUMMARY,
                properties: propertiesPicked || null,
                description: this.AerisDescriptions.ALERTS_SUMMARY,
                showData: false,
            };
            alertsList.push(alertsObj);
        }
        let alerts: ObjAerisType = {};
        alerts[AerisType.ALERTS_SUMMARY] = alertsList;
        return alerts;
    }

    prepareLightningResponse(response: any): ObjAerisType {
        let lightningRange = {};
        let lightningPulse = {};
        let lightningList = [];
        if (response !== undefined && response.response.length > 0) {
            lightningRange = (({ count, minDateTimeISO, maxDateTimeISO }) => ({
                count,
                minDateTimeISO,
                maxDateTimeISO,
            }))(response.response[0].summary.range);
            let prepareLightningRange = Object.entries(lightningRange);
            lightningPulse = (({ count, cg, ic, negative, positive }) => ({
                count,
                cg,
                ic,
                negative,
                positive,
            }))(response.response[0].summary.pulse);
            let prepareLightningPulse = Object.entries(lightningPulse);
            let lightningObj = {
                type: this.AerisType.LIGHTNING_SUMMARY,
                range: prepareLightningRange,
                pulse: prepareLightningPulse,
                description: this.AerisDescriptions.LIGHTNING_SUMMARY,
                showData: false,
            };
            lightningList.push(lightningObj);
        }
        let lightning: ObjAerisType = {};
        lightning[AerisType.LIGHTNING_SUMMARY] = lightningList;
        return lightning;
    }

    get AerisType() {
        return AerisType;
    }

    get AerisDescriptions() {
        return AerisDescriptions;
    }

    get numberOfAerisRequestsWithinShapes() {
        return numberOfAerisRequestsWithinShapes;
    }

    onRectangleComplete(
        overlay: google.maps.Rectangle,
        updateFilter: boolean
    ): void {
        const bounds = overlay.getBounds();
        const list = this.isMeasurementDrawing
            ? this.rectangles.measure
            : this.rectangles.select;
        const rectangle: RectangleDrawing = {
            north: bounds.getNorthEast().lat(),
            south: bounds.getSouthWest().lat(),
            east: bounds.getNorthEast().lng(),
            west: bounds.getSouthWest().lng(),
        };
        list.push(rectangle);
        if (!this.isMeasurementDrawing && updateFilter) {
            this.onGeometriesChange();
            this.fetchAerisApiDataWithinRectangle(rectangle);
        } else {
            this.fetchAerisApiDataWithinRectangle(rectangle);
        }
    }

    fetchAerisApiDataWithinRectangle(rectangle: RectangleDrawing) {
        rectangle.isReceivingAllData = false;
        forkJoin([
            this.aerisService.fetchAerisDataWithinRectangle(
                AerisType.AIR_QUALITY,
                this.aerisCredentials.aerisClient,
                this.aerisCredentials.aerisSecret,
                rectangle.north,
                rectangle.west,
                rectangle.south,
                rectangle.east,
                FetchLimitParam.limit
            ),
            this.aerisService.fetchAerisDataWithinRectangle(
                AerisType.EARTHQUAKES,
                this.aerisCredentials.aerisClient,
                this.aerisCredentials.aerisSecret,
                rectangle.north,
                rectangle.west,
                rectangle.south,
                rectangle.east,
                FetchLimitParam.limit
            ),
            this.aerisService.fetchAerisDataWithinRectangle(
                AerisType.FIRES,
                this.aerisCredentials.aerisClient,
                this.aerisCredentials.aerisSecret,
                rectangle.north,
                rectangle.west,
                rectangle.south,
                rectangle.east,
                FetchLimitParam.limit
            ),
            this.aerisService.fetchAerisDataWithinRectangle(
                AerisType.CLIMATE_NORMALS,
                this.aerisCredentials.aerisClient,
                this.aerisCredentials.aerisSecret,
                rectangle.north,
                rectangle.west,
                rectangle.south,
                rectangle.east,
                FetchLimitParam.limit
            ),
            this.aerisService.fetchAerisDataWithinRectangle(
                AerisType.OBSERVATIONS,
                this.aerisCredentials.aerisClient,
                this.aerisCredentials.aerisSecret,
                rectangle.north,
                rectangle.west,
                rectangle.south,
                rectangle.east,
                FetchLimitParam.limit
            ),
            this.aerisService.fetchAerisDataWithinRectangle(
                AerisType.RIVERS,
                this.aerisCredentials.aerisClient,
                this.aerisCredentials.aerisSecret,
                rectangle.north,
                rectangle.west,
                rectangle.south,
                rectangle.east,
                FetchLimitParam.limit
            ),
            this.aerisService.fetchAerisDataWithinRectangle(
                AerisType.RIVERS_GAUGES,
                this.aerisCredentials.aerisClient,
                this.aerisCredentials.aerisSecret,
                rectangle.north,
                rectangle.west,
                rectangle.south,
                rectangle.east,
                FetchLimitParam.limit
            ),
            this.aerisService.fetchAerisDataWithinRectangle(
                AerisType.STORMCELLS,
                this.aerisCredentials.aerisClient,
                this.aerisCredentials.aerisSecret,
                rectangle.north,
                rectangle.west,
                rectangle.south,
                rectangle.east,
                FetchLimitParam.limit
            ),
            this.aerisService.fetchAerisDataWithinRectangle(
                AerisType.STORM_REPORTS,
                this.aerisCredentials.aerisClient,
                this.aerisCredentials.aerisSecret,
                rectangle.north,
                rectangle.west,
                rectangle.south,
                rectangle.east,
                FetchLimitParam.limit
            ),
            this.aerisService.fetchAerisDataWithinRectangle(
                AerisType.TIDES,
                this.aerisCredentials.aerisClient,
                this.aerisCredentials.aerisSecret,
                rectangle.north,
                rectangle.west,
                rectangle.south,
                rectangle.east,
                FetchLimitParam.limit
            ),
            this.aerisService.fetchAerisDataWithinRectangle(
                AerisType.TROPICALCYCLONES,
                this.aerisCredentials.aerisClient,
                this.aerisCredentials.aerisSecret,
                rectangle.north,
                rectangle.west,
                rectangle.south,
                rectangle.east,
                FetchLimitParam.tropicalcyclonesLimit
            ),
            this.aerisService.fetchAerisDataWithinRectangle(
                AerisType.ALERTS_SUMMARY,
                this.aerisCredentials.aerisClient,
                this.aerisCredentials.aerisSecret,
                rectangle.north,
                rectangle.west,
                rectangle.south,
                rectangle.east,
                FetchLimitParam.limit
            ),
            //,this.aerisService.fetchAerisDataWithinRectangle(AerisType.LIGHTNING_SUMMARY, rectangle.north, rectangle.west, rectangle.south, rectangle.east, FetchLimitParam.limit)
        ]).subscribe(
            ([
                airqualityObs,
                earthquakesObs,
                firesObs,
                normalsObs,
                observationsObs,
                riversObs,
                riversGaugesObs,
                stormcellsObs,
                stormReportsObs,
                tidesObs,
                tropicalcyclonesObs,
                alertsObs,
                lightningObs,
            ]) => {
                rectangle.rectangleData = {};
                rectangle.rectangleData = Object.assign(
                    this.prepareAirQualityResponse(airqualityObs),
                    this.prepareEarthquakesResponse(earthquakesObs),
                    this.prepareFiresResponse(firesObs),
                    this.prepareNormalsResponse(normalsObs),
                    this.prepareObservationsResponse(observationsObs),
                    this.prepareRiversResponse(riversObs),
                    this.prepareRiversGaugesResponse(riversGaugesObs),
                    this.prepareStormcellsResponse(stormcellsObs),
                    this.prepareStormreportsResponse(stormReportsObs),
                    this.prepareTidesResponse(tidesObs),
                    this.prepareTropicalcyclonesResponse(tropicalcyclonesObs),
                    this.prepareAlertsResponse(alertsObs),
                    this.prepareLightningResponse(lightningObs)
                );
            },
            (error) => {
                rectangle.isReceivingAllData = true;
                rectangle.emptyResponsesCounter =
                    numberOfAerisRequestsWithinShapes;
                this.notifService.error(
                    "Something went wrong... We can not provide Risk Info"
                );
                console.log(error);
            },
            () => {
                rectangle.emptyResponsesCounter = 0;
                rectangle.isReceivingAllData = true;
                rectangle.emptyResponsesCounter = this.aerisResponsesCounter(
                    rectangle.rectangleData
                );
            }
        );
    }

    onPolylineComplete(overlay: google.maps.Polyline) {
        let path = overlay.getPath().getArray();
        const polylineObj: PolylineDrawing = {
            polyline: path as google.maps.LatLng[],
            latPolylineInfobox: path[0].lat(),
            lngPolylineInfobox: path[0].lng(),
        };
        this.polylines.measure.push(polylineObj);
    }

    getLastCoordsForInfobox(event, i, info: AgmInfoWindow) {
        let point = { lat: event.latLng.lat(), lng: event.latLng.lng() };
        this.polylines.measure[i].latPolylineInfobox = point.lat;
        this.polylines.measure[i].lngPolylineInfobox = point.lng;
        info.open();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
        this.destroy$.next();
        this.destroy$.complete();
        this.renderer.removeClass(document.body, 'custom-recaptcha-page');
    }

    openDetails($event: MapDetails) {
        if ($event.isPopupCall) {
            const Object = {
                location: { x: $event.location.x, y: $event.location.y },
                radius: $event.radius,
            };
            this.drawCircle(Object);
        } else {
            if ($event.dataset.id === OpenWeather.getId()) {
                this.drawOpenWeatherPolygon($event);
            }
            this.DetailsOpen.emit($event);
        }
    }

    drawOpenWeatherPolygon(alertEvent: any) {
        this.datapointService
            .getOpenWeatherDatapointDetails(alertEvent.datapointID)
            .subscribe((datapoint) => {
                this.resetOpenWeatherVariables();
                this.openWeatherAlertsObjects.set(
                    alertEvent.datapointId,
                    datapoint
                );
                for (let [key, value] of this.openWeatherAlertsObjects) {
                    const convertedArray = value.geometry.coordinates[0].map(
                        (coordinates) => {
                            return { lat: coordinates[1], lng: coordinates[0] };
                        }
                    );
                    const polygon = new google.maps.Polygon({
                        paths: convertedArray,
                        fillColor: OpenWeather.polygonConfiguration().fillColor, // '#00FF00',
                        fillOpacity:
                            OpenWeather.polygonConfiguration().fillOpacity,
                        strokeColor: OpenWeather.getStrokeColor(),
                        strokeOpacity:
                            OpenWeather.polygonConfiguration().strokeOpacity,
                        strokeWeight:
                            OpenWeather.polygonConfiguration().strokeWeight,
                        map: this.mapElement,
                    });
                    const params = {
                        geometryId: value.geometryID,
                        event_name: value.field_event,
                        start_date: value.field_start_date,
                        end_date: value.field_end_date,
                    };
                    this.openWeatherpolygons.push({ polygon: polygon, params });
                }
                this.openWeatherpolygons.forEach((polygonData, index) => {
                    const { polygon } = polygonData;
                    polygon.addListener("click", () =>
                        this.onPolygonClick(index, polygonData)
                    );
                });
            });
    }
    onPolygonClick(
        index: number,
        polygonData: {
            polygon: google.maps.Polygon;
            params: {
                geometryId: string;
                event_name: String;
                start_date: String;
                end_date: String;
            };
        }
    ): void {
        const {
            polygon,
            params: { geometryId, event_name, start_date, end_date },
        } = polygonData;
        const details =
            "<div> <b>Event:</b> " +
            event_name +
            "</div><div> <b>Start Date:</b> " +
            start_date +
            "</div><div> <b>End Date:</b> " +
            end_date +
            "</div>";
        this.openWeatherInfoWindow.setContent(details);
        this.openWeatherInfoWindow.setPosition(polygon.getPath().getAt(0));
        this.openWeatherInfoWindow.open(this.mapElement);
    }

    resetOpenWeatherVariables() {
        this.openWeatherInfoWindow?.close();
        this.openWeatherAlertsObjects.clear();
        this.openWeatherpolygons.forEach((polygonData, index) => {
            polygonData.params.geometryId = "";
            polygonData.polygon.setMap(null);
        });
        this.openWeatherpolygons = [];
    }
    drawCircle(crisis24: Crisis24Circle) {
        const options = {
            strokeColor: Crisis24Alert.getColor(crisis24.severity),
            strokeOpacity: 1.0,
            strokeWeight: 1,
            fillColor: Crisis24Alert.getColor(crisis24.severity),
            fillOpacity: 0.5,
            map: this.mapElement,
            center: new google.maps.LatLng(
                crisis24.location.y,
                crisis24.location.x
            ),
            clickable: true,
            radius: crisis24.radius * 1000, //Here, we convert radius in (KM) * Meter. 1 KM = 1000 Meter
        };
        const circle = new google.maps.Circle(options);
        google.maps.event.addListener(circle, "click", function (ev) {
            const contentString =
                "<div> <b>Severity:</b> " +
                crisis24.severity +
                "</div><div> <b>Radius:</b> " +
                crisis24.radius +
                '</div><div style="height: 6px; width: 100%; margin-top: 8px; border-radius: 2px; opacity: 0.8; background:' +
                Crisis24Alert.getColor(crisis24.severity) +
                '"></div>';
            const infowindow = new google.maps.InfoWindow({
                content: contentString,
            });
            const mapElement = this.mapElement;
            infowindow.setPosition(
                new google.maps.LatLng(crisis24.location.y, crisis24.location.x)
            );
            infowindow.open({
                anchor: circle,
                mapElement,
            });
        });
        this.crises24CircleObjects.push(circle);
    }

    updatePolygonPaths(
        polygon: PolygonDrawing,
        event: PolygonPathEvent<any>,
        index: number,
        type: string,
        action: string
    ) {
        polygon.isReceivingAllData = false;
        polygon.polygonData = {};
        let parsedPoints = [];
        for (let i = 0; i < event.newArr[0].length; i++) {
            parsedPoints[i] = new google.maps.LatLng(
                Number(event.newArr[0][i].lat),
                Number(event.newArr[0][i].lng)
            );
        }
        this.shapeUpdateDebouncer.next({
            event: [new google.maps.MVCArray(parsedPoints)],
            index: index,
            type: type,
            action: action,
        });
    }

    computeRectangleArea(bounds: RectangleDrawing): number {
        if (!bounds) {
            return 0;
        }
        const southWest = new google.maps.LatLng(bounds.south, bounds.west);
        const northEast = new google.maps.LatLng(bounds.north, bounds.east);
        const southEast = new google.maps.LatLng(bounds.south, bounds.east);
        const northWest = new google.maps.LatLng(bounds.north, bounds.west);
        if (google.maps.geometry) {
            return google.maps.geometry.spherical.computeArea([
                northEast,
                northWest,
                southWest,
                southEast,
            ]);
        }
    }

    computeCircleArea(radius: number): number {
        return Math.PI * Math.pow(radius, 2);
    }

    computePolygonArea(path: google.maps.MVCArray<google.maps.LatLng>) {
        if (google.maps.geometry) {
            return google.maps.geometry.spherical.computeArea(path);
        }
    }

    computePolylineDistance(path: any) {
        return google.maps.geometry.spherical.computeLength(path);
    }

    squareMToSquareMi(area: number): number {
        return area / 2589988.1103;
    }

    squareMToSquareKm(area: number): number {
        return area / 1000000;
    }

    squareMToSquareFeet(area: number) {
        return area * 10.76391041671;
    }
    mToMi(value: number): number {
        return value / 1609.34;
    }

    mToKm(value: number): number {
        return value / 1000;
    }

    mToFeet(value: number): number {
        return this.mToKm(value) * 3280.84;
    }

    mToMeter(value: number): number {
        return this.mToKm(value) * 1000;
    }

    copyAllMeasureDistance(path: any){
        const distance= this.computePolylineDistance(path);
        const distancemToKm = this.decimalPipe.transform(this.mToKm(distance), '1.0-2');
        const distancemToMi = this.decimalPipe.transform(this.mToMi(distance), '1.0-2');
        const distancemToFeet = this.decimalPipe.transform(this.mToFeet(distance), '1.0-2');
        const distancemToM = this.decimalPipe.transform(this.mToMeter(distance), '1.0-2');
        const data = `${distancemToKm} \n${distancemToMi} \n${distancemToM} \n${distancemToFeet}`;
        window.navigator.clipboard.writeText(data).then(
            () => this.notifService.success("Copied All data to clipboard"),
            (err) => console.error("Failed to copy measurements:", err)
        );
    }

    copyAllMeasureCircle(radius: number) {
        const area = this.computeCircleArea(radius);
        const circleAreasquareMToSquareKm = this.decimalPipe.transform(this.squareMToSquareKm(area), '1.0-2');
        const circleAreasquareMToSquareMi = this.decimalPipe.transform(this.squareMToSquareMi(area), '1.0-2');
        const circleAreasquareMToSquareM = this.decimalPipe.transform(area, '1.0-2');
        const circleAreasquareMToSquareFeet = this.decimalPipe.transform(this.squareMToSquareFeet(area), '1.0-2');
        const data = `${circleAreasquareMToSquareKm} \n${circleAreasquareMToSquareMi} \n${circleAreasquareMToSquareM} \n${circleAreasquareMToSquareFeet} `;
        // Copy the concatenated string to the clipboard
        window.navigator.clipboard.writeText(data).then(
            () => this.notifService.success("Copied All data to clipboard"),
            (err) => console.error("Failed to copy measurements:", err)
        );
    }

    copyAllMeasureRectangle(bounds: RectangleDrawing){
        const area = this.computeRectangleArea(bounds);
        const rectangleAreasquareMToSquareKm = this.decimalPipe.transform(this.squareMToSquareKm(area), '1.0-2');
        const rectangleAreasquareMToSquareMi = this.decimalPipe.transform(this.squareMToSquareMi(area), '1.0-2');
        const rectangleAreasquareMToSquareM = this.decimalPipe.transform(area, '1.0-2');
        const rectangleAreasquareMToSquareFeet = this.decimalPipe.transform(this.squareMToSquareFeet(area), '1.0-2');
        const data = `${rectangleAreasquareMToSquareKm} \n${rectangleAreasquareMToSquareMi} \n${rectangleAreasquareMToSquareM} \n${rectangleAreasquareMToSquareFeet} `;
         // Copy the concatenated string to the clipboard
         window.navigator.clipboard.writeText(data).then(
            () => this.notifService.success("Copied All data to clipboard"),
            (err) => console.error("Failed to copy measurements:", err)
        );
    }
    
    copyAllMeasurePolygon(path: google.maps.MVCArray<google.maps.LatLng>){
        const area = this.computePolygonArea(path);
        const rectangleAreasquareMToSquareKm = this.decimalPipe.transform(this.squareMToSquareKm(area), '1.0-2');
        const rectangleAreasquareMToSquareMi = this.decimalPipe.transform(this.squareMToSquareMi(area), '1.0-2');
        const rectangleAreasquareMToSquareM = this.decimalPipe.transform(area, '1.0-2');
        const rectangleAreasquareMToSquareFeet = this.decimalPipe.transform(this.squareMToSquareFeet(area), '1.0-2');
        const data = `${rectangleAreasquareMToSquareKm} \n${rectangleAreasquareMToSquareMi} \n${rectangleAreasquareMToSquareM} \n${rectangleAreasquareMToSquareFeet} `;
         // Copy the concatenated string to the clipboard
         window.navigator.clipboard.writeText(data).then(
            () => this.notifService.success("Copied All data to clipboard"),
            (err) => console.error("Failed to copy measurements:", err)
        );
    }

    onClickMeasurment(type: string, input) {
        this.notifService.success("Copied to clipboard");
        let data;
        if (type === 'squareMToSquareKm') {
            data = this.squareMToSquareKm(input);
        } else if (type === 'squareMToSquareMi') {
            data = this.squareMToSquareMi(input);
        } else if (type === 'squareMToSquareFeet') {
            data = this.squareMToSquareFeet(input);
        } else if (['computePolygonArea', 'computeCircleArea', 'computeRectangleArea'].includes(type)) {
            data = input;
        } else if(type === 'mToKm') {
            data = this.mToKm(input);
        } else if(type === 'mToMi') {
            data = this.mToMi(input);
        } else if(type === 'mToFeet') {
            data = this.mToFeet(input);
        } else if(type === 'mToMeter') {
            data = this.mToMeter(input);
        }
        window.navigator.clipboard.writeText(this.decimalPipe.transform(data, '1.0-2'));
    }

    private handleImageOverlaysMapChanges(mapState: MapStateForImageOverlays) {
        switch (mapState.action) {
            case MapStateActionEnum.NONE:
                break;
            case MapStateActionEnum.UPDATE_OVERLAY:
                break;
            case MapStateActionEnum.INSERT_OVERLAY: {
                if (mapState.interactive) {
                    this.onInteractiveMapAdded(
                        mapState.overlay,
                        mapState.opacity
                    );
                    return;
                }
                let existingIndex =
                    this.datapointsPageStateService.activeImageOverlaysOnMap.findIndex(
                        (overlay) => overlay.id === mapState.overlay.id
                    );
                if (existingIndex >= 0) {
                    return;
                }
                this.mapElement.overlayMapTypes.push(mapState.map);
                this.datapointsPageStateService.activeImageOverlaysOnMap.push(
                    mapState.overlay
                );
                break;
            }
            case MapStateActionEnum.REMOVE_OVERLAY: {
                if (mapState.interactive) {
                    this.onInteractiveMapRemoved(mapState.overlay);
                    return;
                }
                let indexOfOverlay = this.mapElement.overlayMapTypes
                    .getArray()
                    .findIndex((map) => map.name === mapState.overlay.id);
                this.mapElement.overlayMapTypes.removeAt(indexOfOverlay);
                this.datapointsPageStateService.activeImageOverlaysOnMap.splice(
                    indexOfOverlay,
                    1
                );
                break;
            }
        }
    }

    onSearchAutocompleteSelected($event) {
        this.mapStateService.activeSearchResultMarker$.next({
            fitBounds: $event.geometry.viewport,
        });
        this.mapStateService.emitMarkerIsInitialized(true);
    }

    onSearchLocationSelected(location: GMALocation) {
        this.mapStateService.activeSearchResultMarker$.next({
            longitude: location.longitude,
            latitude: location.latitude,
        });
        this.mapElement.setCenter({
            lat: location.latitude,
            lng: location.longitude,
        });
        this.mapElement.setZoom(12);
    }

    updateAddressOnDragEnd(x: any, y: any) {
        this.address = `${x},${y}`;
    }

    seeAddressDetails() {
        const searchBoxValue = !isUndefined(this.address)
            ? this.address.split(",")
            : this.address;
        const isFloat = function isFloat(x) {
            return !!(x % 1);
        };
        let isLatLonInput = false;
        if (!isUndefined(searchBoxValue) && searchBoxValue.length === 2) {
            const latitude = parseFloat(searchBoxValue[0].trim());
            const longitude = parseFloat(searchBoxValue[1].trim());
            if (isFloat(latitude) && isFloat(longitude)) {
                isLatLonInput = true;
                this.onSearchLocationSelected({
                    latitude: latitude,
                    longitude: longitude,
                });
            }
        }

        if (!isLatLonInput) {
            if (!this.markerIsInitialized) {
                this.initMarker();
            }
        }

        if (this.searchResultMarker$.value !== null) {
            this.dataPointsServiceState.activeSearchLocation = {
                x: this.searchResultMarker$.value.longitude,
                y: this.searchResultMarker$.value.latitude,
            };
            this.onCustomLocationDetailsSelected.emit({
                x: this.searchResultMarker$.value.longitude,
                y: this.searchResultMarker$.value.latitude,
            });
        }
    }

    getMapViewType(): { type: string; iconSource: string }[] {
        return Object.values(MapViewType).map((mode) => ({
            type: mode,
            iconSource: MapIcons[mode],
        }));
    }

    onIconClick(mode: string): void {
        this.mapType = { type: mode, iconSource: MapIcons[mode] };
        this.cd.detectChanges();
    }

    public clearCrises24CircleObjectsArray() {
        if (this.crises24CircleObjects.length) {
            this.crises24CircleObjects.forEach((element) => {
                element.setMap(null);
            });
        }
    }

    // createTensorflightPolygon(params) {
    //     if (
    //         params.newSelectedValue === this.locationProfile.TENSORFLIGHT &&
    //         this.externalTensorFlightData &&
    //         this.externalTensorFlightData.status == TensorflightStatus.SUCCESS
    //     ) {
    //         this.tensorFlightPolygonData = {
    //             paths: this.externalTensorFlightData.polygonData.coordinates[0].map(
    //                 (coord) => {
    //                     return { lat: coord[1], lng: coord[0] };
    //                 }
    //             ),
    //         };

    //         if (this.isTensorflightFirstTimeFetched) {
    //             this.zoomInMapToPolygon();
    //         }

    //         this.isTensorflightFirstTimeFetched = false;
    //         this.areBoundsChanged = false;
    //     } else if (
    //         params.prevSelectedValue === this.locationProfile.TENSORFLIGHT &&
    //         params.newSelectedValue !== this.locationProfile.TENSORFLIGHT
    //     ) {
    //         this.removeTensorflightPolygon();
    //     }
    // }

    onBoundsChange(event: any) {
        this.currentMapBounds = event;
    }

    // removeTensorflightPolygon() {
    //     this.tensorFlightPolygonData = null;
    // }

    onZoomChange(event: any) {
        this.currentZoom = event;
        this.areBoundsChanged = true;
    }

    zoomInMapToPolygon() {
        const bounds = new google.maps.LatLngBounds();
        // for (const path of this.tensorFlightPolygonData.paths) {
        //     bounds.extend(new google.maps.LatLng(path.lat, path.lng));
        // }

        const parsedBounds = JSON.parse(JSON.stringify(bounds));
        this.mapElement.fitBounds(parsedBounds);
    }

    getDefaultIconUrl() {
        return "../../../assets/icons/mapIcons/icon-pin.png";
    }

    // createAddressProfileTensorflightPolygon(params) {
    //     if (params) {
    //         this.tensorFlightPolygonData = {
    //             paths: params.coordinates[0].map((coord) => {
    //                 return { lat: coord[1], lng: coord[0] };
    //             }),
    //         };
    //         this.zoomInMapToPolygon();
    //     } else {
    //         this.removeTensorflightPolygon();
    //     }
    // }

    prepareHeatMap(response: HeatmapResponse) {
        let heatmapData: google.maps.visualization.WeightedLocation[] = [];
        let hasSomeValue = response.data.some((value) => value.weight > 0);

        response.data.forEach((value) => {
            let weightedLocation: google.maps.visualization.WeightedLocation = {
                location: new google.maps.LatLng(
                    value.latitude,
                    value.longitude
                ),
                weight: value.weight,
            };
            heatmapData.push(weightedLocation);
        });

        let heatMap = new google.maps.visualization.HeatmapLayer({
            data: heatmapData,
            map: this.mapElement,
        });

        var gradientForValues = [
            "rgba(0, 255, 0, 0)", // Transparent green
            'rgb(0, 255, 0)',     // Green
            'rgb(127, 255, 0)',   // Chartreuse Green
            'rgb(255, 255, 0)',   // Yellow
            'rgb(255, 165, 0)',   // Orange
            'rgb(255, 0, 0)'      // Red
        ];

        let gradientForZeros = [
            "rgba(0, 255, 0, 0)", // Transparent green
            "rgba(0, 255, 0, 1)", // Solid green
        ];

        heatMap.set("radius", heatMap.get("radius") ? null : 1);
        if (!hasSomeValue) {
            heatMap.set("gradient", gradientForZeros);
        } else {
            heatMap.set("gradient", gradientForValues);
        }

        heatMap.set("dissipating", false);
        heatMap.set("opacity", 0.6);
        this.heatMap = heatMap;
    }


}

interface GeojsonMapItem {
    geojson: any; // geojson
    showInfo: boolean;
    clickedLocation?: LatLngLiteral;
    style: MapTypeStyle;
    regionId?: number;
}
