<div class="edit-form-container">
    <form *ngIf="editAccountForm && !loading" [formGroup]="editAccountForm" class="form-wrapper">
        <mat-form-field>
            <mat-label>Name</mat-label>
            <input matInput autocomplete="off" name="edit-name" required formControlName="name">
            <mat-error *ngIf="editAccountForm.controls['name'].touched && editAccountForm.controls['name'].hasError('required') ">
                Account name is required
            </mat-error>
            <mat-error *ngIf="editAccountForm.controls['name'].touched && editAccountForm.controls['name'].hasError('maxlength')">
                You exceed the maximum number of characters.
            </mat-error>
            <mat-error *ngIf="editAccountForm.controls['name'].touched && editAccountForm.controls['name'].hasError('pattern')">
                You cannot use special characters. Space is allowed only between words.
            </mat-error> 
        </mat-form-field> 
        <h4 class="create-title">Select modules</h4>
        <div class="list" formGroupName="checkboxes">
        
            <table style="width:100%">
                <tr>
                    <th>Dataset Name</th>
                </tr>
              <div class="showInHorizontal">
                <tr *ngFor="let dataset of datasets; let i = index">
                    <td>
                        <mat-checkbox #datasetCheckbox [formControlName]="datasets[i].text" [checked]="isChecked(dataset.text)" style="text-transform: capitalize">
                            {{ datasets[i].text }}
                        </mat-checkbox>
        
                    </td>
                </tr>
              </div>
            </table>
        </div>
    </form>
    <button (click)="submitAccount()" mat-flat-button type="submit"
            color="accent" class="map-button">Submit
    </button>
</div>

