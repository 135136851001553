<div class="settings-container">
    <form [formGroup]="layerExposeForm">
        <div class="inputs-row">
            <mat-form-field class="cluster-error-fields">
                <mat-select formControlName="equationId" placeholder="Equation" class="cluster-field-dropdown">
                    <ng-container *ngFor="let field of exposeLayerEquations">
                        <mat-option [value]="field.id">{{field.name}} </mat-option>
                    </ng-container>
                </mat-select>
                <mat-error *ngIf="layerExposeForm.get('equationId').errors?.required">
                    Equation is required.
                </mat-error>
            </mat-form-field>
        </div>

        <div class="inputs-row">
            <button mat-button mat-flat-button color="accent" (click)="fetchLayerExposeData()">
                Apply
            </button>
            <button mat-button mat-flat-button color="primary" (click)="clearData()">
                Cancel
            </button>
        </div>
    </form>
</div>

<ng-container *ngIf="showTable; then showTableDatasourceTemplate else noShowMessageTemplate"></ng-container>
<ng-template #showTableDatasourceTemplate>
<div *ngIf="dataIsReady" class="reports-container" style="margin-top: 10px">
    <div class="reports-container">
        <div class="report-box " style="width: 100%; min-height: 46vh">
            <div>
                <div class="report-header" style="display: flex; justify-content: flex-end">
                    <div class="report-type-icons-container">
                        <span (click)="setDisplayType(ReportDisplayType.TABLE)" class="report-icon" [ngClass]="{
                                selected:
                                    reportSubType === ReportDisplayType.TABLE &&
                                    dataIsReady,
                                active: dataIsReady
                            }">
                            <i class="fal fa-table chart-icons" style="
                                    font-size: 14px;
                                    position: relative;
                                    bottom: 1px;
                                "></i>
                        </span>
                        <span (click)="
                                setDisplayType(ReportDisplayType.BAR_CHART)
                            " class="report-icon" [ngClass]="{
                                selected:
                                    reportSubType ===
                                        ReportDisplayType.BAR_CHART &&
                                    dataIsReady,
                                active: dataIsReady
                            }">
                            <svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <g fill="none" fill-rule="evenodd">
                                    <path
                                        d="M16.5 3.5h3A1.5 1.5 0 0 1 21 5v14a1.5 1.5 0 0 1-1.5 1.5H15V5a1.5 1.5 0 0 1 1.5-1.5zM15 20.5H9v-10A1.5 1.5 0 0 1 10.5 9H15M4.5 15H9v5.5H4.5A1.5 1.5 0 0 1 3 19v-2.5A1.5 1.5 0 0 1 4.5 15z"
                                        stroke="#002d57" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round" class="chart-icons" />
                                    <path d="M24 24H0V0h24z" />
                                </g>
                            </svg>
                        </span>
                        <span (click)="
                                setDisplayType(ReportDisplayType.PIE_CHART)
                            " class="report-icon" [ngClass]="{
                                selected:
                                    reportSubType ===
                                        ReportDisplayType.PIE_CHART &&
                                    dataIsReady,
                                active: dataIsReady
                            }">
                            <i class="fal fa-chart-pie chart-icons" style="
                                    font-size: 14px;
                                    position: relative;
                                    bottom: 1px;
                                "></i>
                        </span>

                        <span (click)="downloadReports()"
                            class="analytics-download-btn analytic-header-button report-icon">
                            <!-- download-arrow-icon -->
                            <svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <g fill="none" fill-rule="evenodd">
                                    <path stroke="#002d57" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M15.557 11.11 12 14.669l-3.557-3.557M12 3.997v10.671M20.003 16.446h0a3.557 3.557 0 0 1-3.557 3.557H7.554a3.557 3.557 0 0 1-3.557-3.557h0" />
                                    <path d="M-.005-.005h24.01v24.01H-.005z" />
                                </g>
                            </svg>
                        </span>
                        <span (click)="close()" class="report-icon active">
                            <svg width="11" height="11" viewBox="0 0 9 9" xmlns="http://www.w3.org/2000/svg">
                                <g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"
                                    stroke="#002d57" stroke-width="1.5" class="chart-icons">
                                    <path d="m1 1 3.333 3.333L1 7.667" />
                                    <path d="M7.333 1 4 4.333l3.333 3.334" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </div>

                <div class="report-content" *ngIf="reportSubType == ReportDisplayType.TABLE">
                    <div class="report-table">
                        <table mat-table *ngIf="dataSource" [dataSource]="dataSource" class="report-table">
                            <ng-container matColumnDef="name">
                                <th mat-header-cell *matHeaderCellDef>
                                    Policy Number
                                </th>
                                <td mat-cell *matCellDef="let layerExpose" class="text-left">
                                    {{ layerExpose.policynumber }}
                                </td>
                                <td mat-footer-cell *matFooterCellDef>Total</td>
                            </ng-container>
                            <ng-container matColumnDef="baseValue">
                                <th mat-header-cell *matHeaderCellDef>
                                    Base Value
                                </th>
                                <td mat-cell *matCellDef="let layerExpose" class="text-left">
                                    {{ layerExpose.baseValue | commaFormat }}
                                </td>
                                <td mat-footer-cell *matFooterCellDef>
                                    {{
                                    layerExposeSummary?.totalSumOfBaseValues ||
                                    0 | commaFormat
                                    }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="layerValue">
                                <th mat-header-cell *matHeaderCellDef>Limit</th>
                                <td mat-cell *matCellDef="let layerExpose" class="text-left">
                                    {{ layerExpose.layerLimit | commaFormat }}
                                </td>
                                <td mat-footer-cell *matFooterCellDef></td>
                            </ng-container>
                            <ng-container matColumnDef="attachmentPoint">
                                <th mat-header-cell *matHeaderCellDef>
                                    Attachment Point
                                </th>
                                <td mat-cell *matCellDef="let layerExpose" class="text-left">
                                    {{
                                    layerExpose.attachmentPoint
                                    | commaFormat
                                    }}
                                </td>
                                <td mat-footer-cell *matFooterCellDef></td>
                            </ng-container>
                            <ng-container matColumnDef="participation">
                                <th mat-header-cell *matHeaderCellDef> Participation % </th>
                                <td mat-cell *matCellDef="let layerExpose" class="text-left"> {{ layerExpose.participation | commaFormat }} </td>
                                <td mat-footer-cell *matFooterCellDef></td>
                            </ng-container>
                            <ng-container matColumnDef="exposedValue">
                                <th mat-header-cell *matHeaderCellDef>
                                    Layer Exposed Value
                                </th>
                                <td mat-cell *matCellDef="let layerExpose" class="text-left">
                                    {{ layerExpose.exposedValue | commaFormat }}
                                </td>
                                <td mat-footer-cell *matFooterCellDef>
                                    {{
                                    layerExposeSummary?.totalSumOfLayerExposure ||
                                    0 | commaFormat
                                    }}
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="
                                    displayedColumns;
                                    sticky: true
                                "></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                            <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
                        </table>
                    </div>
                </div>

                <div *ngIf="reportSubType === ReportDisplayType.BAR_CHART">
                    <canvas mdbChart chartType="bar" [datasets]="chartDatasets" [labels]="chartLabels"
                        [options]="chartOptions" [colors]="chartColors" [legend]="false">
                    </canvas>
                </div>

                <div *ngIf="reportSubType === ReportDisplayType.PIE_CHART">
                    <canvas mdbChart chartType="pie" [datasets]="chartDatasets" [labels]="chartLabels"
                        [options]="chartOptions" [colors]="chartColors" [legend]="false">
                    </canvas>
                </div>
            </div>
            <div class="report-title-container">
                <input class="report-bottom-title" placeholder="Layer Exposed" [(ngModel)]="reportName">
            </div>
        </div>
    </div>
</div>
</ng-template>

<ng-template #noShowMessageTemplate>
    <p>No data available to display.</p>
</ng-template>

