<div class="accumulation-component">
    <div class="accumulations-header">
        <button [ngClass]="{
            'active-tab': activeTab === accumulationsTab.CLUSTER,
          }" class="accumulations-header-button" (click)="changeActiveTab(accumulationsTab.CLUSTER)">
            Cluster
        </button>
        <button [ngClass]="{
            'active-tab': activeTab === accumulationsTab.HEATMAP,
          }" class="accumulations-header-button" (click)="changeActiveTab(accumulationsTab.HEATMAP)">
            Heatmap
        </button>
        <button [ngClass]="{
            'active-tab': activeTab === accumulationsTab.THEMATIC_MAP
          }" class="accumulations-header-button" (click)="changeActiveTab(accumulationsTab.THEMATIC_MAP)">
            Thematic Map
        </button>
        <button *ngIf="showLayer" [ngClass]="{
            'active-tab': activeTab === accumulationsTab.LAYER_EXPOSE,
          }" class="accumulations-header-button" (click)="changeActiveTab(accumulationsTab.LAYER_EXPOSE)">
            Layer Exposed
        </button>
    </div>

    <div class="accumulations-content">
        <div [ngSwitch]="activeTab">
            <div *ngSwitchCase="accumulationsTab.CLUSTER">
                <map-datapoints-cluster-panel [dataset]="dataset" [initSettings]="initSettings"></map-datapoints-cluster-panel>
            </div>
            <div *ngSwitchCase="accumulationsTab.HEATMAP">
                <map-heatmap [dataset]="dataset" [dataSource]="dataSource" [treeControl]="treeControl" [heatMapTreeStructure]="heatMapTreeStructure"></map-heatmap>
            </div>
            <div *ngSwitchCase="accumulationsTab.THEMATIC_MAP">
                <map-thematic-map (showTMAnalytics)="onShowTMAnalyticsChange($event)" [dataset]="dataset" [formulas]="formulas" [nriFields]="nriFields" [climateOverlays]="climateOverlays"></map-thematic-map>
            </div>
            <div *ngSwitchCase="accumulationsTab.LAYER_EXPOSE">
                <map-layer-expose-accumulation></map-layer-expose-accumulation>
            </div>
            <div *ngSwitchDefault></div>
        </div>
    </div>


    <div class="panel-container" [class.hidden]="getThematicMapStatus()">
        <div class="panel-header" style="flex-direction: column; padding:0px; margin-top: 20px; white-space: nowrap;">
            <div class="analytics-button-group">
                <span (click)="addCountReport()" class="analytic-header-button">Add Count</span>
                <span (click)="addAggregateReport()" class="analytic-header-button">Add Aggregate</span>
                <span (click)="addRankingReport()" *ngIf="!isComparisonModeActivated" class="analytic-header-button">Add Ranking</span>
                <div class="comparison-toggle-button" matTooltipPosition="above" [matTooltip]="getTooltipForComparisonToggle()">
                    <mat-slide-toggle color="accent" [checked]="isComparisonModeActivated" (change)="changeComparisonMode()"></mat-slide-toggle>
                </div>
                <span *ngIf="reportItems?.length > 0 && !isDashboardCall" (click)="downloadReports()"
                    class="analytics-download-btn analytic-header-button">
                    <!-- download-arrow-icon -->
                    <svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <g fill="none" fill-rule="evenodd">
                            <path stroke="#002d57" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
                                d="M15.557 11.11 12 14.669l-3.557-3.557M12 3.997v10.671M20.003 16.446h0a3.557 3.557 0 0 1-3.557 3.557H7.554a3.557 3.557 0 0 1-3.557-3.557h0" />
                            <path d="M-.005-.005h24.01v24.01H-.005z" />
                        </g>
                    </svg>
                </span>
            </div>
        </div>

    <div class="reports-container" style="margin-top: 10px;" >
        <div *ngFor="let report of reportItems; let i = index">
            <div *ngIf="report.type === ReportType.COUNT" class="report">
                <div class="report-container">
                    <map-count-report #countReportComponent [fromAnalytics]="false" [dataset]="dataset" [nriFields]="_nriFields"
                        [uuid]="report.id" [climateOverlays]="_climateOverlays"
                        [dashboardWorkspaceItems]="dashboardWorkspaceItems" (closed)="removeReportItem(i)"
                        (saveWorkspaceItemToDashboard)="
                            saveWorkspaceItemForDashboard(i)
                        " (saveAsWorkspaceItemToDashboard)="
                            saveAsWorkspaceItemForDashboard(i)
                        " (saveSorting)="saveSorting(report)" [isDashboardCall]="isDashboardCall"
                        style="width: 100%" [isComparisonModeActivated]="isComparisonModeActivated" [groups]="groups" [groupsStrcuture]="groupsStrcuture">
                    </map-count-report>
                </div>
            </div>
            <div *ngIf="report.type === ReportType.AGGREGATE" class="report">
                <div class="report-container">
                    <map-aggregate-report #aggregateReportComponent [fromAnalytics]="false" [dataset]="dataset" [nriFields]="_nriFields"
                        [uuid]="report.id" [formulas]="formulas" [dashboardWorkspaceItems]="dashboardWorkspaceItems"
                        style="width: 100%" (closed)="removeReportItem(i)" (saveWorkspaceItemToDashboard)="
                            saveWorkspaceItemForDashboard(i)
                        " (saveAsWorkspaceItemToDashboard)="
                            saveAsWorkspaceItemForDashboard(i)
                        " (saveSorting)="saveSorting(report)" [isDashboardCall]="isDashboardCall"
                        [climateOverlays]="_climateOverlays" [isComparisonModeActivated]="isComparisonModeActivated" [groups]="groups" [groupsStrcuture]="groupsStrcuture">
                    </map-aggregate-report>
                </div>
            </div>
            <div class="report" *ngIf="report.type === ReportType.RANKING">
                <div class="report-container">
                    <map-ranking-report #rankingReportComponent [fromAnalytics]="false" [dataset]="dataset" [nriFields]="_nriFields"
                        [uuid]="report.id" [formulas]="formulas" [dashboardWorkspaceItems]="dashboardWorkspaceItems"
                        style="width: 100%" (closed)="removeReportItem(i)" (saveWorkspaceItemToDashboard)="
                            saveWorkspaceItemForDashboard(i)
                        " (saveAsWorkspaceItemToDashboard)="
                            saveAsWorkspaceItemForDashboard(i)
                        " (saveSorting)="saveSorting(report)" [isDashboardCall]="isDashboardCall"
                        [isComparisonModeActivated]="isComparisonModeActivated" [groups]="groups" [groupsStrcuture]="groupsStrcuture">
                    </map-ranking-report>
                </div>
            </div>
        </div>
    </div>
    </div>

</div>