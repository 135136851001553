import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {DatasetAnalyticsCount} from '../../model/analytics/dataset-analytics-count';
import {DatapointFilter} from '../../model/datapoint/filter/datapoint-filter';
import {map} from 'rxjs/operators';
import {DatasetFieldStatistics} from '../../model/analytics/dataset-field-statistics';
import {DatapointProjection} from '../../model/datapoint/projection/datapoint-projection';
import {ReportResponse} from '../../model/datapoint/report/report-response';
import {ReportRequest} from '../../model/datapoint/report/report-request';
import {AggregateMinMax} from '../../model/datapoint/report/aggregate-min-max';
import { Dataset } from 'src/app/model/dataset/dataset';
import { DatasetField } from 'src/app/model/dataset/field/dataset-field';

@Injectable({
    providedIn: 'root'
})
export class DatapointsAggregateService {
    constructor(private readonly http: HttpClient) {
    }

    /**
     * If needed this method works in backend with multiple fields.
     */
    getDatapointsFieldStatistics(datasetID: string, fieldID: string, filter: DatapointFilter): Observable<DatasetFieldStatistics> {
        let stringFilter = JSON.stringify(filter);
        let fieldIDs = JSON.stringify([fieldID]);
        return this.http.get<DatasetFieldStatistics[]>(`/datapoints/aggregate/dataset/${datasetID}/fields?filter=${stringFilter}&fieldIDs=${fieldIDs}`)
            .pipe(map(response => response[0]));
    }

    getDatapointsFieldStatisticsForDI(datasetID: string, fieldID: string, filter: DatapointFilter): Observable<DatasetFieldStatistics> {
        let stringFilter = JSON.stringify(filter);
        let fieldIDs = JSON.stringify([fieldID]);
        return this.http.get<DatasetFieldStatistics[]>(`/datapoints/aggregate/dataset/${datasetID}/di-fields?filter=${stringFilter}&fieldIDs=${fieldIDs}`)
            .pipe(map(response => response[0]));
    }

    getDatapointsCount(datasetID: string, filter: DatapointFilter, projection:DatapointProjection): Observable<DatasetAnalyticsCount> {
        let stringFilter = JSON.stringify(filter);
        let stringProjection = JSON.stringify(projection);
        return this.http.get<DatasetAnalyticsCount>(`/datapoints/aggregate/dataset/${datasetID}/count?filter=${stringFilter}&projection=${stringProjection}`);
    }

    getDatapointsReport(datasetID: string, filter: DatapointFilter, report: ReportRequest, projection: DatapointProjection): Observable<ReportResponse> {
        let stringFilter = encodeURIComponent(JSON.stringify(filter));
        let stringReport = encodeURIComponent(JSON.stringify(report));
        let stringProjection = encodeURIComponent(JSON.stringify(projection));
        let limit = 2500;
        let skip = 0;
        return this.http.get<ReportResponse>(`/datapoints/aggregate/dataset/${datasetID}/report?filter=${stringFilter}&report=${stringReport}&limit=${limit}&skip=${skip}&projection=${stringProjection}`);
    }

    getTableViewDatapointsReport(datasetID: string, filter: DatapointFilter, report: ReportRequest, projection: DatapointProjection, limit?: number, skip?: number): Observable<ReportResponse> {
        let stringFilter = encodeURIComponent(JSON.stringify(filter));
        let stringReport = encodeURIComponent(JSON.stringify(report));
        let stringProjection = encodeURIComponent(JSON.stringify(projection));
        return this.http.get<ReportResponse>(`/datapoints/aggregate/dataset/${datasetID}/report?filter=${stringFilter}&report=${stringReport}&limit=${limit}&skip=${skip}&projection=${stringProjection}`);
    }

    getDatapointsAggregateMinMaxForLinkedDatasets(pointDatasetId: string, complexDatasetId: string, grouping: string, aggregateFieldId: string, formula: string, filter: DatapointFilter): Observable<AggregateMinMax> {
        let jsonFilter = JSON.stringify(filter);
        let params = new HttpParams()
            .set('grouping', grouping)
            .set('filter', jsonFilter);
        if (aggregateFieldId) {
            params = params.set('aggregateFieldId', aggregateFieldId);
        } else {
            params = params.set('aggregateFormula', formula);
        }

        return this.http.get<AggregateMinMax>(`/datapoints/aggregate/tail-dataset/${pointDatasetId}/head-dataset/${complexDatasetId}/min-max`, {params: params});
    }

    async prepareDatapointsFieldStatistics(datasetFields: DatasetField[], dataset: Dataset, groups: number[]): Promise<DatasetFieldStatistics[]> {
        let stringFilter = JSON.stringify({
            datasetID: dataset.id,
            groups: groups
        });
        let promises = [];
        datasetFields.forEach(datasetField => {
            let fieldIDs = JSON.stringify([datasetField.id]);
            promises.push(this.http.get<DatasetFieldStatistics[]>(`/datapoints/aggregate/dataset/${dataset.id}/fields?filter=${stringFilter}&fieldIDs=${fieldIDs}`).toPromise());
        })
        try {
            const results = await Promise.all(promises);
            // Handle the results here
            return  [].concat(...results);
          } catch (error) {
            // Handle errors
            console.error(error);
          }
    }

    getComparisonAnalyticsData(datasetID: string, filter: DatapointFilter, report: ReportRequest, projection: DatapointProjection): Observable<ReportResponse> {
        let stringFilter = encodeURIComponent(JSON.stringify(filter));
        let stringReport = encodeURIComponent(JSON.stringify(report));
        let stringProjection = encodeURIComponent(JSON.stringify(projection));
        let limit = 2500;
        let skip = 0;
        return this.http.get<ReportResponse>(`/datapoints/aggregate/dataset/${datasetID}/comparison-report?filter=${stringFilter}&report=${stringReport}&limit=${limit}&skip=${skip}&projection=${stringProjection}`);
    }
}
