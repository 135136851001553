import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AuthService } from "src/app/auth/auth.service";
import { UserStateService } from "src/app/auth/user-state-service";

@Component({
    selector: "settings-header-breadcrumbs",
    templateUrl: "./settings-header-breadcrumbs.component.html",
    styleUrls: ["./settings-header-breadcrumbs.component.scss"],
})
export class SettingsHeaderBreadcrumbsComponent implements OnInit {
    // @Input () accountSettingsBreadCrumbs = [];
    @Input() componentName: string;
    @Input() breadCrumbs = [];
    accountLinks = [
        {
            path: "fields",
            name: "Fields",
            component: "AccountFieldsComponent",
            active: false,
            breadCrumbs: ["Home", "Accounts", "Settings"],
        },
        {
            path: "groups",
            name: "Groups",
            component: "AccountGroupsComponent",
            active: false,
            breadCrumbs: ["Home", "Accounts", "Settings"],
        },
        {
            path: "members",
            name: "Members",
            component: "AccountMembersComponent",
            active: false,
            breadCrumbs: ["Home", "Accounts", "Settings"],
        },
        {
            path: "global-overlays",
            name: "Global Overlays",
            component: "AccountOverlaysComponent",
            active: false,
            breadCrumbs: ["Home", "Accounts", "Settings"],
        },
    ];
    accountId: number;

    constructor(
        readonly userStateService: UserStateService,
        private readonly route: ActivatedRoute,
        private readonly auth: AuthService
    ) {}

    ngOnInit() {
        const principalUser = this.auth.getPrincipal();
        const currentRoute = this.route.snapshot.url.map(segment => segment.path).join('/');
        const isCustomScoringRoute = currentRoute.includes("custom-scoring");

        if (principalUser && !isCustomScoringRoute) {
            this.userStateService.initialize(principalUser);
        }
        this.accountId = +this.route.snapshot.paramMap.get("accountId");
        // pushed object for tessadata is logged in user is superadmin ONLY
        //  do private overlays visible for superadmin only
        if (this.userStateService.isSuperadmin) {
            this.accountLinks.push({
                path: "private-overlays",
                name: "Private Overlays",
                component: "AccountPrivateOverlaysComponent",
                active: false,
                breadCrumbs: ["Home", "Accounts", "Settings"],
            });
            this.accountLinks.push({
                path: "external-overlays",
                name: "Tessadata Dataset",
                component: "AccountExternalOverlaysComponent",
                active: false,
                breadCrumbs: ["Home", "Accounts", "Settings"],
            });
        }

        if (
            this.auth.can("update", this.accountId) ||
            this.userStateService.isSuperadmin
        ) {
            this.accountLinks.push({
                path: "api-meter",
                name: "API usage",
                component: "AccountApiMeteringComponent",
                active: false,
                breadCrumbs: ["Home", "Accounts", "Settings"],
            });
        }

        if (
            this.accountId &&
            (this.auth.can("update", this.accountId) ||
            this.userStateService.isSuperadmin)
        ) {
            this.accountLinks.push({
                path: "custom-scoring",
                name: "Custom Scoring",
                component: "CustomScoringComponent",
                active: false,
                breadCrumbs: ["Home", "Accounts", "Settings"],
            });
        }

        this.userStateService.userStateSubject.subscribe((isReady) => {
            if (isReady) {
                if (
                    this.accountId &&
                    (this.auth.can("update", this.accountId) || this.userStateService.isSuperadmin)
                ) {
                    this.userStateService.isEnableLayerExposedMenu(this.accountId).subscribe((isEnabled) => {
                        if (isEnabled) {
                            const exists = this.accountLinks.some(link => link.path === "layer-exposed");
                            if (!exists) {
                                this.accountLinks.push({
                                    path: "layer-exposed",
                                    name: "Layer Exposed",
                                    component: "LayerExposedComponent",
                                    active: false,
                                    breadCrumbs: ["Home", "Accounts", "Settings"],
                                });
                            }
                        }
                    });
                    
                }
            }
        });
        
    }
}
