import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from "@angular/core";
import {
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from "@angular/forms";
import { AccountService } from "../../data-access-layer/account/account.service";
import { Account, CreateAccountRequest } from "../../model/account/account";
import { UserStateService } from "../../auth/user-state-service";
import { AuthService } from "../../auth/auth.service";
import { Constants } from "../../constants";
import { Subject } from "rxjs";
import { Group } from "../../model/group/group";
import { NotifService } from "../../core/notification/notif.service";
import {
    datasetConfig,
    DatasetsArray,
} from "../account-panel/account-panel.constants";

@Component({
    selector: "map-edit-account",
    templateUrl: "./edit-account.component.html",
    styleUrls: ["./edit-account.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditAccountComponent implements OnInit {
    @Input() account: Account;
    @Input() emitAccountOnEditSubject: Subject<Account>;
    @Output()
    Update: EventEmitter<string> = new EventEmitter<string>();
    datasets = datasetConfig;
    datasetLinkMap = {};
    editAccountForm: UntypedFormGroup;
    loading = false;
    selectedValue: boolean = false;

    constructor(
        private readonly formBuilder: UntypedFormBuilder,
        private readonly accountService: AccountService,
        private readonly userStateService: UserStateService,
        private readonly auth: AuthService,
        private readonly notifService: NotifService,
        private readonly changeDetector: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.editAccountForm = new UntypedFormGroup({
            id: new UntypedFormControl(null),
            name: new UntypedFormControl(null, [
                Validators.required,
                Validators.maxLength(Constants.ACCOUNT_NAME_LENGTH),
                Validators.pattern("^[A-Za-z0-9]+( [A-Za-z0-9]+)*$"),
            ]),
            checkboxes: this.formBuilder.group({}),
            atLeastOneDatasetSelected: new UntypedFormControl(
                true,
                Validators.requiredTrue
            ),
        });
        this.emitAccountOnEditSubject.subscribe((account) => {
            this.selectedValue = false;
            let accCheckboxes = this.editAccountForm.controls.checkboxes.value;
            for (const dataset in accCheckboxes) {
                let isExist = account?.datasets.some(
                    (accountDataset) => accountDataset.name.toLowerCase() === dataset.toLowerCase()
                );
                if(isExist) {
                    accCheckboxes[dataset] = true;
                } else {
                    accCheckboxes[dataset] = false;
                }
            }

            this.editAccountForm.patchValue({
                id: account.id,
                name: account.name,
                checkboxes: accCheckboxes,
            });
        });
        const checkboxes = this.editAccountForm.get(
            "checkboxes"
        ) as UntypedFormGroup;
        let datasets = datasetConfig.map((dataset) => {
            return { title: dataset.text };
        });
        datasets.forEach((option: any) => {
            checkboxes.addControl(option.title, new UntypedFormControl(true));
        });
        checkboxes.valueChanges.subscribe((v) => {
            if (!v["locations"]) {
                checkboxes
                    .get("locations")
                    .setValue(true, { emitEvent: false });
            }
            this.changeDetector.detectChanges();
        });
    }

    submitAccount() {
        if (this.editAccountForm.valid) {
            let { name } = this.editAccountForm.getRawValue();
            this.account.name = name;

            this.accountService
                .updateAccount(this.account.id, this.constructCreateAccountRequest())
                .subscribe(
                    (res) => {
                        this.userStateService
                        .initialize(this.auth.principal)
                        .then(() => {
                            this.notifService.success(
                                "Successfully updated account"
                            );
                            this.Update.emit();
                            this.changeDetector.detectChanges();
                        });
                    },
                    (err) => {
                        this.Update.emit("Error: Not updated;");
                        this.notifService.success(
                            "Account with this username already exists."
                        );
                    }
                );
        }
    }

    private constructCreateAccountRequest(): CreateAccountRequest {
        const request: CreateAccountRequest = {
            name: this.editAccountForm.controls.name.value,
            links: [],
            datasets: [],
        };
        this.getSelectedDatasets().forEach((datasetName) => {
            let datasetRequest = DatasetsArray.find(
                (createDatasetRequest) =>
                    createDatasetRequest.application === datasetName
            );
            if (datasetRequest) {
                request.datasets.push(datasetRequest);
            }
        });
        return request;
    }

    getSelectedDatasets(): string[] {
        let checkboxes = this.editAccountForm.controls.checkboxes.value;
        let datasetNames = [];
        for (const dataset in checkboxes) {
            if (checkboxes.hasOwnProperty(dataset) && checkboxes[dataset]) {
                datasetNames.push(dataset.toUpperCase());
            }
        }
        return datasetNames;
    }

    isChecked(text: string): boolean {
        return this.account?.datasets.some(
            (accountDataset) => accountDataset.name.toLowerCase() === text.toLowerCase()
        );
    }

}
