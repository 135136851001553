import {DownloadReportItem} from './item/download-report-item';
import {DownloadItemReportType} from './item/download-item-report-type';
import {DownloadReportChartColumnsRequest} from './download-report-chart-columns-request';
import {DownloadReportChartValueRequest} from './download-report-chart-value-request';

export class DownloadReportChartRequest implements DownloadReportItem {
    valueKey: ValueKey;
    description?: string;
    columns: DownloadReportChartColumnsRequest;
    values: DownloadReportChartValueRequest[];
    title?: string;
    type: DownloadItemReportType;
}

export enum ValueKey {
    VALUE = 'VALUE',
    COUNT = 'COUNT',
    AVERAGE = 'AVERAGE',
    EXPOSE = 'EXPOSE'
}
