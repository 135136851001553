import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { IndicatorField } from "src/app/model/dashboard/indicators-data";
import { DatasetFieldFloatingOption } from "src/app/model/dataset/dataset-field-floating-option";

@Component({
    selector: "map-indicators-edit-dialog",
    templateUrl: "./indicators-edit-dialog.component.html",
    styleUrls: ["./indicators-edit-dialog.component.scss"],
})
export class IndicatorsEditDialog {
   // readonly TENSORFLIGHT_KEYWORD = 'tensorflight';
    readonly MUNICHRE_KEYWORD = 'munichre_';
    title: string;
    cancelButton: string;
    confirmButton: string;
    displayedColumns: string[];
    dataSource: MatTableDataSource<any>;
    indicators: any[];

    get indicatorOptions() {
        return Object.keys(DatasetFieldFloatingOption);
    }

    constructor(
        public dialogRef: MatDialogRef<IndicatorsEditDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.title = 'Manage Indicators';
        this.cancelButton = "Cancel";
        this.confirmButton = "Save";
        this.indicators = data.indicators;
        this.dataSource = new MatTableDataSource<any>(
        this.indicators.filter(indicator => !indicator.id.includes(this.MUNICHRE_KEYWORD))
        );

        this.displayedColumns = [
            "id",
            "name",
            "isIndicators",
            "indicatorOptions",
        ];
    }

    setIndicatorOption(field: IndicatorField) {
        if (field.isIndicators) {
            field.indicatorsOption = DatasetFieldFloatingOption.SUM;
        } else {
            field.indicatorsOption = null;
        }
    }

    onConfirm(): void {
        this.dialogRef.close(this.indicators);
    }

    onDismiss(): void {
        this.dialogRef.close(false);
    }
}
