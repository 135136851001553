import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Account, CreateAccountRequest } from '../../model/account/account';

@Injectable()
export class AccountService {

    constructor(
        private readonly http: HttpClient
    ) {
    }

    getMyAccounts(): Observable<Account[]> {
        return this.http.get<Account[]>(`/accounts`);
    }

    findAccount(accountID: number): Observable<Account> {
        return this.http.get<Account>(`/accounts/${ accountID }`);
    }

    createAccount(request: CreateAccountRequest): Observable<Account> {
        return this.http.post<Account>(`/accounts`, request);
    }

    updateAccount(accountID: any, request: CreateAccountRequest): Observable<Account> {
        return this.http.put<Account>(`/accounts/${ accountID }`, request);
    }

    deleteAccount(accountID: number): Observable<any> {
        return this.http.delete(`/accounts/${ accountID }`);
    }

    getAccount(id: number): Observable<Account> {
        return this.http.get<Account>(`/accounts/${ id }`);
    }

    updateAccountModules(accountID: number, request: any): Observable<any> {
        return this.http.put<any>(`/modules/account/${ accountID }`, request);
    }
    
    getModules(): Observable<any> {
        return this.http.get<any>(`/modules/all`);
    }

    getAssignModules(accountID: number): Observable<any> {
        return this.http.get<any>(`/modules/assign/${accountID}`);
    }

    getAccountDynamicIndicatorSetting(accountID: number): Observable<any> {
        return this.http.get<any>(`/di-settings/${accountID}/get`);
    }

    saveAccountDynamicIndicatorSetting(accountID: number, request): Observable<any> {
        return this.http.post<Account>(`/di-settings/${accountID}/save`, request);
    }
}
