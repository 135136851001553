import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { TessadataService } from '../../../data-access-layer/tessadata.service';
import { Point } from '../../../model/geometry/point';
import { TessadataLocationDetails } from '../../../core/tessadata/tessadata-location-details';
import { DownloadService } from '../../../data-access-layer/download/download.service';
import { AttachmentUtils } from '../../../core/utils/attachment-utils';
import { Observable, Subscription, forkJoin, of } from 'rxjs';
import { GoogleGeocodingService } from 'src/app/shared/services/google-geo-coder.service';
import { filter, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { TessadataDatasetStructure } from 'src/app/core/tessadata/tessadata-dataset-structure';
import { TessadataFieldsValues } from '../../../core/tessadata/tessadata-fields-values';
import { MapStateService } from '../../../map/map-state.service';
import { DataPointsServiceState } from '../../../shared/services/datapoints-service-state';
import {NotifService} from '../../../core/notification/notif.service';
import { EChartsOption } from 'echarts';
import { ClimateOverlayPermission, DatapointsClimateChartService } from '../climate-charts/datapoints-climate-chart.service';
import { EMPTY_PROFILE, ExternalDataFetchingAPIKeys, LocationProfile, MUNICHRE_ARRAY,/* TENSORFLIGHT_ARRAY, TENSORFLIGHT_POLYGON_DATA_KEY*/ } from 'src/app/model/datapoint/location-profile/location-profile';
import { AerisService } from 'src/app/data-access-layer/aeris.service';
import { AerisType } from 'src/app/core/aeris/AerisType';
import { Aeris } from 'src/app/core/utils/aeris';
import { AerisData } from '../datapoints-location-profile/location-profile/location-profile.component';
import { isNullOrUndefined, isUndefined } from "src/app/core/utils/util-master";
import { Climate } from 'src/app/core/utils/climate';
import { TessadataDataset } from 'src/app/core/tessadata/tessadata-dataset';
import { isEnabled } from 'src/environments/environment';
import { Functionalities } from 'src/environments/app-functionalities';
import { ComputationUtils } from 'src/app/core/utils/computation-utils';
import { DistanceUnit } from 'src/app/constants';
import { PoiClosestFilter } from 'src/app/model/datapoint/filter/poi-closest-filter';
import { MaptycsTheme } from '../climate-charts/custom-theme';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { DialogModel } from 'src/app/model/dialog/dialog-model';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DatasetFieldType } from 'src/app/model/dataset/dataset-field-type';
import { MinuchRe } from 'src/app/core/utils/minuch-re';
import { SidePanelService } from 'src/app/shared/services/side-panel.service';
import { TessadataNriFields } from 'src/app/core/tessadata/tessadata-nri-fields';
import { NRIUtils } from 'src/app/core/tessadata/nri-utils';

@Component({
    selector: 'map-address-location-profile',
    templateUrl: './address-location-profile.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./address-location-profile.component.scss',
        '../datapoints-location-profile/location-profile/location-profile.component.scss',
        '../datapoints-location-profile/datapoints-profile-panel.component.scss']
})
export class AddressLocationProfileComponent implements OnInit, OnDestroy {
    isExpanded = false;
    isLoading = false;
    requestFailed = false;
    location: Point;
    currentPinAddress: Observable<string>;
    locationDetails: TessadataLocationDetails;

    initMaxTempOption: EChartsOption;
    initMeanTempOption: EChartsOption;
    initMinTempOption: EChartsOption;
    initSnowfallOption: EChartsOption;
    initPrecipitationOption: EChartsOption;
    initSurfaceWindOption: EChartsOption;
    initPopulationDensityOption: EChartsOption;
    initSeaLevelRiseOption: EChartsOption;
    initSeaLevelRiseWarmingOption: EChartsOption;
    @Input() datasetID: any;
    _dropdownSelectedValue: string;

    locationProfile = new LocationProfile();
    selectedAddressPoint: Point;
    airQualityData: AerisData[] = [];
    meteorologicalConditionsData: AerisData[] = [];
    forecastData: AerisData[] = [];
    tropicalCyclonesData: AerisData[] = [];
    alertsData: AerisData[] = [];
    convectiveData: AerisData[] = [];
    droughtsData: AerisData[] = [];
    firesOutlookData: AerisData[] = [];
    earthquakesData: AerisData[] = [];
    firesData: AerisData[] = [];
    placesData: AerisData[] = [];
    riversData: AerisData[] = [];
    stormCellsData: AerisData[] = [];
    threatsData: AerisData[] = [];

    tessadataEnabled = isEnabled(Functionalities.TESSADATA_DATA);
    tessadataPOIDetails: TessadataFieldsValues[]; //each entry belongs to a new POI
    tessadataPOIDataset: TessadataDataset;
    tessadataPOIDistance: number = 100; //maybe extract this into constants
    tessadataPOIDistanceUnit: DistanceUnit = DistanceUnit.KM;
    tessadataPOISubmittedDistanceUnit: DistanceUnit = DistanceUnit.KM;
    tessadataPOILimit: number = 5; //maybe extract this into constant
    testdataInputParameters: {address: Point, tessadataPOIDatasets: TessadataDataset[], tessadataPOIDatasetsStructures: TessadataDatasetStructure[]} = {address: {x: 0, y: 0}, tessadataPOIDatasets: [], tessadataPOIDatasetsStructures: []};
    address: string = null;
    maptycsTheme = MaptycsTheme;
   // tensorflightFieldsMetaData: any[] = [];
    munichreFieldsMetaData: any[] = [];
    private readonly subscriptions: Subscription = new Subscription();

    hazardScores : any;
    riskScores = MinuchRe.RiskScoresElements();
    dataForSeeInfo: any;

    displayedColumns: string[] = ['name', 'none', 'low'];

    displayedRiskColumns: string[] = ['name', 'none', 'low'];
    EMPTY_PROFILE = EMPTY_PROFILE;
    activeProfile: string;

    summaryCard: NRICard;
    nriCards: NRICard[] = [];

    constructor(
        private readonly tessadataService: TessadataService,
        private cd: ChangeDetectorRef,
        private downloadService: DownloadService,
        public dataPointsServiceState: DataPointsServiceState,
        private geoCoderService: GoogleGeocodingService,
        private mapStateService: MapStateService,
        private notifService: NotifService,
        private datapointsClimateChartService: DatapointsClimateChartService,
        public readonly climateOverlayPermission: ClimateOverlayPermission,
        private aerisService: AerisService,
        public readonly dialog: MatDialog,
        private readonly route: ActivatedRoute,
        private readonly sidePanelService: SidePanelService,

    ) {
        this.currentPinAddress = this.mapStateService.getCurrentPinAddress();
        this.initMaxTempOption = datapointsClimateChartService.maxTemperatureService.options;
        this.initMeanTempOption = datapointsClimateChartService.meanTemperatureService.options;
        this.initMinTempOption = datapointsClimateChartService.minTemperatureService.options;
        this.initSnowfallOption = datapointsClimateChartService.snowfallService.options;
        this.initPrecipitationOption = datapointsClimateChartService.precipitationService.options;
        this.initSurfaceWindOption = datapointsClimateChartService.surfaceWindService.options;
        this.initPopulationDensityOption = datapointsClimateChartService.populationDensityService.options;
        this.initSeaLevelRiseOption = datapointsClimateChartService.seaLevelRiseService.options;
        this.initSeaLevelRiseWarmingOption = datapointsClimateChartService.seaLevelRiseWarmingService.options

        this.dataPointsServiceState.getActiveDropDownValueSubject().subscribe((value: string) => {
            this._dropdownSelectedValue = value;

            if(this._dropdownSelectedValue == this.locationProfile.CLIMATE){
                this.prepareClimateCharts(this.selectedAddressPoint);
            } else {
                Climate.resetGraphTypes(this.datapointsClimateChartService);
            }
            this.cd.detectChanges();
        })
    }

    @Input('location')
    set setLocationInput(data: {address: Point, externalPOIMenuItems: TessadataDataset[], externalPOIDatasetsStructures: TessadataDatasetStructure[]}) {
        this.setLocation(data);
    }

    get DistanceUnit() {
        return DistanceUnit;
    }

    onMaxTempChartInit(ec) {
        if(!isUndefined(ec)) {
            this.datapointsClimateChartService.chartGraphicsConfiguration.graphType.maxTemperature.instance = ec;
        }
        this.datapointsClimateChartService.maxTemperatureService.emitAddressChartEvent(ec);
        this.datapointsClimateChartService.showLoading(ec);
    }

    onMeanTempChartInit(ec) {
        if(!isUndefined(ec)) {
            this.datapointsClimateChartService.chartGraphicsConfiguration.graphType.meanTemperature.instance = ec;
        }
        this.datapointsClimateChartService.meanTemperatureService.emitAddressChartEvent(ec);
        this.datapointsClimateChartService.showLoading(ec);
    }

    onMinTempChartInit(ec) {
        if(!isUndefined(ec)) {
            this.datapointsClimateChartService.chartGraphicsConfiguration.graphType.minTemperature.instance = ec;
        }
        this.datapointsClimateChartService.minTemperatureService.emitAddressChartEvent(ec);
        this.datapointsClimateChartService.showLoading(ec);
    }

    onSnowfallChartInit(ec) {
        if(!isUndefined(ec)) {
            this.datapointsClimateChartService.chartGraphicsConfiguration.graphType.snowfallTemperature.instance = ec;
        }
        this.datapointsClimateChartService.snowfallService.emitAddressChartEvent(ec);
        this.datapointsClimateChartService.showLoading(ec);
    }

    onPrecipitationChartInit(ec) {
        if(!isUndefined(ec)) {
            this.datapointsClimateChartService.chartGraphicsConfiguration.graphType.precipitation.instance = ec;
        }
        this.datapointsClimateChartService.precipitationService.emitAddressChartEvent(ec);
        this.datapointsClimateChartService.showLoading(ec);
    }

    onSurfaceWindChartInit(ec) {
        if(!isUndefined(ec)) {
            this.datapointsClimateChartService.chartGraphicsConfiguration.graphType.surfaceWind.instance = ec;
        }
        this.datapointsClimateChartService.surfaceWindService.emitAddressChartEvent(ec);
        this.datapointsClimateChartService.showLoading(ec);
    }

    onPopulationDensityCharInit(ec) {
        if(!isUndefined(ec)) {
            this.datapointsClimateChartService.chartGraphicsConfiguration.graphType.populationDensity.instance = ec;
        }
        this.datapointsClimateChartService.populationDensityService.emitAddressChartEvent(ec);
        this.datapointsClimateChartService.showLoading(ec);
    }

    onSeaLevelRiseChartInit(ec) {
        if(!isUndefined(ec)) {
            this.datapointsClimateChartService.chartGraphicsConfiguration.graphType.seaLevelRise.instance = ec;
        }
        this.datapointsClimateChartService.seaLevelRiseService.emitAddressChartEvent(ec);
        this.datapointsClimateChartService.showLoading(ec);
    }

    onSeaLevelRiseWarmingChartInit(ec) {
        if(!isUndefined(ec)) {
            this.datapointsClimateChartService.chartGraphicsConfiguration.graphType.seaLevelRiseWarming.instance = ec;
        }
        this.datapointsClimateChartService.seaLevelRiseWarmingService.emitAddressChartEvent(ec);
        this.datapointsClimateChartService.showLoading(ec);
    }

    ngOnInit() {
        this.dataPointsServiceState.emitOnLocationProfileInit();
        this.currentPinAddress.subscribe(response => {
            this.address = response;
        });

        this.dataPointsServiceState.getExternalOverlaySelection().subscribe((selectedValue) => {
        /*    if(selectedValue == TENSORFLIGHT_ARRAY[0] && this.dataPointsServiceState.externalCallCount === 0) {
                this.dataPointsServiceState.externalCallCount = 1;
                this.tensorflightFieldsMetaData = [];
                this.cd.detectChanges();
                this.fetchTensorFlightAddressProfileData();
            } */

            if(selectedValue == MUNICHRE_ARRAY[0] && this.dataPointsServiceState.externalCallCount === 0) {
                this.dataPointsServiceState.externalCallCount = 1;
                this.fetchMunichReAddressProfileData();
            }
        })
    }

    setLocation(data: {address: Point, externalPOIMenuItems: TessadataDataset[], externalPOIDatasetsStructures: TessadataDatasetStructure[]}) {
        this.geoCoderService.reverseGeocoding(data.address)
            .subscribe(currentPinAddress => this.mapStateService.updateCurrentPinAddress(currentPinAddress));
        this.aerisFetch(data.address);
        this.testdataInputParameters = {address: data.address, tessadataPOIDatasets: data.externalPOIMenuItems, tessadataPOIDatasetsStructures: data.externalPOIDatasetsStructures};
        this.fetchClosestPOI();
        this.location = data.address;
        this.isLoading = true;
        this.cd.detectChanges();
        this._dropdownSelectedValue = this.locationProfile.EXTERNAL;
        this.selectedAddressPoint = data.address;
        this.prepareClimateCharts(data.address);
        const accountId = this.route.snapshot.paramMap.get("accountId");

        this.tessadataService
            .fetchLocationProfile(data.address.y, data.address.x, accountId)
            .pipe(
                tap(locationDetails => this.locationDetails = locationDetails),
                map(locationDetails => locationDetails.datasets.map(dataset => dataset.datasetId)),
                mergeMap(datasetIds => datasetIds.length ? this.tessadataService.fetchDatasetsStructures( this.datasetID , datasetIds) : of(null))
            )
            .subscribe((response: TessadataDatasetStructure[] | null) => {
                if (response) {
                    this.mapDatasetFieldLabels(response);
                }
                this.requestFailed = false;
                this.isLoading = false;
                setTimeout(() => {
                    if (this.locationDetails) {
                        this.prepareNRICards();
                    }
                }, 2000);
                this.cd.detectChanges();
            }, () => {
                this.requestFailed = true;
                this.isLoading = false;
                this.cd.detectChanges();
            });
    }

    private mapDatasetFieldLabels(labelDatasets: TessadataDatasetStructure[]) {
        this.locationDetails.datasets.forEach((dataset: TessadataFieldsValues) => {
            if (dataset.fields.length) {
                const labelDataset = labelDatasets.find(labelDataset => labelDataset.datasetId === dataset.datasetId);

                dataset.fields.forEach(field => {
                    const labelField = labelDataset.fields.find(labelField => labelField.fieldId === field.id);
                    if (!isNullOrUndefined(labelField)) {
                        field.label = labelField.fieldLabel;
                    }
                });
            }
        });
    }

    onExpandedClick(expanded?: boolean) {
        this.isExpanded = (expanded !== undefined) ? expanded : !this.isExpanded;

        if (!expanded) {
            this.dataPointsServiceState.emitOnAddressLocationProfileClose();
        }
    }

    downloadLocationProfile($event) {
        $event.preventDefault();
        if (!this.locationDetails || !this.currentPinAddress) {
            return;
        }
        let address: any;
        this.currentPinAddress.subscribe(response => {
            address = response;
        });

        let distanceInM = ComputationUtils.getDistanceInMeters(this.tessadataPOIDistance, this.tessadataPOIDistanceUnit);
        const poiClosestFilter: PoiClosestFilter = {
            latitude: this.testdataInputParameters.address.y,
            longitude: this.testdataInputParameters.address.x,
            externalDatasetId: this.tessadataPOIDataset.datasetId,
            distanceInMeters: distanceInM,
            distanceUnit: this.tessadataPOIDistanceUnit,
            limit: this.tessadataPOILimit
        };
        const filterIds = this.filterIds();
     //   filterIds.push(ExternalDataFetchingAPIKeys.TENSORFLIGHT)
        filterIds.push(ExternalDataFetchingAPIKeys.MUNICHRE)
        this.downloadService.downloadExternalDataForAddress(this.location, this.datasetID, address, filterIds, poiClosestFilter)
            .subscribe(response => AttachmentUtils.downloadFileWithName(response, 'Address Profile.xlsx'),
                error => this.notifService.error('Something went wrong during download'));
        // this.currentPinAddress.pipe(
        //     switchMap(address => this.downloadService.downloadExternalDataForAddress(this.location, address, this.filterIds())),
        //     tap(response => AttachmentUtils.downloadFileWithName(response, 'Address Profile.xlsx'))
        // ).subscribe();
    }

    filterIds() {
        return this.locationDetails.datasets.reduce(function(ids, obj){
            if(!['25', '26'].includes(obj.datasetId)){
                ids.push(obj.datasetId);
            }
            return ids;
        }, []);
    }

    onClickCoordinates() {
        this.notifService.success('Full Coordinates Copied');
        window.navigator.clipboard.writeText(this.location.y + ',' + this.location.x);
    }

    ngOnDestroy() {
        this.dataPointsServiceState.emitOnLocationProfileDestroyed();
        this.datapointsClimateChartService.unsubscribeAddressChartInstances();
    }

    avoidDatasets(datasetId) {
        return  !['25', '26'].includes(datasetId);
    }

    prepareClimateCharts(address: Point) {
        this.datapointsClimateChartService.subscribeAddressChartInstances(address, this.datasetID);
        this.datapointsClimateChartService.checkClimatePermissions(this.climateOverlayPermission, this.datasetID);
    }

    isExternalDatasets(datasetId) {
        return !['noharm','risk_index_tract'].includes(datasetId);
    }

    aerisFetch(address: Point) {
        this.aerisService.getAerisCredentials().subscribe((value:any) =>{
        let aerisCredentials = Aeris.decodeAerisCredentials(value.aerisClient, value.aerisSecret)

        this.aerisService.fetchLocationProfile(AerisType.CONDITIONS, aerisCredentials.aerisClient, aerisCredentials.aerisSecret, address.y, address.x).subscribe((value: any) => {
            const response = value.response;
            this.meteorologicalConditionsData = Aeris.computeAerisData(response, '', 0);
            this.meteorologicalConditionsData = this.meteorologicalConditionsData.filter(meteorologicalConditions => {
                return meteorologicalConditions.text !== "" && meteorologicalConditions.value !== "";
            })
        }, () => {
            this.meteorologicalConditionsData = [];
        });
        this.aerisService.fetchLocationProfile(AerisType.FORECASTS, aerisCredentials.aerisClient, aerisCredentials.aerisSecret, address.y, address.x).subscribe((value: any) => {
            const response = value.response;
            this.forecastData = Aeris.computeAerisData(response, '', 0);
            this.forecastData = this.forecastData.filter(forecast => {
                return forecast.text !== "" && forecast.value !== "";
            })
        }, () => {
            this.forecastData = [];
        });
        this.aerisService.fetchLocationProfile(AerisType.AIR_QUALITY, aerisCredentials.aerisClient, aerisCredentials.aerisSecret, address.y, address.x).subscribe((value: any) => {
            const response = value.response;
            this.airQualityData = Aeris.computeAerisData(response, '', 0);
            this.airQualityData = this.airQualityData.filter(airQuality => {
                return airQuality.text !== "" && airQuality.value !== "";
            })
        }, () => {
            this.airQualityData = [];
        });
        this.aerisService.fetchLocationProfile(AerisType.TROPICALCYCLONES, aerisCredentials.aerisClient, aerisCredentials.aerisSecret, address.y, address.x).subscribe((value: any) => {
            const response = value.response;
            this.tropicalCyclonesData = Aeris.computeAerisData(response, '', 0);
            this.tropicalCyclonesData = this.tropicalCyclonesData.filter(tropicalCyclones => {
                return tropicalCyclones.text !== "" && tropicalCyclones.value !== "";
            })
        }, () => {
            this.tropicalCyclonesData = [];
        });
        this.aerisService.fetchLocationProfile(AerisType.ALERTS, aerisCredentials.aerisClient, aerisCredentials.aerisSecret, address.y, address.x).subscribe((value: any) => {
            const response = value.response;
            this.alertsData = Aeris.computeAerisData(response, '', 0);
            this.alertsData = this.alertsData.filter(alerts => {
                return alerts.text !== "" && alerts.value !== "";
            })
        }, () => {
            this.alertsData = [];
        });
        this.aerisService.fetchLocationProfile(AerisType.CONVECTIVE, aerisCredentials.aerisClient, aerisCredentials.aerisSecret, address.y, address.x).subscribe((value: any) => {
            const response = value.response;
            this.convectiveData = Aeris.computeAerisData(response, '', 0);
            this.convectiveData = this.convectiveData.filter(convective => {
                return convective.text !== "" && convective.value !== "";
            })
        }, () => {
            this.convectiveData = [];
        });
        this.aerisService.fetchLocationProfile(AerisType.DROUGHTS, aerisCredentials.aerisClient, aerisCredentials.aerisSecret, address.y, address.x).subscribe((value: any) => {
            const response = value.response;
            this.droughtsData = Aeris.computeAerisData(response, '', 0);
            this.droughtsData = this.droughtsData.filter(droughts => {
                return droughts.text !== "" && droughts.value !== "";
            })
        }, () => {
            this.droughtsData = [];
        });
        this.aerisService.fetchLocationProfile(AerisType.FIRES_OUTLOOk, aerisCredentials.aerisClient, aerisCredentials.aerisSecret, address.y, address.x).subscribe((value: any) => {
            const response = value.response;
            this.firesOutlookData = Aeris.computeAerisData(response, '', 0);
            this.firesOutlookData = this.firesOutlookData.filter(firesOutlook => {
                return firesOutlook.text !== "" && firesOutlook.value !== "";
            })
        }, () => {
            this.firesOutlookData = [];
        });
        this.aerisService.fetchLocationProfile(AerisType.FIRES, aerisCredentials.aerisClient, aerisCredentials.aerisSecret, address.y, address.x).subscribe((value: any) => {
            const response = value.response;
            this.firesData = Aeris.computeAerisData(response, '', 0);
            this.firesData = this.firesData.filter(fires => {
                return fires.text !== "" && fires.value !== "";
            })
        }, () => {
            this.firesData = [];
        });
        this.aerisService.fetchLocationProfile(AerisType.PLACES, aerisCredentials.aerisClient, aerisCredentials.aerisSecret, address.y, address.x).subscribe((value: any) => {
            const response = value.response;
            this.placesData = Aeris.computeAerisData(response, '', 0);
            this.placesData = this.placesData.filter(places => {
                return places.text !== "" && places.value !== "";
            })
        }, () => {
            this.placesData = [];
        });
        this.aerisService.fetchLocationProfile(AerisType.RIVERS, aerisCredentials.aerisClient, aerisCredentials.aerisSecret, address.y, address.x).subscribe((value: any) => {
            const response = value.response;
            this.riversData = Aeris.computeAerisData(response, '', 0);
            this.riversData = this.riversData.filter(rivers => {
                return rivers.text !== "" && rivers.value !== "";
            })
        }, () => {
            this.riversData = [];
        });
        this.aerisService.fetchLocationProfile(AerisType.STORMCELLS, aerisCredentials.aerisClient, aerisCredentials.aerisSecret, address.y, address.x).subscribe((value: any) => {
            const response = value.response;
            this.stormCellsData = Aeris.computeAerisData(response, '', 0);
            this.stormCellsData = this.stormCellsData.filter(stormCells => {
                return stormCells.text !== "" && stormCells.value !== "";
            })
        }, () => {
            this.stormCellsData = [];
        });
        this.aerisService.fetchLocationProfile(AerisType.THREATS, aerisCredentials.aerisClient, aerisCredentials.aerisSecret, address.y, address.x).subscribe((value: any) => {
            const response = value.response;
            this.threatsData = Aeris.computeAerisData(response, '', 0);
            this.threatsData = this.threatsData.filter(threats => {
                return threats.text !== "" && threats.value !== "";
            })
        }, () => {
            this.threatsData = [];
        });
    });
    }

    downloadAdressProfileChart() {
        const addressDetails = {
            address: this.address,
            yAxis: this.location.y.toFixed(6),
            xAxis: this.location.x.toFixed(6)
        }
        Climate.downloadOverlayProfileChart(this.datapointsClimateChartService, this.notifService, 'addressProfileClimateChart', null, addressDetails);
    }

    fetchClosestPOI(isSubmitCall: boolean = false) {
        if (this.tessadataEnabled) {
            if (!isSubmitCall) {
                this.tessadataPOIDataset = this.testdataInputParameters.tessadataPOIDatasets[0];
            }
            let distanceInM = ComputationUtils.getDistanceInMeters(this.tessadataPOIDistance, this.tessadataPOIDistanceUnit);
            this.tessadataPOISubmittedDistanceUnit = this.tessadataPOIDistanceUnit;
            const poiClosestFilter: PoiClosestFilter = {
                latitude: this.testdataInputParameters.address.y,
                longitude: this.testdataInputParameters.address.x,
                externalDatasetId: this.tessadataPOIDataset.datasetId,
                distanceInMeters: distanceInM,
                distanceUnit: this.tessadataPOIDistanceUnit,
                limit: this.tessadataPOILimit
            };
            //this.poiClosestSubmittedFilter.emit(poiClosestFilter);
            this.tessadataService.fetchClosestPOIDetails(poiClosestFilter).subscribe(
                locationDetails => {
                    this.tessadataPOIDetails = locationDetails.datasets;
                    if (this.tessadataPOIDetails.length) {
                        let tessadataDatasetStructure = this.testdataInputParameters.tessadataPOIDatasetsStructures.find(structure => structure.datasetId === this.tessadataPOIDetails[0].datasetId);
                        this.tessadataPOIDetails.forEach(poi => poi.fields.forEach(field => {
                            let tessadataField = tessadataDatasetStructure.fields.find(f => f.fieldId === field.id);
                            field.label = tessadataField.fieldLabel;
                            field.type = tessadataField.fieldType;
                            if (field.label === 'Distance') {
                                field.value = field.value ? String(ComputationUtils.getDistanceInUnit(Number(field.value), this.tessadataPOIDistanceUnit)) : '';
                            }
                        }));
                    }
                   this.cd.detectChanges();
                }
            );
        }
    }

 /*   fetchTensorFlightAddressProfileData() {
        const accountId = +this.route.snapshot.paramMap.get("accountId");

        const tensorflightStructureObservable = this.tessadataService.fetchDatasetsStructures(this.datasetID, [ExternalDataFetchingAPIKeys.TENSORFLIGHT]);
        const tensorflightAdddressProfileData = this.tessadataService.getLoggedData(this.dataPointsServiceState.activeSearchLocation.y, this.dataPointsServiceState.activeSearchLocation.x, ExternalDataFetchingAPIKeys.TENSORFLIGHT);

        forkJoin({
            tensorflightStructure: tensorflightStructureObservable,
            tensorflightData: tensorflightAdddressProfileData
        }).subscribe({
            next: (result) => {
                this.tensorflightFieldsMetaData = result?.tensorflightStructure[0]?.fields || [];
                const tensorflightData = result?.tensorflightData?.datasets[0] || null;

                if(!tensorflightData){
                    this.openConfirmationDialogForExternalDatasets(accountId, ExternalDataFetchingAPIKeys.TENSORFLIGHT)
                } else {
                    this.prepareDataTensorflightForAddressProfilePanel(tensorflightData, this.tensorflightFieldsMetaData);
                }

            },
            error: (err) => {
                console.error('Error:', err);
            }
        }); 


    } 

    prepareDataTensorflightForAddressProfilePanel(tensorflightData, tensorflightMetaFields) {
        const fields = tensorflightData.fields.filter((field) => field.id !== TENSORFLIGHT_POLYGON_DATA_KEY);
        this.dataPointsServiceState.tensorflightFieldsWithValues = this.combineFieldMetaDataAndFieldValue(fields, tensorflightMetaFields);
        const tensorflightAddressProfilePolygon = tensorflightData.fields.find((field) => field.id === TENSORFLIGHT_POLYGON_DATA_KEY);

        if(tensorflightAddressProfilePolygon.value){
            this.drawTensorflightPolgon(tensorflightAddressProfilePolygon.value);
        }

        this.activeProfile = 'Tensorflight';
        this.dataPointsServiceState.emitDropDownValue(this.EMPTY_PROFILE);
        this.cd.detectChanges();
        setTimeout(() => {
            this.dataPointsServiceState.emitDropDownValue(TENSORFLIGHT_ARRAY[0]);
            this.cd.detectChanges();
        }, 0);
    }
*/
    combineFieldMetaDataAndFieldValue(fields, metaFields): any[] {
        const transformedFields = fields.map((field) => {
            const metaField = metaFields.find((metaField) => metaField.fieldId === field.id) || {};
            return {
                ...metaField,
                ...field
            }
        })
        return transformedFields;
    }

    formatExternalDataFieldValue(field) {
        if(field.fieldType === DatasetFieldType.NUMERIC){
            const numericValue = parseFloat(field.value);
            const roundedValue = Math.round(numericValue * 100) / 100;
            return roundedValue.toFixed(2);
        }
        return field.value;
    }

    openConfirmationDialogForExternalDatasets(accountId, datasetId) {
        const dialogRef = this.dialog.open(DialogComponent, {
            data: new DialogModel(
                "Confirm Action",
                `Are you sure you want to fetch data?`
            ),
        });
        dialogRef
            .afterClosed()
            .pipe(take(1))
            .subscribe((dialogResult) => {
                if (dialogResult) {
                    this.tessadataService.fetchExternalLogData(accountId, this.dataPointsServiceState.activeSearchLocation.y, this.dataPointsServiceState.activeSearchLocation.x, datasetId, this.address).subscribe((response) => {
                        const data = response.datasets[0] || null;

                        this.cd.detectChanges();
                     //   if(data && datasetId == ExternalDataFetchingAPIKeys.TENSORFLIGHT){
                     //       this.prepareDataTensorflightForAddressProfilePanel(data, this.tensorflightFieldsMetaData);
                     //   }
                        if(data && datasetId == ExternalDataFetchingAPIKeys.MUNICHRE){
                            this.prepareMunichreDataForAddressProfilePanel(data, this.munichreFieldsMetaData);
                        }
                    });
                }
            });
    }

  //  drawTensorflightPolgon(tensorflightAddressProfilePolygon) {
    //    this.dataPointsServiceState.emitOnTensorflightAddressProfilePolygonFetch(tensorflightAddressProfilePolygon);
    //}

    isFieldValueValid(value) {
        if(value === null || value === undefined || value === "null"){
            return false
        }
        return true
    }

    fetchMunichReAddressProfileData() {
        this.dataPointsServiceState.dataSource = [];
        this.munichreFieldsMetaData = [];
        this.dataPointsServiceState.munichreFieldsWithValues = [];
        this.hazardScores = null;
        const accountId = +this.route.snapshot.paramMap.get("accountId");
        const munichreStructureObservable = this.tessadataService.fetchDatasetsStructures(this.datasetID, [ExternalDataFetchingAPIKeys.MUNICHRE]);
        const munichreAdddressProfileData = this.tessadataService.getLoggedData(this.dataPointsServiceState.activeSearchLocation.y, this.dataPointsServiceState.activeSearchLocation.x, ExternalDataFetchingAPIKeys.MUNICHRE);
        this.cd.detectChanges();
        forkJoin({
            munichreStructure: munichreStructureObservable,
            munichreData: munichreAdddressProfileData
        }).subscribe({
            next: (result) => {
                this.munichreFieldsMetaData = result?.munichreStructure[0]?.fields || [];
                const munichreData = result?.munichreData?.datasets[0] || null;

                if(!munichreData){
                    this.openConfirmationDialogForExternalDatasets(accountId, ExternalDataFetchingAPIKeys.MUNICHRE)
                } else {
                    this.prepareMunichreDataForAddressProfilePanel(munichreData, this.munichreFieldsMetaData)
                }

            },
            error: (err) => {
                console.error('Error:', err);
            }
        });
    }

    prepareMunichreDataForAddressProfilePanel(munichreData, munichreMetaFields) {
        this.hazardScores = MinuchRe.HazardScoresElements();
        const fields = munichreData.fields;
        this.dataPointsServiceState.munichreFieldsWithValues = this.combineFieldMetaDataAndFieldValue(fields, munichreMetaFields);;

        this.dataPointsServiceState.munichreFieldsWithValues.forEach(field =>{
            this.hazardScores.forEach(hazardScore => {
                if (hazardScore.id.replace('munichre_hazards_', '') === field.fieldId) {
                    hazardScore.values.forEach((item) => {
                        // Iterate over the keys of each object
                        Object.keys(item).forEach((key) => {
                            let value = item[key];
                            if(value.value == field.value || (field.value && value.value == parseInt(field.value).toFixed(4).replace(/\.0+$/,''))) {
                                value.isActive = true;
                                value.class = 'progress-bar active';
                                value.tooltip = value.tooltip + value.value;
                            } else {
                                value.tooltip = '';
                            }
                        });
                    });
                }
            });

        })
        this.dataPointsServiceState.dataSource = this.hazardScores;
        this.dataPointsServiceState.riskDataSource = this.riskScores;

        this.activeProfile = 'Munich Re';
        this.dataPointsServiceState.emitDropDownValue(this.EMPTY_PROFILE);
        this.cd.detectChanges();
        setTimeout(() => {
            this.dataPointsServiceState.emitDropDownValue(MUNICHRE_ARRAY[0]);
            this.cd.detectChanges();
        }, 0);
    }

    isMunicReDataPresent() {
        return this.dataPointsServiceState.munichreFieldsWithValues?.length && this.dataPointsServiceState.dataSource?.length && this.dataPointsServiceState.riskDataSource?.length;
    }

    getPointerPositionForSeeInfo(element:any){
        const field = this.dataPointsServiceState.munichreFieldsWithValues.find(field => field.fieldId === element.values[1].id.replace('munichre_hazards_', ''));
        if(field !== undefined){
            return MinuchRe.getPointerPosition(field.value);
        }
    }

    getTooltipTextForSeeInfo(element: any): string {
        const fieldRiskScore = this.dataPointsServiceState.munichreFieldsWithValues.find(field => field.fieldId === element.values[0].id.replace('munichre_hazards_', ''));
        const fieldRiskIndex = this.dataPointsServiceState.munichreFieldsWithValues.find(field => field.fieldId === element.values[1].id.replace('munichre_hazards_', ''));

        if (fieldRiskIndex !== undefined && fieldRiskScore !== undefined) {
            return MinuchRe.getTooltipText(fieldRiskIndex.value, fieldRiskScore.value);
        } else if (fieldRiskScore === undefined && fieldRiskIndex !== undefined) {
            return `Risk Score:  - Risk Index: ${fieldRiskIndex.value}`;
        } else if (fieldRiskScore !== undefined && fieldRiskIndex === undefined) {
            return `Risk Score: ${fieldRiskScore.value} - Risk Index: `;
        } else {
            const defaultRiskIndex = 0;
            const defaultRiskScore = "Low";
            return `Risk Score: ${defaultRiskScore} - Risk Index: ${defaultRiskIndex}`;
        }
    }

    downloadMunichReportForSeeInfo() {
        this.subscriptions.add(
            this.downloadService
                .downloadMunichReReportForSeeInfo(this.location.y, this.location.x, this.datasetID, this.address)
                .subscribe(
                    (response) => {
                        const url =  response.url;
                        window.location.href = response.url;
                    },
                    (error) =>
                        this.notifService.error(
                            "Something went wrong during download"
                        )
                )
        )

    }

    getTessadataSummaryFieldIds() {
        return [TessadataNriFields.SUMMARY_RISK_RATING, TessadataNriFields.SUMMARY_EAL_RATING, TessadataNriFields.SUMMARY_SOVI_RATING, TessadataNriFields.SUMMARY_RESL_RATING];
    }

    prepareNRICards() {
        this.summaryCard = this.createNRICard('Summary', 'summary.svg', this.getTessadataSummaryFieldIds(), false);
        this.nriCards.push(this.createNRICard('Avalanche', 'avalanche.svg', [TessadataNriFields.AVALANCHE_RSK, TessadataNriFields.AVALANCHE_EAL, TessadataNriFields.AVALANCHE_HLR]));
        this.nriCards.push(this.createNRICard('Coastal Flooding', 'coastalflooding.svg', [TessadataNriFields.COASTAL_FLOODING_RSK, TessadataNriFields.COASTAL_FLOODING_EAL, TessadataNriFields.COASTAL_FLOODING_HLR]));
        this.nriCards.push(this.createNRICard('Cold Wave', 'coldwave.svg', [TessadataNriFields.COLDWAVE_RSK, TessadataNriFields.COLDWAVE_EAL, TessadataNriFields.COLDWAVE_RSK]));
        this.nriCards.push(this.createNRICard('Drought', 'drought.svg', [TessadataNriFields.DROUGHT_RSK, TessadataNriFields.DROUGHT_EAL, TessadataNriFields.DROUGHT_HLR]));
        this.nriCards.push(this.createNRICard('Earthquake', 'earthquake.svg', [TessadataNriFields.EARTHQUAKE_RSK, TessadataNriFields.EARTHQUAKE_EAL, TessadataNriFields.EARTHQUAKE_HLR]));
        this.nriCards.push(this.createNRICard('Hail', 'hail.svg', [TessadataNriFields.HAIL_RSK, TessadataNriFields.HAIL_EAL, TessadataNriFields.HAIL_HLR]));
        this.nriCards.push(this.createNRICard('Heat Wave', 'heatwave.svg', [TessadataNriFields.HEATWAVE_RSK, TessadataNriFields.HEATWAVE_EAL, TessadataNriFields.HEATWAVE_HLR]));
        this.nriCards.push(this.createNRICard('Hurricane', 'tornado.svg', [TessadataNriFields.HURRICANE_RSK, TessadataNriFields.HURRICANE_EAL, TessadataNriFields.HURRICANE_HLR]));
        this.nriCards.push(this.createNRICard('Ice Storm', 'icestorm.svg', [TessadataNriFields.ICESTORM_RSK, TessadataNriFields.ICESTORM_EAL, TessadataNriFields.ICESTORM_HLR]));
        this.nriCards.push(this.createNRICard('Landslide', 'landslide.svg', [TessadataNriFields.LANDSLIDE_RSK, TessadataNriFields.LANDSLIDE_EAL, TessadataNriFields.LANDSLIDE_HLR]));
        this.nriCards.push(this.createNRICard('Lightning', 'lightning.svg', [TessadataNriFields.LIGHTNING_RSK, TessadataNriFields.LIGHTNING_EAL, TessadataNriFields.LIGHTNING_HLR]));
        this.nriCards.push(this.createNRICard('Riverine Flood', 'river.svg', [TessadataNriFields.RIVERINEFLOOD_RSK, TessadataNriFields.RIVERINEFLOOD_EAL, TessadataNriFields.RIVERINEFLOOD_HLR]));
        this.nriCards.push(this.createNRICard('Strong Wind', 'wind.svg', [TessadataNriFields.STRONGWIND_RSK, TessadataNriFields.STRONGWIND_EAL, TessadataNriFields.STRONGWIND_HLR]));
        this.nriCards.push(this.createNRICard('Tornado', 'tornado.svg', [TessadataNriFields.TORNADO_RSK, TessadataNriFields.TORNADO_EAL, TessadataNriFields.TORNADO_HLR]));
        this.nriCards.push(this.createNRICard('Tsunami', 'tsunami.svg', [TessadataNriFields.TSUNAMI_RSK, TessadataNriFields.TSUNAMI_EAL, TessadataNriFields.TSUNAMI_HLR]));
        this.nriCards.push(this.createNRICard('Volcanic Activity', 'volcanic.svg', [TessadataNriFields.VOLCANIC_RSK, TessadataNriFields.VOLCANIC_EAL, TessadataNriFields.VOLCANIC_HLR]));
        this.nriCards.push(this.createNRICard('Wildfire', 'wildfire.svg', [TessadataNriFields.WILDFIRE_RSK, TessadataNriFields.WILDFIRE_EAL, TessadataNriFields.WILDFIRE_HLR]));
        this.nriCards.push(this.createNRICard('Winter Weather', 'winter.svg', [TessadataNriFields.WINTER_RSK, TessadataNriFields.WINTER_EAL, TessadataNriFields.WINTER_HLR]));
    }

    createNRICard(title: string, icon: string, fieldIds: string[], removePrefixOfAttributes = true): NRICard {
        let properties: NRIKeyValue[] = [];
        let riskNumericalValues: number[] = [];
        const prefix_for_risk = 'risk_index_tract_';
        const riskIndexTract = this.locationDetails?.datasets.find(item => item.datasetId === 'risk_index_tract');
        const riskFields = riskIndexTract?.fields || [];
        fieldIds.forEach(id => {
            let newId = id;
            if (id.startsWith(prefix_for_risk)) {
                newId = id.slice(prefix_for_risk.length);
            }      
            let datasetField = riskFields.find(item => item.id === newId);
            let datapointField = datasetField
            if (!datapointField) {
                return;
            }
            let value: any = datapointField.value || datapointField.textValue || datapointField.numberValue || datapointField.datetimeValue;
            if (value) {
                riskNumericalValues.push(NRIUtils.getNumericRiskValue(value) || 10);
            }
            let fieldName = datasetField.label;
            let label = removePrefixOfAttributes ? fieldName.substring(fieldName.indexOf('- ') + 2) : fieldName;
            properties.push({ label: label, value: value });
        });
        let maxRisk = Math.min.apply(Math, riskNumericalValues);
        let warnSignColor = NRIUtils.riskValueColorsByNumbers[maxRisk];
        return { title: title, icon: icon, properties: properties, warnSignColor: warnSignColor, visible: maxRisk <= 5 };
    }

}


interface NRICard {
    title: string;
    icon: string;
    properties: NRIKeyValue[];
    warnSignColor: string;
    visible: boolean;
}

interface NRIKeyValue {
    label: string;
    value: string;
}
