import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnInit,
    Output,
    Input,
    ViewChild,
    ElementRef,
} from "@angular/core";
import {
    FormArray,
    FormBuilder,
    FormGroup,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from "@angular/forms";
import { AccountService } from "../../../data-access-layer/account/account.service";
import { NotifService } from "../../../core/notification/notif.service";
import { UserStateService } from "../../../auth/user-state-service";
import { AuthService } from "../../../auth/auth.service";
import { OverlaysService } from "src/app/data-access-layer/global-overlays/overlays.service";
import { ActivatedRoute } from "@angular/router";
import { CustomScoringService } from "../services/custom-scoring.service";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { GroupService } from "src/app/data-access-layer/groups/group.service";
import { DatapointsAggregateService } from "src/app/data-access-layer/datapoints/datapoints-aggregate.service";
import { I } from "@angular/cdk/keycodes";
import { MatTableDataSource } from "@angular/material/table";
import { CustomScoring, CustomScoringScope } from "../model/custom-service";
import { SidePanelComponent } from "src/app/core/side-panel/side-panel.component";
import { filter, take } from "rxjs/operators";
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { DialogModel } from "src/app/model/dialog/dialog-model";
import { MatDialog } from "@angular/material/dialog";
import { AddCustomScoringComponent } from "../add-custom-scoring/add-custom-scoring.component";


@Component({
    selector: "view-global-dataset",
    templateUrl: "./view-global-dataset.component.html",
    styleUrls: ["./view-global-dataset.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewGlobalDataSetComponent implements OnInit {
    @Output()
    updatedSuccessfully = new EventEmitter();
    @Output() onCancelClose = new EventEmitter();
    @Input() existingData: any;
    @Input() dataSetRecord: any;
    @Input() fieldDropdown: any;
    @Input() customScoringType: any;
    panelTitle: string = "";
    createAccountForm: UntypedFormGroup;
    datasetLinkMap = {};
    externalOverlays: any;
    accountId: number;
    selectedType: string = '';
    isNumberField = false;
    isTextField = false;
    isDropdownValueSelected = false;
    textOptions = [];
    locationDataset: any;
    dataSource: any;
    applyIcon: string = "icon1"
    dataToEdit: any;
    displayedColumns: string[] = ['name', 'Actions'];
    tableData: any;
    isEdit: boolean = false;
    isOpenAddComponent = false;
    customScoringList: CustomScoring[];
    @ViewChild("addCustomScoring") addCustomScoring: AddCustomScoringComponent;

    @ViewChild("customScoringPanel") customScoringPanel: SidePanelComponent;
    @ViewChild('filterComp', { read: ElementRef }) filterCompRef: ElementRef;
    constructor(
        private readonly formBuilder: UntypedFormBuilder,
        private readonly accountService: AccountService,
        private readonly notifService: NotifService,
        private readonly changeDetector: ChangeDetectorRef,
        private readonly userStateService: UserStateService,
        private readonly auth: AuthService,
        private overlayService: OverlaysService,
        private readonly route: ActivatedRoute,
        private customService: CustomScoringService,
        private fb: FormBuilder,
        private readonly groupService: GroupService,
        private readonly datapointsAggregateService: DatapointsAggregateService,
        public readonly dialog: MatDialog,

    ) {
        
    }

    ngOnChanges() {
        this.dataSource = null;
        if (this.dataSetRecord) {
            this.getList();
        }
        this.existingData = false;
        if (this.existingData) {
            this.patchExistingData();
        }
    }

    ngOnInit() {
        this.dataSource = null;
        this.accountId = +this.route.snapshot.paramMap.get("accountId");
        this.initForm();
        if (this.existingData) {
            this.patchExistingData();
        }
        if (this.dataSetRecord) {
            this.getList();
        }
    }
    convertObjects(objectList) {
        return objectList.map(object => this.convertObject1ToObject2(object));
      }

    convertObject1ToObject2(object1) {
        return {
          id: object1.fieldId,
          name: object1.fieldLabel,
          type: this.convertFieldType(object1.fieldType),
        };
      }

    convertFieldType(fieldType) {
        switch (fieldType) {
          case "STRING":
            return "SHORT_TEXT_FIELD";
          case "NUMERIC":
            return "NUMBER_FIELD";
          default:
            return fieldType;
        }
      }

    addNewScoring() {
        this.setCustomScoringType();

        const filteredFields = this.dataSetRecord.fields.filter(field => field.displayInDropdowns === true);

        this.locationDataset = this.dataSetRecord;

        this.externalOverlays = filteredFields;

        this.isEdit = false;
        this.dataToEdit = null;
        this.addCustomScoring.isEdit = false;
        this.addCustomScoring.existingData = null;
        this.addCustomScoring.dataToEdit = null;
        this.textOptions = null;
        this.addCustomScoring.isApplied = false;
        this.addCustomScoring.isDropdownValueSelected = false;
        this.addCustomScoring.createAccountForm.reset();
        this.addCustomScoring.initForm();
        
        this.clearFormValidators(this.createAccountForm);
        this.createAccountForm.reset();
        this.isDropdownValueSelected = false;
        this.initForm();

        if (this.customScoringType === CustomScoringScope.EXTERNAL) {
            this.externalOverlays = this.convertObjects(this.dataSetRecord.fields);
            let locationAccountDataSet = localStorage.getItem("locationDataSetItem_"+this.accountId);
            let localStorageData = JSON.parse(locationAccountDataSet)
            this.locationDataset = {...this.locationDataset, id: localStorageData.id};
        }

        this.isOpenAddComponent = true;
        this.isEdit = false;
        this.dataToEdit = null;
        this.customScoringPanel.showPanel();
        this.changeDetector.detectChanges();
    }

    patchExistingData(): void {
        this.createAccountForm.patchValue({
            name: this.existingData.name,
            fieldTextAndNumeric: this.existingData.fieldName,
        });

        this.onFieldTypeChange({
            value: this.existingData.fieldName,
        }, true);
        if (this.existingData.scoring && this.existingData.scoring.length > 0) {
            this.isDropdownValueSelected = true;

            const rangesArray = this.createAccountForm.get('ranges') as FormArray;

            this.existingData.scoring.forEach((range) => {
                const rangeGroup = this.fb.group({
                    minValue: [range.minNumberValue, this.isNumberField ? Validators.required : null],
                    maxValue: [range.maxNumberValue, this.isNumberField ? Validators.required : null],
                    textValues: [range.textValues || [], this.isTextField ? Validators.required : null],
                    label: [range.label, Validators.required],
                });
                rangesArray.push(rangeGroup);
            });
        }
    }
    setCustomScoringType() {
        if (this.customScoringType === 'Global') {
            this.customScoringType = CustomScoringScope.GLOBAL;
            this.panelTitle = 'Global Datasets';

        } else if (this.customScoringType === 'Locations') {
            this.customScoringType = CustomScoringScope.INTERNAL;
            this.panelTitle = 'Locations';

        } else if (this.customScoringType === 'External') {
            this.customScoringType = CustomScoringScope.EXTERNAL;
            this.panelTitle = 'External Datasets';

        }
    }
    editCustomScoring(customScoring) {
        this.addCustomScoring.clearFormValidators(this.addCustomScoring.createAccountForm);
        this.addCustomScoring.isDropdownValueSelected = true;
        this.setCustomScoringType();
        this.customService.getCustomScoring(this.accountId,customScoring._id).subscribe((data) => {
            const filteredFields = this.dataSetRecord.fields.filter(field => field.displayInDropdowns === true);
            this.dataToEdit = data; // STORE EXISTING DATA
            this.isEdit = true;
            this.externalOverlays = filteredFields
            this.locationDataset = this.dataSetRecord;
            let selectedData = this.dataSetRecord.fields.find(data => data.name === this.dataToEdit.fieldName);
            let tempValueForDataSetId;
                if (this.customScoringType === CustomScoringScope.EXTERNAL) {
                    this.externalOverlays = this.convertObjects(this.dataSetRecord.fields);

                    let locationAccountDataSet = localStorage.getItem("locationDataSetItem_"+this.accountId);
                    let localStorageData = JSON.parse(locationAccountDataSet)
                    tempValueForDataSetId = {id: localStorageData.id};
                    tempValueForDataSetId = tempValueForDataSetId.id;
                    selectedData = this.dataSetRecord.fields.find(data => data.fieldLabel === this.dataToEdit.fieldName);
                    this.locationDataset = {...this.locationDataset, id: localStorageData.id};


                } else {
                    tempValueForDataSetId = customScoring.datasetID;
                    selectedData = this.dataSetRecord.fields.find(data => data.name === this.dataToEdit.fieldName);

                }
                this.groupService.getGroupsAsTreeForCustomScoring(tempValueForDataSetId, this.accountId).subscribe(groups => {
                  const ids: number[] = [];
                  const collectIds = (nodes: any[]) => {
                    nodes.forEach(node => {
                      ids.push(node.value.id);
                      if (node.children && node.children.length > 0) {
                        collectIds(node.children);
                      }
                    });
                  };
                  collectIds(groups);

                  let fieldId;
                  let tempSelectedId;
                  if (this.customScoringType === CustomScoringScope.EXTERNAL) {
                      fieldId = this.dataSetRecord.datasetId + '_' + selectedData.fieldId;
                      tempSelectedId = selectedData.fieldId;
                  } else {
                      fieldId = this.locationDataset.id;
                      tempSelectedId = selectedData.id;
                      fieldId = tempSelectedId
                  }
                  this.datapointsAggregateService.getDatapointsFieldStatistics(tempValueForDataSetId, fieldId, {
                      datasetID: tempValueForDataSetId,
                      groups: ids
                  })
                  .subscribe((statistics) => {
                      this.textOptions = statistics.values;

                      this.customScoringPanel.showPanel();
                      this.changeDetector.detectChanges();

                  })
              });

          });
    }
    deleteCustomScoring(customScoring) {
        const dialogRef = this.dialog.open(DialogComponent, {
            data: new DialogModel(
                "Confirm Action",
                `Are you sure you want to delete the custom scoring?`
            ),
        });
        dialogRef
            .afterClosed()
            .pipe(take(1))
            .subscribe((dialogResult) => {
                if (dialogResult) {
                  this.customService
                        .deleteCustomScoring(this.accountId, customScoring._id)
                        .subscribe((success) => {
                            this.getList();
                            this.notifService.success(
                                "The custom scoring has been deleted."
                            );
                        });
                }
            });
    }

    toggleIcon(customScoring) {
        this.applyIcon = this.applyIcon === "icon1" ? "icon2" : "icon1";
        let applied = customScoring.applied;
        let isSuccess = false;
        this.customService.applyFlag(this.accountId, customScoring._id, !applied).subscribe((data) => {
          this.getList();
          this.notifService.success(
              "The custom scoring applied flag has been updated.."
          );
          isSuccess = true;
        });
        if (isSuccess) {
          this.changeDetector.detectChanges();
          return true;
        }
      }

    getList() {
            this.setCustomScoringType();
            this.customScoringList = null;
            this.dataSource =null;
            this.customService.getCustomScoringsForAccount(this.accountId, this.customScoringType, this.dataSetRecord.id)
                .subscribe((data) => {
                this.customScoringList = data;
                this.customScoringList
                .map(item => ({
                    ...item,
                    name: item.name.trimStart()
                }))
                .sort((a, b) => a.name.localeCompare(b.name))

                this.dataSource = new MatTableDataSource<any>([...this.customScoringList]);
                this.dataSource.filter = '';
                this.changeDetector.detectChanges();
                const inputElement: HTMLInputElement = this.filterCompRef.nativeElement.querySelector('input');
                if (inputElement) {
                  inputElement.value = '';
                }
            });
    }

    applyFilter(filterValue: string): void {
        const trimmedFilter = filterValue.trim().toLowerCase();    
        this.dataSource.filterPredicate = (data: any, filter: string): boolean => {
          if (!filter) {
            return true;
          }
          return data.name?.toLowerCase().includes(filter);
        };    
        this.dataSource.filter = trimmedFilter;
    }

    onCreateSuccess() {
        this.getList();
    }

    onCancelClose2() {
        this.customScoringPanel.hidePanel();
        this.customScoringPanel.closePanel();
        this.getList();
    }

    getData() {


        this.accountService.findAccount(this.accountId).subscribe((account) => {
           const locationDataset = account.datasets.find((dataset: any) => dataset.name === 'Locations');
            if (locationDataset) {
                this.locationDataset = locationDataset;
                this.customService.getLocationCustomScoringFields(locationDataset?.id)
                .subscribe((data) => {
                  this.externalOverlays = data;
                });
            }
        });
    }
    onSelectionChange(selectedId: number) {
        const selectedData = this.externalOverlays.find(data => data.id === selectedId);
        this.selectedType = selectedData ? selectedData.type : '';
    }
    get ranges() {
        return this.createAccountForm.get('ranges') as FormArray;
    }

    onFieldTypeChange(event: any, isComingFromExisting = false) {
        let selectedData;
        if (this.existingData && isComingFromExisting) {
            selectedData = this.externalOverlays.find(data => data.name === this.existingData.fieldName);
            this.createAccountForm.controls.fieldTextAndNumeric.setValue(selectedData.id);

        } else {
            selectedData = this.externalOverlays.find(data => data.id === event.value);
        }
        const selectedFieldType = selectedData ? selectedData.type : '';
        this.isNumberField = selectedFieldType === 'NUMBER_FIELD';
        this.isTextField = selectedFieldType === 'SHORT_TEXT_FIELD';
        this.isDropdownValueSelected = true;
        this.ranges.clear();
        this.ranges.clearValidators();
        if (this.isNumberField || this.isTextField) {
            this.ranges.setValidators([Validators.required]);
        }

        this.ranges.updateValueAndValidity();
        if (!this.existingData) {
            this.addRange();
        }

        this.groupService.getGroupsAsTreeForCustomScoring(this.locationDataset.id, this.accountId).subscribe(groups => {
            const ids: number[] = [];
            const collectIds = (nodes: any[]) => {
              nodes.forEach(node => {
                ids.push(node.value.id);
                if (node.children && node.children.length > 0) {
                  collectIds(node.children);
                }
              });
            };
            collectIds(groups);

            this.datapointsAggregateService.getDatapointsFieldStatistics(this.locationDataset.id, selectedData.id, {
                datasetID: this.locationDataset.id,
                groups: ids
            })
            .subscribe((statistics) => {
                this.textOptions = statistics.values;
            })

        });
    }

    addRange() {
        const rangeGroup = this.fb.group({
            minValue: [
                '',
                this.isNumberField ? Validators.required : [],
            ],
            maxValue: [
                '',
                this.isNumberField ? Validators.required : [],
            ],
            textValues: [
                this.isTextField ? [] : null,
                this.isTextField ? Validators.required : [],
            ],
            label: ['', Validators.required],
        });

        this.ranges.push(rangeGroup);

    }
    removeRange(index: number) {
        this.ranges.removeAt(index);
    }

    drop(event: CdkDragDrop<FormGroup[]>) {
        moveItemInArray(this.ranges.controls, event.previousIndex, event.currentIndex);
        this.updatePayloadOrder();
    }

      updatePayloadOrder() {
        this.ranges.controls.forEach((control, index) => {
          control.patchValue({ order: index + 1 });
        });
    }

    moveRangeUp(index: number) {
        if (index === 0) return;
        const ranges = this.ranges.controls;
        [ranges[index - 1], ranges[index]] = [ranges[index], ranges[index - 1]];
        this.updatePayloadOrder();
    }

      moveRangeDown(index: number) {
        if (index === this.ranges.length - 1) return;
        const ranges = this.ranges.controls;
        [ranges[index], ranges[index + 1]] = [ranges[index + 1], ranges[index]];
        this.updatePayloadOrder();
    }


      get hasValidationError() {
        return this.ranges.controls.some(
          (control) =>
            control.get('minValue').value != null &&
            control.get('maxValue').value != null &&
            control.get('minValue').value > control.get('maxValue').value
        );
    }
    initForm(): void {
        this.createAccountForm = this.fb.group({
            name: ['', [Validators.required]],
            fieldTextAndNumeric: ['', [Validators.required]],
            ranges: this.fb.array([]),

        });
        this.createAccountForm.get('ranges').clearValidators();  // CLEAR ALL VALIDATORS INITIALLY
        this.createAccountForm.updateValueAndValidity();

    }

    mapItems(items) {
        let values = [];
        Object.keys(items).map((index) => {
            let item = items[index];
            if (item) {
                values.push(index);
            }
        });
        return !(values.length === 0);
    }
    clearFormValidators(formGroup: UntypedFormGroup | FormGroup): void {
        Object.keys(formGroup.controls).forEach((key) => {
          const control = formGroup.get(key);
          if (control instanceof UntypedFormGroup || control instanceof FormGroup) {
            this.clearFormValidators(control);
          } else if (control instanceof UntypedFormControl) {
            control.clearValidators();
            control.updateValueAndValidity();
          }
        });
      }

      ngOnDestroy() {
        localStorage.removeItem("global")
        localStorage.removeItem("external")
        this.customScoringPanel.hidePanel();
        this.customScoringPanel.closePanel();
      }

    onCancel() {
        this.clearFormValidators(this.createAccountForm);
        this.createAccountForm.reset();
        this.onCancelClose.emit();
        this.changeDetector.detectChanges();
    }
    submitAccount(): void {
        if (this.createAccountForm.valid) {
            const formData = this.createAccountForm.value;

        const selectedData = this.externalOverlays.find(data => data.id === formData.fieldTextAndNumeric);
            const payload = {
                name: formData.name,
                accountId: this.accountId,
                deleted: false,
                scope: selectedData.scope,
                datasetID: this.locationDataset.name,
                fieldName: selectedData.name,
                fieldTextAndNumeric: formData.fieldTextAndNumeric,
                scoring: formData.ranges.map((range, index) => ({
                    minValue: range.minValue,
                    maxValue: range.maxValue,
                    textValues: range.textValues,
                    label: range.label,
                    order: index + 1,
                }))
            };

            if (this.existingData) {
                const payload = {
                    _id: this.existingData._id,
                    name: formData.name,
                    accountId: this.accountId,
                    deleted: false,
                    scope: selectedData.scope,
                    datasetID: this.locationDataset.name,
                    fieldName: selectedData.name,
                    fieldTextAndNumeric: formData.fieldTextAndNumeric,
                    scoring: formData.ranges.map((range, index) => ({
                        minValue: range.minValue,
                        maxValue: range.maxValue,
                        textValues: range.textValues,
                        label: range.label,
                        order: index + 1,
                    }))
                };
                this.customService.updateCustomScoring(this.accountId, payload)
                .subscribe((data) => {

                    this.clearFormValidators(this.createAccountForm);
                    this.createAccountForm.reset();
                    this.notifService.success(
                        "Successfully created custom scoring"
                    );
                    this.updatedSuccessfully.emit();
                    this.changeDetector.detectChanges();


                });
            } else {
                this.customService.createCustomScoring(this.accountId, payload)
                .subscribe((data) => {
                    this.clearFormValidators(this.createAccountForm);
                    this.createAccountForm.reset();
                    this.notifService.success(
                        "Successfully created custom scoring"
                    );
                    this.updatedSuccessfully.emit();
                    this.changeDetector.detectChanges();


                });
            }

          } else {
            console.log("Form is invalid. Please check the errors.");
          }
    }
   

}
