import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { UserStateService } from '../../auth/user-state-service';
import { MatTabsModule } from '@angular/material/tabs';
import { OverlaysService } from '../../data-access-layer/global-overlays/overlays.service';
import { ActivatedRoute } from '@angular/router';
import { CacheService } from './services/cache.service';
import { CustomScoringService } from './services/custom-scoring.service';
import { MatTableDataSource } from '@angular/material/table';
import { CustomScoring, CustomScoringScope } from './model/custom-service';
import { Dataset } from 'src/app/model/dataset/dataset';
import { TessadataDatasetStructure } from 'src/app/core/tessadata/tessadata-dataset-structure';
import { SidePanelComponent } from 'src/app/core/side-panel/side-panel.component';
import { MatDialog } from '@angular/material/dialog';
import { DialogModel } from 'src/app/model/dialog/dialog-model';
import { take } from "rxjs/operators";
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { NotifService } from 'src/app/core/notification/notif.service';
import { GroupService } from 'src/app/data-access-layer/groups/group.service';
import { DatapointsAggregateService } from 'src/app/data-access-layer/datapoints/datapoints-aggregate.service';
import { AddCustomScoringComponent } from './add-custom-scoring/add-custom-scoring.component';

export enum DatasetType {
  LOCATIONS = 'Locations',
  EXTERNAL = 'External',
  GLOBAL = 'Global'
}

@Component({
  selector: 'map-custom-scoring',
  templateUrl: './custom-scoring.component.html',
  styleUrls: ['./custom-scoring.component.scss']
})
export class CustomScoringComponent {

  breadCrumbs = ["Home", "Accounts", "Settings"];
  accountId: number;
  selectedOptions: String = 'Locations';
  cacheDurationTime = 2;
  customScoringList: CustomScoring[];
  dataSource: any;
  globalOverlays: Dataset[];
  externalOverlays: Dataset[];
  displayedColumns: string[] = ['name', 'Actions'];
  applyIcon: string = "icon1"
  isOverlays:boolean = false
  actionColumnNameTitle: string = 'Actions';
  dataToEdit: any;
  @ViewChild("customScoringPanel") customScoringPanel: SidePanelComponent;
  @ViewChild("globalDatasetPanel") globalDatasetPanel: SidePanelComponent;
  @ViewChild("addCustomScoring") addCustomScoring: AddCustomScoringComponent;
  @ViewChild('filterComp', { read: ElementRef }) filterCompRef: ElementRef;
  isActionGloablAndExtenalDataset: boolean = true;
  dataSetRecord: any;
  isEdit: boolean = false;
  textOptions = [];
  panelTitle: string = '';
  customScoringType: any;
  constructor(
    public userStateService: UserStateService,
    private overlayService: OverlaysService,
    private cacheService: CacheService,
    private customService: CustomScoringService,
    private readonly route: ActivatedRoute,
    public readonly dialog: MatDialog,
    private readonly notifService: NotifService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly groupService: GroupService,
    private readonly datapointsAggregateService: DatapointsAggregateService,

  ) {
    this.userStateService = userStateService;
    this.overlayService = overlayService;
    this.cacheService = cacheService;
  }

  ngOnInit() {
    this.accountId = +this.route.snapshot.paramMap.get("accountId");

    this.customService.getCustomScoringsForAccount(this.accountId, CustomScoringScope.INTERNAL)
          .subscribe((data) => {
            this.customScoringList = data;
            this.dataSource = new MatTableDataSource<CustomScoring>(this.customScoringList);
          });
  }

  public onDatasetChange(isPanel = false) {
    if (isPanel) {
      this.customScoringPanel.hidePanel();
      this.customScoringPanel.closePanel();
      this.globalDatasetPanel.hidePanel();
      this.globalDatasetPanel.closePanel();

      this.addCustomScoring.clearFormValidators(this.addCustomScoring.createAccountForm);
      this.addCustomScoring.createAccountForm.reset();
  
      this.addCustomScoring.isDropdownValueSelected = false;
      this.isEdit = false;
      this.dataToEdit = null;
      this.changeDetector.detectChanges();
    }
    if (this.selectedOptions === 'Locations') {
      this.displayedColumns = ['name', 'Actions'];
    } else {
      this.displayedColumns = ['name', 'datasetId', 'Actions'];
      this.actionColumnNameTitle = 'Custom Scoring';
    }
    switch (this.selectedOptions) {
      case DatasetType.LOCATIONS:
        this.dataSource = null
        this.isOverlays = false
        this.customService.getCustomScoringsForAccount(this.accountId, CustomScoringScope.INTERNAL)
        .subscribe((data) => {
          this.customScoringList = data;
          this.customScoringList
          .map(item => ({
            ...item,
            name: item.name.trimStart()
          }))
          .sort((a, b) => a.name.localeCompare(b.name))
  
          this.dataSource = new MatTableDataSource<CustomScoring>(this.customScoringList);
          this.dataSource.filter = '';
        });
        break;
      case DatasetType.EXTERNAL:
        this.dataSource = null
        this.isOverlays = true
        this.overlayService.getAccountTessadataOverlays(this.accountId)
        .subscribe((externalOverlay) => {
          externalOverlay.map((item) => {
            item.id = item.datasetId;
            item.name = item.datasetLabel;
            item.overlayGroupId = item.externalGroupId;
          });
          this.externalOverlays = externalOverlay
          this.externalOverlays
          .map(item => ({
            ...item,
            name: item.name.trimStart()
          }))
          .sort((a, b) => a.name.localeCompare(b.name))

          this.dataSource = new MatTableDataSource<any>(this.externalOverlays)
          this.dataSource.filter = '';
        })
        break;
      case DatasetType.GLOBAL:
        this.dataSource = null
        this.isOverlays = true
        this.overlayService.getAccountGlobalOverlays(this.accountId)
        .subscribe((data) => {
          this.globalOverlays = data.filter(item => 
            !(item.isGeoserver === false || 
              item.thematicMapSettings?.isThematicMapDataset === true || 
              item.geometryType === 'POINT')
            );
          this.globalOverlays.sort((a, b) => a.name.localeCompare(b.name))
          this.dataSource = new MatTableDataSource<any>(this.globalOverlays)
          this.dataSource.filter = '';
        })
        break;
      default:
        console.log("default");

    }

    const inputElement: HTMLInputElement = this.filterCompRef.nativeElement.querySelector('input');    
    if (inputElement) {
      inputElement.value = '';
    }
  }

  applyFilter(filterValue: string): void {
    const trimmedFilter = filterValue.trim().toLowerCase();
    this.dataSource.filterPredicate = (data: any, filter: string): boolean => {
      if (!filter) {
        return true;
      }      
      return data.name?.toLowerCase().includes(filter);
    };
    this.dataSource.filter = trimmedFilter;
  }
  editCustomScoring(customScoring): void {
    let locationDataset = JSON.parse(localStorage.getItem("locationDataSetItem_"+this.accountId));
    let locationCustomFields = JSON.parse(localStorage.getItem("locationCustomFields_"+this.accountId));
    let selectedData = locationCustomFields.find(data => data.name === customScoring.fieldName);
    this.customService.getCustomScoring(this.accountId,customScoring._id).subscribe((data) => {
        this.isEdit = true;
        this.dataToEdit = data;
        this.groupService.getGroupsAsTreeForCustomScoring(locationDataset.id, this.accountId).subscribe(groups => {
          const ids: number[] = [];
          const collectIds = (nodes: any[]) => {
            nodes.forEach(node => {
              ids.push(node.value.id);
              if (node.children && node.children.length > 0) {
                collectIds(node.children);
              }
            });
          };
          collectIds(groups);

          this.datapointsAggregateService.getDatapointsFieldStatistics(locationDataset.id, selectedData.id, {
              datasetID: locationDataset.id,
              groups: ids
          })
          .subscribe((statistics) => {
              this.textOptions = statistics.values;
              this.changeDetector.detectChanges();
              this.customScoringPanel.showPanel();
          })
      });
    });
}


  toggleIcon(customScoring) {
    this.applyIcon = this.applyIcon === "icon1" ? "icon2" : "icon1";
    let applied = customScoring.applied;
    let isSuccess = false;
    this.customService.applyFlag(this.accountId, customScoring._id, !applied).subscribe((data) => {
      this.onDatasetChange();
      this.notifService.success(
          "The custom scoring applied flag has been updated.."
      );
      isSuccess = true;
    });
    if (isSuccess) {
      this.changeDetector.detectChanges();
      return true;
    }

  }

  get componentName() {
    return this.constructor.name;
  }

  ngOnDestroy() {
    localStorage.removeItem("global")
    localStorage.removeItem("external")
    this.customScoringPanel.hidePanel();
    this.customScoringPanel.closePanel();
    this.addCustomScoring.createAccountForm.reset();
    this.addCustomScoring.initForm();
  }

  updatetheFlag(dataSetRecord) {
    if (this.selectedOptions === 'Global') {
        this.panelTitle = 'Global Datasets';
    } else if (this.selectedOptions === 'Locations') {
        this.panelTitle = 'Locations';
    } else if (this.selectedOptions === 'External') {
        this.panelTitle = 'External Datasets';
    }
    this.dataSetRecord = dataSetRecord;
    this.globalDatasetPanel.showPanel();

  }
  addNewScoring() {
    this.isEdit = false;
    this.dataToEdit = null;
    this.addCustomScoring.isEdit = false;
    this.addCustomScoring.existingData = null;
    this.addCustomScoring.dataToEdit = null;
    this.textOptions = null;
    this.addCustomScoring.isApplied = false;
    this.addCustomScoring.clearFormValidators(this.addCustomScoring.createAccountForm);
    this.addCustomScoring.createAccountForm.reset();
    this.addCustomScoring.isDropdownValueSelected = false;
    this.addCustomScoring.initForm();
      if (this.selectedOptions === 'Global') {
          this.customScoringType = CustomScoringScope.GLOBAL;

      } else if (this.selectedOptions === 'Locations') {
          this.customScoringType = CustomScoringScope.INTERNAL;

      } else if (this.selectedOptions === 'External') {
          this.customScoringType = CustomScoringScope.EXTERNAL;

      }
 
    this.customScoringPanel.showPanel();
    this.changeDetector.detectChanges();
  }

  onCancelClose2() {
    this.customScoringPanel.hidePanel();
    this.customScoringPanel.closePanel();
    this.globalDatasetPanel.hidePanel();
    this.globalDatasetPanel.closePanel();
    this.changeDetector.detectChanges();
  }

  onCreateSuccess() {
    this.changeDetector.detectChanges();
    this.onDatasetChange();

  }

  deleteCustomScoring(customScoring): void {
    const dialogRef = this.dialog.open(DialogComponent, {
        data: new DialogModel(
            "Confirm Action",
            `Are you sure you want to delete the custom scoring?`
        ),
    });
    dialogRef
        .afterClosed()
        .pipe(take(1))
        .subscribe((dialogResult) => {
            if (dialogResult) {
              this.customService
                    .deleteCustomScoring(this.accountId, customScoring._id)
                    .subscribe((success) => {
                        this.onDatasetChange();
                        this.notifService.success(
                            "The custom scoring has been deleted."
                        );
                    });
            }
        });
  }


}
