<div *ngIf="detailsForm" class="overlay-box-container">
	<div class="overlay-row  overlay-row-two-columns">
		<div class="overlay-box overlay-dataset-box">
			<div class="overlay-box-title">
				<span class="text-primary">Dataset</span>
			</div>
			<div class="overlay-box-content">
				<form [formGroup]="detailsForm">
					<div class="overlay-box-row">
						<div class="text-lightdark">Dataset Name</div>
						<div>
							<mat-form-field *ngIf="detailsForm.controls.type.value !== DatasetType.ACCOUNT_APPLICATION">
								<input formControlName="name" matInput autocomplete="off" />
							</mat-form-field>
							<span *ngIf="detailsForm.controls.type.value === DatasetType.ACCOUNT_APPLICATION"
								class="label" style="margin-left: 0px;">{{detailsForm.controls.name.value}}</span>
						</div>
					</div>
					<div class="overlay-box-row">
						<div class="text-lightdark">Dataset Type</div>
						<mat-form-field class="dataset-type-form-field" style="margin-left: 5px;width: 180px;">
							<mat-select formControlName="type">
								<mat-option [value]="DatasetType.ACCOUNT_OVERLAY">Private</mat-option>
								<mat-option [value]="DatasetType.GLOBAL_OVERLAY">Global</mat-option>
								<mat-option [value]="DatasetType.ACCOUNT_APPLICATION">Application</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
					<div *ngIf="!isAccountApplicationDataset && isCreate" class="overlay-box-row">
						<div class="text-lightdark">Geometry Type</div>
						<div>
							<mat-form-field>
								<mat-select formControlName="geometryType"
									(selectionChange)="geometryTypeChanged($event)">
									<mat-option [value]="DatasetGeometryType.NONE">None</mat-option>
									<mat-option [value]="DatasetGeometryType.POINT">Point</mat-option>
									<mat-option [value]="DatasetGeometryType.COMPLEX">Complex</mat-option>
									<mat-option [value]="DatasetGeometryType.LINE_STRING">Line string
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
					</div>
					<div class="overlay-box-row"
						*ngIf="detailsForm.controls.geometryType.value === DatasetGeometryType.COMPLEX">
						<div class="text-lightdark">Estimated overlay size in MB</div>
						<div>
							<mat-form-field>
								<input type="number" formControlName="geometryShardCount" matInput />
							</mat-form-field>
						</div>
					</div>
					<div class="overlay-box-row">
						<div class="text-lightdark">Main Summary</div>
						<div>
							<mat-form-field>
								<mat-select formControlName="mainSummaryFieldIndex">
									<mat-option *ngFor="let field of detailsForm.controls.fields.value"
										[value]="detailsForm.controls.fields.value.indexOf(field)">
										{{ field.name }}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
					</div>
					<div class="overlay-box-row" *ngIf="!isAccountApplicationDataset">
						<div class="text-lightdark">Overlay Group</div>
						<div class="padding">
							<mat-form-field color="accent">
								<mat-select formControlName="overlayGroupId">
									<mat-option [value]="null">None</mat-option>
									<mat-option *ngFor="let group of groups" [value]="group.id">
										{{ group.name }}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
					</div>
					<div *ngIf="isAccountApplicationDataset" class="overlay-box-row">
						<div class="text-lightdark">Geocoded</div>
						<div class="padding">
							<mat-checkbox formControlName="isGeocoded"></mat-checkbox>
						</div>
					</div>
					<div class="overlay-box-row">
						<div class="text-lightdark">Linkable</div>
						<div class="padding">
							<mat-checkbox formControlName="isLinkable"></mat-checkbox>
						</div>
					</div>
					<div class="overlay-box-row" *ngIf="isAccountApplicationDataset">
						<div class="text-lightdark">Enable Hazard Hub</div>
						<div class="padding">
							<mat-checkbox formControlName="hasHazardHubEnabled"></mat-checkbox>
						</div>
					</div>
					<div class="overlay-box-row" *ngIf="!isAccountApplicationDataset">
						<div class="text-lightdark">Is Thematic Overlay</div>
						<div class="padding" formGroupName="thematicMapSettings">
							<mat-checkbox
								[matTooltip]="'You can only enable thematic overlay after the overlay was created'"
								[matTooltipDisabled]="detailsForm.value.id !== undefined && detailsForm.value.id !== null "
								(change)="thematicMapChanged($event)"
								formControlName="isThematicMapDataset"
								class="thematic-checkbox-tooltip">
							</mat-checkbox>
						</div>
					</div>
					<div class="overlay-box-row" *ngIf="!isAccountApplicationDataset">
						<div>Show in </div>
						<div class="padding">
							<mat-form-field color="accent">
								<mat-select formControlName="showInMap">
									<mat-option *ngFor="let option of showInMapOptions" [value]="option.value">
										{{option.viewValue}}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
					</div>
					<div *ngIf="this.detailsForm?.controls.thematicMapSettings.get('isThematicMapDataset').value"
						class="overlay-box-row">
						<div class="text-lightdark">Main Thematic Field</div>
						<div class="padding" formGroupName="thematicMapSettings">
							<mat-form-field>
								<mat-select formControlName="mainThematicFieldId">
									<mat-option [value]="null">None</mat-option>
									<mat-option *ngFor="let field of dataSource.data | hasId" [value]="field.id">
										{{ field.name }}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
					</div>
					<div *ngIf="this.detailsForm?.controls.thematicMapSettings.get('isThematicMapDataset').value"
						class="overlay-box-row">
						<div class="text-lightdark">Parent Overlay</div>
						<div class="padding" formGroupName="thematicMapSettings">
							<mat-form-field>
								<mat-select formControlName="parentDatasetId">
									<mat-option [value]="null">None</mat-option>
									<mat-option *ngFor="let dataset of thematicDatasets" [value]="dataset.id">
										{{ dataset.name }}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
					</div>
					<div *ngIf="this.detailsForm?.controls.thematicMapSettings.get('isThematicMapDataset').value"
						class="overlay-box-row">
						<div class="text-lightdark">Parent Link Field</div>
						<div class="padding" formGroupName="thematicMapSettings">
							<mat-form-field>
								<mat-select formControlName="parentLinkFieldId">
									<mat-option [value]="null">None</mat-option>
									<mat-option *ngFor="let field of dataSource.data" [value]="field.id">
										{{ field.name }}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
					</div>
					<div class="overlay-box-row" *ngIf="isShowGeoServerFields">
						<div class="text-lightdark">Is Geo Server</div>
						<div class="padding">
							<mat-checkbox
								[matTooltip]="'You can only enable thematic overlay after the overlay was created'"
								(change)="geoServerChanged($event)" formControlName="isGeoserver"></mat-checkbox>
						</div>
					</div>
					<div class="overlay-box-row" *ngIf="isShowGeoServerFields && isGeoServerChecked">
						<div class="text-lightdark">Geo-Server Dataset Id</div>
						<div class="padding">
							<mat-form-field>
								<input formControlName="geoserverDatasetId" matInput autocomplete="off"
									(input)="checkGeoServerAttributsValues()" />
							</mat-form-field>
						</div>
					</div>
					<div class="overlay-box-row" *ngIf="isShowGeoServerFields && isGeoServerChecked">
						<div class="text-lightdark">Geo-Server Raster Dataset Id</div>
						<div class="padding">
							<mat-form-field>
								<input formControlName="rasterId" matInput autocomplete="off"
									(input)="checkGeoServerAttributsValues()" />
							</mat-form-field>
						</div>
					</div>
					<div class="overlay-box-row" *ngIf="isShowGeoServerFields && isGeoServerChecked">
						<div class="text-lightdark">Geo-Server Workspace</div>
						<div class="padding">
							<mat-form-field>
								<input formControlName="geoWorkspace" matInput autocomplete="off"
									(input)="checkGeoServerAttributsValues()" />
							</mat-form-field>
						</div>
					</div>
				</form>
			</div>
		</div>
		<div *ngIf="displayColorizationBox" class="overlay-box overlay-colorization-box">
			<div class="overlay-box-title">
				<span class="text-primary">Colorization</span>
			</div>
			<div class="overlay-box-content">
				<map-colorization [datasetStylingOptions]="datasetStylingOptions"
					[fields]="detailsForm.controls.fields.value"
					[datasetGeometryType]="detailsForm.controls.geometryType.value" [dataset]="dataset"
					[rowGroups]="groupes?.rowGroups"></map-colorization>
			</div>
		</div>
	</div>
	<div class="overlay-row" style="width: 100%; overflow: auto;">
		<div class="overlay-box overlay-fields-box" style="padding-bottom: 0rem !important;">
			<div class="overlay-box-title">
				<span>
                    Fields
                    <mat-radio-group *ngIf="tessadataEnabled" id="aggregateChartDisplayType" [(ngModel)]="filter" (change)="onFilterChanged()" class="radio-buttons-group">
                        <mat-radio-button [value]="DatasetConfigFilterEnum.ALL">All</mat-radio-button>
                        <mat-radio-button [value]="DatasetConfigFilterEnum.INTERNAL">Internal</mat-radio-button>
                        <mat-radio-button *ngIf="isSuperAdmin" [value]="DatasetConfigFilterEnum.EXTERNAL">External</mat-radio-button>
                        <mat-radio-button *ngIf="isSuperAdmin" [value]="DatasetConfigFilterEnum.NRI">NRI</mat-radio-button>
					<!--	<mat-radio-button *ngIf="isSuperAdmin" [value]="DatasetConfigFilterEnum.TENSORFLIGHT">TensorFlight</mat-radio-button>-->
						<mat-radio-button *ngIf="isSuperAdmin" [value]="DatasetConfigFilterEnum.E2VALUE">E2Value</mat-radio-button>
						<mat-radio-button *ngIf="isSuperAdmin" [value]="DatasetConfigFilterEnum.MUNICHRE">MunchRe</mat-radio-button>
                    </mat-radio-group>
                </span>
				<div class="toolbar-container">
					<button (click)="download()" *ngIf="detailsForm.controls.id.value" mat-flat-button color="primary"
						class="small">
						<!-- arrow-bottom-icon -->
						<div class="icon-container-wrap">
							<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
								<g fill="none" fill-rule="evenodd">
									<path class="icon-primary" stroke="#002d57" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" d="M12 16v5M10 19l2 2 2-2M7.4 17H7a5 5 0 0 1-5-5h0a4.985 4.985 0 0 1 4.324-4.932A5.998 5.998 0 0 1 12 3h0a6 6 0 0 1 6 6h0a4 4 0 0 1 4 4h0a4 4 0 0 1-4 4h-1.4"/>
									<path d="M0 0h24v24H0z"/>
								</g>
							</svg>
						</div>
						<div>Download</div>
					</button>
					<button *ngIf="detailsForm.controls.id.value" (click)="uploadInput.click()" mat-flat-button
						color="primary" class="small">
						<!-- arrow-bottom-icon -->
						<div class="icon-container-wrap">
							<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
								<g fill="none" fill-rule="evenodd">
									<path d="M0 0h24v24H0z"/>
									<path class="icon-primary" d="M16 19h2a4 4 0 0 0 4-4h0a4 4 0 0 0-4-4h0a6 6 0 0 0-6-6h0a5.998 5.998 0 0 0-5.676 4.068A4.985 4.985 0 0 0 2 14h0a5 5 0 0 0 5 5h1" stroke="#002d57" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
									<path stroke="#002d57" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" d="m14 16-2-2-2 2M12 19v-5"/>
								</g>
							</svg>
						</div>
						<input #uploadInput (change)="upload($event)" onclick="this.value = null" accept=".xlsx"
							style="display: none" type="file">
						<div>Upload</div>
					</button>
					<button (click)="addNewField()" mat-flat-button color="accent" class="small">
						<!-- plus-circle-icon -->
						<svg style="margin-right: 4px;" width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
							<g fill="none" fill-rule="evenodd">
								<path d="M24 0v24H0V0z"/>
								<path d="M15 3.523a8.865 8.865 0 0 0-6 0M3.157 10.358a8.981 8.981 0 0 1 3-5.196M6.157 18.838a8.982 8.982 0 0 1-3-5.196M9 20.477c1.94.697 4.06.697 6 0M20.843 10.358a8.981 8.981 0 0 0-3-5.196M17.843 18.838a8.982 8.982 0 0 0 3-5.196M10 12h4M12 14v-4" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
							</g>
						</svg>
						
						<span>Add Field</span>
					</button>
				</div>
			</div>
			<div class="overlay-box-content" #tableContainer>
				<form class="table-form" #tableForm="ngForm">
					<mat-table (cdkDropListDropped)="rowDropped($event)" *ngIf="dataSource.data.length"
						[cdkDropListData]="dataSource" [dataSource]="dataSource" cdkDropList>
						<!-- Id Column -->
						<ng-container matColumnDef="id">
							<mat-header-cell *matHeaderCellDef> Id</mat-header-cell>
							<mat-cell *matCellDef="let field; let i = index;">
								<span> {{field.id}}</span>
							</mat-cell>
						</ng-container>
						<!-- Name Column -->
						<ng-container matColumnDef="name">
							<mat-header-cell *matHeaderCellDef> Name</mat-header-cell>
							<mat-cell *matCellDef="let field; let i = index;">
								<mat-form-field color="accent">
									<input [(ngModel)]="field.name" [ngModelOptions]="{standalone: true}"
										autocomplete="off" matInput required #inputElement />
									<mat-error *ngIf="inputElement.required" class="error-field-name">
										Fill in a name
									</mat-error>
								</mat-form-field>
							</mat-cell>
						</ng-container>

						<ng-container matColumnDef="displayName">
							<mat-header-cell *matHeaderCellDef>Display Name</mat-header-cell>
							<mat-cell *matCellDef="let field; let i = index;">
								<mat-form-field color="accent">
									<input [(ngModel)]="field.displayName" [ngModelOptions]="{standalone: true}"
										autocomplete="off" matInput />
								</mat-form-field>
							</mat-cell>
						</ng-container>

						<!-- Type Column -->
						<ng-container matColumnDef="type">
							<mat-header-cell *matHeaderCellDef
								[ngClass]="isAccountApplicationDataset ? 'accountApplicationType' : 'overlayType'">Type</mat-header-cell>
							<mat-cell *matCellDef="let field">
								<mat-select [(value)]="field.type" [disabled]="field.id" name="type" placeholder="Type"
									color="accent">
									<mat-option [value]="DatasetFieldSpecificType.SHORT_TEXT_FIELD">Short text
									</mat-option>
									<mat-option [value]="DatasetFieldSpecificType.LONG_TEXT_FIELD">Text area
									</mat-option>
									<mat-option [value]="DatasetFieldSpecificType.TELEPHONE_TEXT_FIELD">Telephone
									</mat-option>
									<mat-option [value]="DatasetFieldSpecificType.EMAIL_TEXT_FIELD">Email</mat-option>
									<mat-option [value]="DatasetFieldSpecificType.URL_TEXT_FIELD">Url</mat-option>
									<mat-option [value]="DatasetFieldSpecificType.NUMBER_FIELD">Number</mat-option>
									<mat-option [value]="DatasetFieldSpecificType.DATE_FIELD">Date</mat-option>
									<mat-option [value]="DatasetFieldSpecificType.RADIO_TEXT_FIELD">Radio</mat-option>
									<mat-option [value]="DatasetFieldSpecificType.CHECKBOX_TEXT_FIELD">Checkbox
									</mat-option>
									<mat-option [value]="DatasetFieldSpecificType.SELECT_TEXT_FIELD">Select</mat-option>
									<mat-option [value]="DatasetFieldSpecificType.LINKS_FIELD">Link(s)</mat-option>
									<mat-option [value]="DatasetFieldSpecificType.MEMBERS_FIELD">Member(s)</mat-option>
								</mat-select>
							</mat-cell>
						</ng-container>

						<!-- Summary Column -->
						<ng-container matColumnDef="isSummary">
							<mat-header-cell *matHeaderCellDef width="100px">Summary</mat-header-cell>
							<mat-cell *matCellDef="let field; let i = index;">
								<mat-checkbox [(ngModel)]="field.isSummary" [name]="'isSummary'+i"></mat-checkbox>
							</mat-cell>
						</ng-container>

						<!-- Required Column -->
						<ng-container matColumnDef="required">
							<mat-header-cell *matHeaderCellDef>Required</mat-header-cell>
							<mat-cell *matCellDef="let field; let i = index;">
								<mat-checkbox [(ngModel)]="field.isRequired" [name]="'required'+i"></mat-checkbox>
							</mat-cell>
						</ng-container>

						<!-- Array Column -->
						<ng-container matColumnDef="isArray">
							<mat-header-cell *matHeaderCellDef>Array</mat-header-cell>
							<mat-cell *matCellDef="let row; let i = index;">
								<mat-checkbox [(ngModel)]="row.isArray" [name]="'isArray'+i"
									[disabled]="isAccountApplicationDataset"></mat-checkbox>
							</mat-cell>
						</ng-container>

						<!-- Generated Column -->
						<ng-container matColumnDef="isGenerated">
							<mat-header-cell *matHeaderCellDef>Generated</mat-header-cell>
							<mat-cell *matCellDef="let field; let i = index;">
								<mat-checkbox [(ngModel)]="field.isGenerated" [name]="'isGenerated'+i"
									[disabled]="true"></mat-checkbox>
							</mat-cell>
						</ng-container>

						<!-- High Cardinality Column -->
						<ng-container matColumnDef="isHighCardinality">
							<mat-header-cell *matHeaderCellDef>High Cardinality</mat-header-cell>
							<mat-cell *matCellDef="let field; let i = index;">
								<mat-checkbox [(ngModel)]="field.isHighCardinality" [name]="'isHighCardinality'+i"
									[disabled]="field.type === DatasetFieldSpecificType.NUMBER_FIELD ||
                                              field.type === DatasetFieldSpecificType.DATE_FIELD ">
								</mat-checkbox>
							</mat-cell>
						</ng-container>

						<!-- Display in dropdowns Column -->
						<ng-container matColumnDef="displayInDropdowns" *ngIf="!isAccountApplicationDataset">
							<mat-header-cell *matHeaderCellDef>Dropdowns</mat-header-cell>
							<mat-cell *matCellDef="let field; let i = index;">
								<mat-checkbox [(ngModel)]="field.displayInDropdowns"
									[name]="'displayInDropdowns'+i"></mat-checkbox>
							</mat-cell>
						</ng-container>

						<!-- Floating Column -->
						<ng-container matColumnDef="isFloating">
							<mat-header-cell *matHeaderCellDef>Floating</mat-header-cell>
							<mat-cell *matCellDef="let field; let i = index;">
								<mat-checkbox [(ngModel)]="field.isFloating" (ngModelChange)="setFloatingOption(field)"
									[name]="'isFloating'+i"
									[disabled]="field.type !== DatasetFieldSpecificType.NUMBER_FIELD"></mat-checkbox>
							</mat-cell>
						</ng-container>

						<!-- Floating Options Column -->
						<ng-container matColumnDef="floatingOptions">
							<mat-header-cell *matHeaderCellDef>Floating Options</mat-header-cell>
							<mat-cell *matCellDef="let field; let i = index;">
								<ng-container *ngIf="field.isFloating else none">

									<mat-select [(value)]="field.floatingOption" placeholder="Option">
										<mat-option *ngFor="let option of floatingOptions" [value]="option">
											{{option}}
										</mat-option>
									</mat-select>
								</ng-container>
								<ng-template #none>
									-
								</ng-template>
							</mat-cell>
						</ng-container>

						<ng-container matColumnDef="dateFormat">
							<mat-header-cell *matHeaderCellDef>Date Format</mat-header-cell>
							<mat-cell *matCellDef="let field; let i = index;">
								<mat-form-field color="accent"
									*ngIf="field.baseType === DatasetFieldType.DATE_TIME && !field.isGenerated">
									<input [(ngModel)]="field.dateFieldMetadata.format"
										[ngModelOptions]="{standalone: true}" autocomplete="off" matInput required />
								</mat-form-field>
								<ng-container
									*ngIf="field.baseType !== DatasetFieldType.DATE_TIME || field.isGenerated">
									-
								</ng-container>
							</mat-cell>
						</ng-container>

						<!-- Row Group Column -->
						<ng-container matColumnDef="isRowGroup">
							<mat-header-cell *matHeaderCellDef>Row Group</mat-header-cell>
							<mat-cell *matCellDef="let field; let i = index;">
								<mat-checkbox [(ngModel)]="field.isRowGroup" [name]="'isRowGroup'+i"
									[disabled]="field.baseType !== DatasetFieldType.TEXT"></mat-checkbox>
							</mat-cell>
						</ng-container>


						<!-- Avg Group Column -->
						<ng-container matColumnDef="isAvgGroup">
							<mat-header-cell *matHeaderCellDef>Avg Group</mat-header-cell>
							<mat-cell *matCellDef="let field; let i = index;">
								<mat-checkbox [(ngModel)]="field.isAvgGroup" [name]="'isAvgGroup'+i"
									[disabled]="field.baseType !== DatasetFieldType.NUMBER"></mat-checkbox>
							</mat-cell>
						</ng-container>

						<!-- Floating Options Column -->
						<ng-container matColumnDef="columnGroup">
							<mat-header-cell *matHeaderCellDef>Column Group</mat-header-cell>
							<mat-cell *matCellDef="let field; let i = index;">
								<ng-container *ngIf="groupes.columnGroups !== undefined else none">
									<mat-select [(value)]="field.group" placeholder="Option">
										<mat-option *ngFor="let option of groupes.columnGroups" [value]="option.id">
											{{option.name}}
										</mat-option>
									</mat-select>
								</ng-container>
								<ng-template #none>
									-
								</ng-template>
							</mat-cell>
						</ng-container>

						<!-- Actions Column -->
						<ng-container matColumnDef="actions">
							<mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>

							<mat-cell *matCellDef="let field; let i = index;" [style.color]="field.color">
								<ng-container *ngIf="filter === DatasetConfigFilterEnum.ALL">
									<span (click)="moveToBottom(i)">
										<mat-icon aria-hidden="false" cdkDragHandle fontIcon="fa-angle-double-down"
											fontSet="fas"></mat-icon>
									</span>
									<span (click)="moveToTop(i)">
										<mat-icon aria-hidden="false" cdkDragHandle fontIcon="fa-angle-double-up"
											fontSet="fas"></mat-icon>
									</span>
								</ng-container>
								<span
									*ngIf="!field.isGenerated && !(field.tags && fieldIsRequired(field.tags)) && !field.isHazardHub"
									(click)="openRemoveFieldModal(field)">
									<div class="icon-container-wrap">
										<svg width="24" height="24" viewBox="0 0 24 24"
											xmlns="http://www.w3.org/2000/svg">
											<g fill="none" fill-rule="evenodd">
												<path
													d="M18.5 6.5V19a2 2 0 0 1-2 2h-9a2 2 0 0 1-2-2V6.5M9 17h6M9 13h6M8.5 6.5v-2A1.5 1.5 0 0 1 10 3h4a1.5 1.5 0 0 1 1.5 1.5v2M20 6.5H4"
													stroke="#323232" stroke-width="1.5" stroke-linecap="round"
													stroke-linejoin="round" class="icon-primary" />
												<path d="M0 0h24v24H0z" />
											</g>
										</svg>
									</div>
								</span>
								<span *ngIf="field.isGenerated || (field.tags && fieldIsRequired(field.tags))"
									style="padding-left: 25px"></span>
								<span>
									<div class="icon-container">
										<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
											<g stroke="#323232" stroke-width="1.937" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
												<path d="M9 11.334h.5M9 16.668h.5M9 6h.5M15 11.334h.5M15 16.668h.5M15 6h.5" class="icon-primary"/>
											</g>
										</svg>
									</div>
								</span>
							</mat-cell>
						</ng-container>

						<mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
						<mat-row *matRowDef="let row; columns: displayedColumns;" [cdkDragData]="row" cdkDrag
							cdkDragLockAxis="y">
						</mat-row>
					</mat-table>
				</form>
			</div>
		</div>
	</div>
</div>