import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import LoginDto from '../model/auth/login.dto';
import {tap} from 'rxjs/operators';
import {JwtHelperService} from '@auth0/angular-jwt';
import {LoginResponse} from '../model/auth/login-response';
import {PrincipalUser} from '../model/auth/principal-user';
import {Router} from '@angular/router';
import {clearAllGroupKeys, UserStateService} from './user-state-service';
import {ChangePasswordRequest} from '../model/user/change-password-request';
import { environment } from 'src/environments/environment';
import { Constants } from '../constants';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private readonly TOKEN_STORAGE_KEY = 'access_token';
    private readonly PRINCIPAL_USER = 'principalUser';

    principal: PrincipalUser;

    // only used when a user first needs to activate 2FA
    // necessary because call the login API after activating the 2FA, so we need to store the login details somewhere
    temporaryLoginRequest: LoginDto;

    constructor(
        private readonly http: HttpClient,
        private readonly jwtHelperService: JwtHelperService,
        private readonly router: Router,
        private readonly userStateService: UserStateService
    ) {
        const existingToken = localStorage.getItem(this.TOKEN_STORAGE_KEY);
        if (existingToken) {
            this.principal = this.decodeToken(existingToken);
        }
    }

    getPrincipal() {
        return this.principal;
    }

    setTemporaryLoginRequest(request: LoginDto){
        this.temporaryLoginRequest = request;
    }

    login(loginRequest: LoginDto): Observable<LoginResponse> {
        return this.http.post<LoginResponse>(
            `/login`,
            loginRequest,
            {
                withCredentials: true
            }
        ).pipe(
            tap(response => {
                this.temporaryLoginRequest = null;
                this.setupAuthenticationDetails(response)
            })
        );
    }

    loginUsingGroupPermissionToken(token: string, groupId: number): Observable<LoginResponse> {
        return this.http.post<LoginResponse>(
            `/login/groups/${groupId}/token/${token}`,
            {}
        );
    }

    public setupAuthenticationDetails(response) {
        this.principal = response.principalUser;//this.decodeToken(response.token);
        try {
            // localStorage.setItem(this.TOKEN_STORAGE_KEY, response.token);
            localStorage.setItem(this.TOKEN_STORAGE_KEY, response.accessToken);
+            localStorage.setItem(this.PRINCIPAL_USER,JSON.stringify(response.principalUser))
            this.userStateService.initialize(this.principal);
        } catch (e) {
            console.error(e);
        }
    }

    decodeToken(token: string): any {
        const decodedToken = this.jwtHelperService.decodeToken(token);
        return JSON.parse(localStorage.getItem(this.PRINCIPAL_USER)); // JSON.parse(decodedToken.principal);
    }

    logout(): void {
        /*
        note -: old implementation.
        this.cleanAuthenticationDetails();
        this.router.navigate(['/public/login']);*/
        // window.location.href = environment.wpLogout;
        this.cleanAuthenticationDetails();
        this.router.navigate(['/public/login']);
    }

    forgotPassword(email: string): Observable<void> {
        let request = {email: email};
        return this.http.post<void>('/forgot-password', request);
    }

    confirmForgotPassword(token: string, password: string, confirmPassword: string): Observable<void> {
        let request = {password: password, confirmPassword: confirmPassword};
        return this.http.post<void>(`/forgot-password/confirm/${token}`, request);
    }

    validateForgotPasswordToken(token: string): Observable<boolean> {
        return this.http.post<boolean>(`/forgot-password/confirm/validate/${token}`, {});
    }


    cleanAuthenticationDetails() {
        this.principal = null;
        this.temporaryLoginRequest = null;
        this.logoutCall().subscribe(response => {});
        localStorage.removeItem(this.TOKEN_STORAGE_KEY);
        localStorage.removeItem(this.PRINCIPAL_USER);
        clearAllGroupKeys();
        this.userStateService.clearAll();
    }

    logoutCall(): Observable<any> {
        return this.http.post<void>('/logout', {});
    }

    verifyInvitationToken(token: string): Observable<boolean> {
        return this.http.get<boolean>(`/members/verify/${token}`);
    }

    acceptMemberInvitation(token: string, name: string, password: string): Observable<any> {
        return this.http.post('/members/accept', {token, name, password});
    }

    isAuthenticated(): boolean {
        const token = localStorage.getItem(this.TOKEN_STORAGE_KEY);
        return !this.jwtHelperService.isTokenExpired(token);
    }

    public getToken(): string {
        return localStorage.getItem(this.TOKEN_STORAGE_KEY);
    }

    changeUserPassword(request: ChangePasswordRequest): Observable<void> {
        return this.http.put<void>(`/changePassword`, request);
    }

    can(action: string, accountID?: number) {
        let role = this.principal.isSuperadmin ? 'admin' : 'user';
        let isSuperAdmin = role === 'admin';
        if (isSuperAdmin) {
            return true;
        }
        let isMember = this.principal.membership.map((account) => account.accountID).includes(accountID);
        let isAccountAdmin = false;
        try {
            isAccountAdmin = this.principal.membership.find((account) => {
                return account.accountID === accountID;
            }).isAccountAdmin;
        } catch (err) {
            // console.log(err);
        }
        switch (action) {
            case 'read': {
                if (isMember) {
                    return true;
                } else {
                    return false;
                }
            }
            case 'update': {
                if (isMember && isAccountAdmin) {
                    return true;
                } else {
                    return false;

                }
            }
            case 'delete': {
                if (isSuperAdmin) {
                    return true;
                } else {
                    return false;
                }
            }
            case 'create': {
                if (isSuperAdmin) {
                    return true;
                } else {
                    return false;
                }
            }
            default: {
                return false;
            }
        }
    }

    wpLogoutCall(): Observable<any> {
        return this.http.get<any>(environment.wpLogout);
    }
}
