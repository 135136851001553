<div [attr.hidden]="!isExpanded || null">
    <div class="group-container" style="left: 16px !important;">
        <ul id="group-tree" class="tree tree-menu">
            <ng-template #treeView let-groupNodes>
                <li *ngFor="let groupNode of groupNodes" class="tree-row">
                    <div class="tree-node">
                        <div class="node-navigation">
                            <mat-icon class="mat-icon-rtl-mirror" (click)="groupNode.isOpen = !groupNode.isOpen"
                                *ngIf="groupNode.children.length">
                                {{groupNode.isOpen ? 'expand_more' : 'chevron_right'}}
                            </mat-icon>
                        </div>
                        <mat-checkbox matTooltipPosition="right" matTooltip="{{ groupNode.value.name }}" [disabled]="disabled"
                            [class.mat-checkbox-disabled]="disabled" (change)="groupWasClicked(groupNode, $event)"
                            [checked]="groupNode.isSelected">
                            {{ groupNode.value.name }}
                        </mat-checkbox>
                    </div>

                    <ul [hidden]="!(groupNode.isOpen && groupNode.children.length > 0)">
                        <ng-container *ngTemplateOutlet="treeView;context: {$implicit: groupNode.children}">
                        </ng-container>
                    </ul>
                </li>
            </ng-template>
            <ng-container *ngTemplateOutlet="treeView;context: { $implicit: groupNodes }"></ng-container>
        </ul>

        <ng-template #node_template></ng-template>
    </div>
</div>
